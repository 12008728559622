import { faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { createBlogApi, deleteBlogApi, getallBlogsApi } from "../../apis/api";
import AdminSidebar from "./AdminSidebar";

const AdminBlog = () => {
  const [isdeleteModalOpen, setdeleteIsModalOpen] = useState(false);
  const opendeleteModal = () => setdeleteIsModalOpen(true);
  const closedeleteModal = () => setdeleteIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blogId, setBlogId] = useState("");

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // for fetching all the blogs and showing in table
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    getallBlogsApi().then((res) => {
      console.log(res.data);
      setBlog(res.data.blogs);
    });
  }, []);

  const [blogTitle, setBlogTitle] = useState("");
  const [author, setAuthor] = useState("Admin");
  const [shortDescription, setDesc] = useState("");
  const [PdfUrl, setPdfUrl] = useState(null);
  const [ImageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);

  const handleCreateBlog = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("blogTitle", blogTitle);
    formData.append("author", author);
    formData.append("shortDescription", shortDescription);
    formData.append("PdfUrl", PdfUrl);
    formData.append("ImageUrl", ImageUrl);

    console.log(formData);

    createBlogApi(formData)
      .then((res) => {
        if (res.data.success == false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          setAuthor("");
          setBlogTitle("");
          setDesc("");
          setPdfUrl(null);
          setImageUrl(null);
          closeModal();
          const newBlog = res.data.blog;
          setBlog((prevBlogs) => [...prevBlogs, newBlog]);
        }
      })
      .catch((e) => {
        toast.error(e.message);
        console.log(e);
      });
  };
  // delete Blogs
  const deleteBlogs = (id) => {
    // make Api call
    deleteBlogApi(id)
      .then((res) => {
        if (res.data.success == true) {
          toast.success(res.data.message);
          closedeleteModal(true);
          setBlog(blog.filter((item) => item._id !== id));
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImageUrl(selectedImage);
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setPdfUrl(selectedFile);
    if (selectedFile) {
      setPdfPreview(URL.createObjectURL(selectedFile));
    }
  };
  const extractFileName = (url) => {
    // This will split the URL by '/' and get the last part, which is assumed to be the filename
    return url?.split("/").pop();
  };

  return (
    <AdminSidebar>
      <>
        <div className="w-full sm:px-6 h-[100vh] overflow-scroll">
          <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
            <div className="sm:flex items-center justify-between">
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                Blogs
              </p>
              {/* <input
                type="text"
                placeholder="Search Blogs"
                className="px-12 py-3 border border-solid border-gray-700 rounded-lg ml-auto"
              /> */}
              <div>
                <button
                  className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-yellow-400 hover:bg-yellow-300 focus:outline-none rounded  text-black font-semibold "
                  onClick={openModal}
                >
                  Add Blogs
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
            <table className="w-full whitespace-nowrap">
              <thead>
                <tr className="h-16 w-full text-sm leading-none text-gray-800">
                  <th className="font-medium text-left pl-4">Blog Title</th>
                  <th className="font-medium text-left pl-12">Author</th>
                  <th className="font-medium text-left pl-12">
                    Blog Description
                  </th>
                  <th className="font-medium text-left pl-12">Blog</th>
                  <th className="font-medium text-left pl-12">Action</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {blog &&
                  blog.map((blog) => (
                    <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100">
                      <td className="pl-12">
                        <p className="text-sm capitalize leading-none text-gray-800">
                          {blog.blogTitle}
                        </p>
                      </td>
                      <td className="pl-12">
                        <p className="capitalize">{blog.author}</p>
                      </td>
                      <td className="pl-20">
                        <p className="capitalize"> {blog.shortDescription}</p>
                      </td>
                      <td className="pl-20">
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          style={{ marginRight: "5px" }}
                        />
                        {extractFileName(blog.pdfUrl)}
                      </td>

                      <td className="px-7 2xl:px-0">
                        <Link
                          to={`/admin-edit-blogs/${blog._id}`}
                          className="bg-[#123697] py-2 px-3 text-white rounded m-1 text-sm"
                        >
                          <i
                            class="fa-regular fa-pen-to-square "
                            style={{ color: "white" }}
                          ></i>
                        </Link>
                        <button
                          onClick={() => {
                            opendeleteModal();
                            setBlogId(blog._id);
                          }}
                          className="bg-[#e92939] py-2 px-3 text-white rounded m-1 text-sm"
                        >
                          <i
                            class="fa-regular fa-trash-can"
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {isdeleteModalOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
              id="my-modal"
            >
              <div className="relative mx-auto p-5 border w-1/4 shadow-lg rounded-md bg-white space-y-8 justify-center items-center flex flex-col">
                <i className="fa-solid fa-triangle-exclamation text-red-500 fa-5x"></i>
                <h1 className="font-medium w-3/4 mx-auto text-center">
                  Are you sure you want to Delete?
                </h1>

                <div className="flex flex-wrap items-center justify-between mx-auto w-full">
                  <button
                    onClick={() => deleteBlogs(blogId)}
                    type="submit"
                    className="w-1/3 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center py-2.5"
                  >
                    Delete
                  </button>
                  <button
                    type="submit"
                    className="w-1/3 text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-2.5"
                    onClick={closedeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {isModalOpen && (
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
              id="my-modal"
            >
              <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    onClick={closeModal}
                    className="text-black bg-gray-200 hover:bg-gray-300 rounded-lg text-sm p-1.5"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>

                <form className="space-y-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 text-center ">
                    Add New BLog
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label
                        htmlFor="Blog"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Blog Title
                      </label>
                      <input
                        value={blogTitle}
                        onChange={(e) => setBlogTitle(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="Blog"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Author
                      </label>
                      <input
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="Blog"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Short Description
                      </label>
                      <input
                        value={shortDescription}
                        onChange={(e) => setDesc(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="EventImage"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Banner Image
                      </label>
                      <input
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    {imagePreview && (
                      <img
                        src={imagePreview}
                        alt="Event Preview"
                        className="mt-2 w-32 h-32 object-cover"
                      />
                    )}

                    <div>
                      <label
                        htmlFor="EventImage"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Blog Contnet
                      </label>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept="pdf/*"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    {/* {pdfPreview && (
                      <img
                        src={pdfPreview}
                        alt="Event Preview"
                        className="mt-2 w-32 h-32 object-cover"
                      />
                    )} */}
                  </div>

                  <button
                    onClick={handleCreateBlog}
                    className="w-full bg-yellow-400 hover:bg-yellow-300 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 text-center text-black font-semibold"
                  >
                    Add Blog
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </>
    </AdminSidebar>
  );
};

export default AdminBlog;
