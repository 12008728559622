import React, { useEffect, useState } from "react";
import { getSingleUniversityByCountryApi } from "../../apis/api";
import  StickyForm  from "../../components/StickyForm";


const Canada = () => {
  const images = [
    "/../assets/images/country/england.jpg",
    "/../assets/images/country/india.jpg",
    "/../assets/images/country/canada.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const [university, setUniversity] = useState([]);
  useEffect(() => {
    getSingleUniversityByCountryApi("Canada").then((res) => {
      console.log(res.data.universities);
      setUniversity(res.data.universities);
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">
      <div className="w-full flex flex-cols">
        <div className="w-full">
          <img
            src="/../assets/images/studyabroad.jpg"
            alt="Your alt text"
            className="w-full h-auto max-h-98 object-cover"
          />
          <div className="md:absolute flex flex-col top-[190px] md:w-[900px] w-full md:pl-36 pl-10 space-y-2">
            <h1 className="md:text-dark text-2xl md:text-4xl font-medium">
              Your dream of studying in
              <br /> Canada is closer than you think!
            </h1>
            <p className="md:text-dark w-[90%]">
              "At Hub International Education, we are dedicated to transforming
              educational aspirations into realities by providing personalized
              guidance and expert support throughout the academic journey."
            </p>
            <button className="bg-yellow-400 w-[210px] text-dark px-5  py-2 mt-2 rounded-md hover:bg-white hover:text-dark transition duration-300">
              Get Started For Free
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">

        <div className="container mx-auto p-8 md:mt-8 mt-32 text-justify md:w-[75%] w-[100%]">
          <div className="flex flex-col md:flex-row gap-10 ">
            {/* first division */}
            <div className="flex-1 max-w-4xl">
              <h1 className="md:text-3xl font-bold mt-4 mb-2 md:text-justify text-left ">
                Study in Canada from Nepal
              </h1>
              <p className="text-gray-700 mb-4">
                Before elaborating about the Canadian education, it is very
                important for us to know about Canada first. Canada is located
                in North America with ten provinces (Ontario, Quebec, Manitoba,
                Saskatchewan, British Columbia, New Brunswick, Nova Scotia,
                Alberta, Newfoundland and Labrador and Prince Edward Island) and
                three territories (Yukon Northwest Territories and Nunavut).
                Canada is the second largest country in the world after Russia.
                Ottawa is the capital of Canada.
                <br /> <br />
                Canada has been providing a top-notch quality education and
                Canada is one of the top countries in the happiness index which
                clearly shows the level of satisfaction each individual has with
                their living standard in Canada. During the recent pandemic,
                many countries didn’t want to bear the responsibilities of
                people other than their citizens and asked students and
                immigrants to return to their own country whereas Canada was one
                of the countries to make the immigrants feel safe and homely and
                gave them all the health and day-to-day facilities required.
                Hosting nearly half a million international students, Canada is
                known to offer high-quality education at affordable tuition fees
                with degrees and certificates that are globally recognized. Add
                to it, an excellent quality of life, immense post-study work and
                immigration opportunities, Canada stands out as a popular
                country to study among Nepalese students.
              </p>

              <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Why do you choose Canada to study?
              </h1>
              <p className="text-gray-700 mb-4 ">
                Students choose Canada for higher studies because of its
                excellent academic reputation, diverse and inclusive society,
                and abundant opportunities for personal and professional growth.
                Canada is known for its high-quality education system, with
                world-renowned universities and colleges offering a wide range
                of programs. Moreover, Canada promotes multiculturalism and
                provides a welcoming environment for international students.
                Most of the Canadian education are public funded which makes
                tuition fees reasonable which will help the students to gain an
                international exposure.
              </p>

              <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Why Canada is best for Nepalese Students??
              </h1>
              <p className="text-gray-700 mb-4">
                Canada is considered one of the best destinations for the
                Nepalese students due to several reasons. Firstly, Canada offers
                a high standard of education, with internationally recognized
                degrees and certificates that can open doors to global career
                opportunities. Secondly, Canadian universities and colleges
                provide a diverse and inclusive environment, embracing students
                from various cultures and backgrounds.
                <br /> <br /> Additionally, Canada’s post-study work permit
                program allows international students, to gain valuable work
                experience after graduation. Moreover, the country’s emphasis on
                research and innovation makes it an ideal choice for those
                seeking academic and research opportunities. Canadian
                universities and colleges provide a conducive environment for
                students to thrive and prepare for successful careers. Whether
                it’s in fields like science, technology, engineering, arts, or
                business, these universities are at the forefront of shaping the
                future and making significant contributions to various fields of
                study.
              </p>

              <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                What is the quality of education in Canada?
              </h1>
              <p className="text-gray-700 mb-4">
                Canada is renowned for its high-quality education system. The
                country maintains rigorous academic standards, ensuring that
                students receive an excellent education. Canadian universities
                and colleges are known for their world-class faculty,
                cutting-edge research facilities, and innovative teaching
                methods. Many institutions in Canada have a strong focus on
                practical, hands-on learning, which equips students with the
                skills and knowledge needed
              </p>

              <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                How is life in Canada as a student?
              </h1>
              <p className="text-gray-700 mb-4">
                Life in Canada as a student is enriching and fulfilling. The
                country offers a safe and welcoming environment for
                international students, with a multicultural society that
                celebrates diversity. Canadian cities provide a vibrant and
                lively atmosphere, with numerous recreational and cultural
                activities to explore. The campuses are often well-equipped with
                state-of-the-art facilities, including libraries, sports
                centers, and student organizations. Furthermore, Canadian
                universities and colleges prioritize student support services,
                helping with academic, career, and personal matters. Overall,
                studying in Canada provides a well-rounded experience, combining
                quality education with an enjoyable and rewarding student life.
              </p>

              <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Top Programs to Study in Canada from Nepal
              </h1>
              <p className="text-gray-700 mb-4">
                Nepal has lately witnessed a surging number of students applying
                for postsecondary education at Canadian educational
                institutions. As the number increases, the concerns and
                confusions with respect to choosing academic programs soar at
                the same time. Therefore, this article is to highlight the top
                programs in Canada for Nepalese students.
              </p>

              <h2 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Business Management
              </h2>
              <p className="text-gray-700 mb-4">
                A substantial number of Nepalese students’ hail from business
                and management backgrounds, starting from high school to degree
                levels. And interestingly, when it comes to prospective students
                who apply to Canadian educational institutions, they are higher
                in number than those from any other academic background. These
                learners, either from high school or with a degree, choose to
                pursue either a diploma, an undergraduate degree, a postgraduate
                certificate, or a master’s degree in Canada. <br />
                <br />
                The most popular programs for those credentials are General
                Business, Sales and Marketing, Accounting, Financial Services,
                and a BBA specializing in a wide array of business management
                disciplines. Additionally, students after a bachelor’s degree
                have mainly two alternatives to choose from, such as
                postgraduate or MBA programs. The postgraduate certificate
                incorporates a great number of specialization programs, such as
                Supply Chain Management, Global Business Management, Marketing
                Management and Digital Media, Business Management, Digital
                Marketing and Marketing Analytics, Business Analytics, Human
                Resources Management, Financial Planning, Wealth Management, and
                Accounting. Furthermore, the MBA is also the most preferred
                option to study at Canadian institutions, provided that aspiring
                learners meet the eligibility prerequisites, as this program
                stipulates excellent levels of previous academic performance and
                executive work experience backed up by English language
                proficiency.
              </p>

              <h2 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Information Technology and Computer Sciences
              </h2>
              <p className="text-gray-700 mb-4">
                The paramount importance of information technology can’t be
                overstated in today’s interconnected and digitalized world. IT
                has transformed virtually every aspect of our personal and
                professional lives, and its significance and usage continue to
                grow as technology advances. Similarly, interested individuals
                in this domain have taken a significant leap as its scope is
                getting wider and demand for IT professionals has seen a
                milestone turn. Thus, Nepalese students interested in pursuing
                academic or specialized programs have lately mushroomed. In
                addition, Nepalese aspirants, after the completion of high
                school or an undergraduate or master’s degree, set their sights
                on pursuing further education in the field of IT. Particularly,
                high school graduates either from a science or management
                background with an additional mathematics background aim to
                choose a two-year skill-oriented program such as Computer
                Programming, Computer Systems Technician-Networking, Software
                Engineering Technician, or Bachelor of Computer Science at a
                public college or university. On the contrary, aspirants from
                management backgrounds will not likely be admitted to the
                undergraduate programs in the discipline of IT, especially at
                the universities, as they set out the requirements that students
                must have hailed from the science setting. However, colleges
                welcome students to the diploma or degree in IT either from
                science or management, provided they meet the prerequisites
                brought forth by the institutions. <br />
                <br />
                On the other hand, undergraduate degree holders in the domain of
                IT from Nepal aspire to thrive in their academic or professional
                careers, either through postgraduate study or a degree. Both
                options are open to eligible students; however, the majority of
                them set their sights on pursuing more experiential and
                industry-focused programs in the postgraduate certificate, such
                as Cybersecurity and Computer Forensics, Advanced Project
                Management-IT, Full Stack Software Development, Web Development,
                Wireless Networking, AI and Machine Learning, Big Data
                Analytics, Cloud Computing, AI and Data Science, Network
                Infrastructure, Information Systems Security, and to name a few.
                The preference for the postgraduate over the degree is given
                owing to its likelihood of making the graduate job-ready within
                a short span of time and on budget.
              </p>

              <h2 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Health Management and Community Services
              </h2>
              <p className="text-gray-700 mb-4">
                Health management and community services are important aspects
                of public health and healthcare systems that aim to promote
                well-being, prevent disease, and provide support to individuals
                with different disabilities in communities. Students, after
                completing either high school with science in biology,
                Proficiency Certificate Level (PCL) in health programs such as
                Nursing, Pharmacy, Health Assistant, Lab Technician, Dental
                Hygiene, or an undergraduate degree with a specialization in
                Nursing, Pharmacy, Dentistry Surgery, Public Health, or MBBS,
                choose to pursue two-year diploma or postgraduate certificate
                programs. <br />
                <br />
                Moreover, the more appealing programs for students with high
                school or PCL credentials have a wide array of options, such as
                Early Childhood Education, Developmental Services Workers,
                Community Workers, Addiction and Mental Health Workers, Office
                Administration-Health Services, Biotechnology, and Biomedical
                Engineering. More significantly, nearly all aspirants do not
                intend to choose direct undergraduate programs for a few crucial
                reasons, such as competitiveness, higher costs, and
                requirements, along with the duration of the programs. <br />
                <br />
                Besides, undergraduate degree holders are also discouraged from
                taking further degree programs in Canada in most cases, as these
                programs set the requirements higher. Therefore, most students
                choose postgraduate certificates over degrees, and the
                availability of programs for these aspirants at the postgraduate
                level includes Healthcare Administration and Services
                Management, Health Informatics, Health Analytics, Advanced
                Healthcare Leadership, Gerontology-Interprofessional Practice,
                Palliative Care, Enhanced Practice for Internationally
                Educated/graduated Nurses, and so on. These programs are
                designed to prepare their graduates for swift transitions to
                professional careers, so they are most preferred.
              </p>

              <h2 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Food Science, Technology, and Hospitality
              </h2>
              <p className="text-gray-700 mb-4">
                A handful of students from food and science, microbiology,
                biology, and hospitality management backgrounds choose to
                specialize in their own respective fields of study. And Canadian
                educational institutions have a variety of programs available to
                these aspirants. Students with concentrations in food and
                science, microbiology, and biology can pursue programs such as
                Food Safety and Quality Assurance Management, Food Science
                Technology, Food Processing-Leadership Management and Product
                Management, Food and Nutrition Management, and so on. On the
                other hand, graduates from either high school or undergrad with
                backgrounds in hospitality and tourism management can pursue
                several programs, namely Hospitality-Hotel and Resort
                Management, Culinary Management, and Hospitality-Hotel and
                Resort Operation Management. Graduates with an undergraduate
                degree can choose to study Hotel and Resort Management,
                Hospitality and Tourism Operations Management, Global
                Hospitality Business Development, Operations, and Leadership, or
                an MBA.
              </p>

              <h2 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Engineering: Civil, Mechanical, Electrical, Electronics,
                Industrial.{" "}
              </h2>
              <p className="text-gray-700 mb-4">
                There are numerous branches and disciplines within engineering,
                and engineers play a crucial role in shaping the modern world by
                innovating and developing solutions to various challenges.
                Canadian establishments have plenty of programs for engineers.
                They can either choose diploma, postgraduate, or degree programs
                in Canada. For high school graduates, they are more likely to go
                with two- or three-year programs such as Civil Engineering
                Technician/Technology, Civil Construction Technician/Technology,
                or Architectural Technician/Technology. On the other hand,
                undergraduate degree graduates could go with two options: a
                postgraduate or master’s degree. Nevertheless, nearly all
                students go to postgraduate programs in which they can
                specialize in Construction Project Management, Strategic
                Leadership, Construction Management, Sustainable Urban and
                Transportation Planning, Quality Engineering Management,
                Industrial Manufacturing Processes, Embedded Systems, Wireless
                Networking, Big Data analytics, AI, and Machine Learning. As
                opposed to this, a few choose to pursue their further education
                at the degree level in Canada.s
              </p>

              <h2 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Requirements for Nepalese Students to Study in Canada{" "}
              </h2>
              <p className="text-gray-700 mb-4">
                Canada has become an increasingly popular destination for
                international students seeking quality education and diverse
                cultural experiences. Among the many nationalities that come to
                study in Canada, Nepalese students have shown a growing interest
                in pursuing their academic dreams in this beautiful country.
                However, to ensure a smooth and successful journey, it is
                essential for Nepalese students to be aware of the specific
                requirements they need to fulfill to study in Canada.
              </p>

              <h2 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                Academic Qualifications{" "}
              </h2>
              <p className="text-gray-700 mb-4">
                The foremost and crucial step for Nepalese students harboring
                aspirations of pursuing their academic journey in Canada is to
                satisfy the rigorous academic requirements set forth by Canadian
                educational institutions. This pivotal stage necessitates the
                successful completion of educational milestones equivalent to
                Canadian secondary education, which corresponds to Grade 12 in
                Nepal. To ensure a seamless transition into the Canadian
                academic landscape, it is imperative for prospective students to
                furnish authentic and up-to-date academic transcripts.
                Specifically, the Higher Secondary Education Board (HSEB)
                transcript holds paramount importance in the application
                process. Moreover, students with higher education are required
                to present transcripts from their Bachelor’s and Master’s
                degrees, if applicable, or any other recognized equivalents,
                serving as tangible evidence of their meritorious academic
                performance.
              </p>
            </div>
            {/* first division upto here */}

            {/* form */}
            {/* second division */}
            {/* <div className=" md:w-[500px] w-full md:mr:0  md:ml-12">
            <form className="bg-white border-2 border-gray p-4 rounded">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full mb-2 p-2 rounded border border-gray-700"
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full mb-2 p-2 rounded border border-gray-700"
              />
              <textarea
                placeholder="Your Message"
                rows="4"
                className="w-full mb-2 p-2 rounded border border-gray-700"
              ></textarea>
              <button
                type="submit"
                className="w-full bg-yellow-400 font-semibold text-dark py-2 rounded hover:bg-yellow-300"
              >
                Apply Now
              </button>
            </form>
          </div> */}
            {/* second division upto here */}
          </div>

          {/* Key Facts Section */}
          <div className="max-w-4xl">
            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Study Levels and Academic Requirements:
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-4">
                <div className="font-semibold">Degree</div>
                <div>Diploma</div>
                <div>Bachelor Degree</div>
                <div>Post Graduate Certificate</div>
                <div>Masters</div>
              </div>
              <div className="p-4">
                <div className="font-semibold">Academic Requirement</div>
                <div>GPA- 2.5 and above</div>
                <div>GPA- 2.8 and above</div>
                <div>50% and above</div>
                <div>60% and above</div>
              </div>
            </div>
          </div>

          {/* Additional info link */}
          <div className="mt-4 max-w-4xl">
            <h2 className="md:text-xl md:text-justify text-left font-bold">
              English Language Proficiency Test
            </h2>
            <p className="text-gray-700 justify-between">
              In light of Canada’s bilingual nature, a profound command of
              either English or French is imperative for international student’s
              academic success and seamless integration into Canadian society.
              For the majority of academic programs, Nepalese students are
              obligated to furnish substantial proof of their English language
              proficiency, typically assessed through standardized examinations
              like the International English Language Testing System (IELTS) or
              Pearson Test of English (PTE). The mandated minimum scores for
              these tests may fluctuate based on the specific educational
              institution and chosen program of study, commonly set at 6.5/6 for
              IELTS and 60/58 for PTE, respectively.
            </p>
          </div>

          <div className="max-w-4xl text-left">
            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Language and Funds Requirements:
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl  font-semibold">Levels</div>
                <div>Diploma</div>
                <div>Bachelor Degree</div>
                <div>Post Graduate Certificate</div>
                <div>Masters</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl  font-semibold">IELTS</div>
                <div>6 not less than 6</div>
                <div>6 not less than 5.5</div>
                <div>6.5 not less than 6</div>
                <div>6.5 not less than 6</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl  font-semibold">PTE</div>
                <div>60 not less than 58</div>
                <div>60 not less than 58</div>
                <div>60 not less than 60</div>
                <div>60 not less than 60</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl  font-semibold">Duration</div>
                <div>2 years</div>
                <div>4 years</div>
                <div>1-2 years</div>
                <div>2 years</div>
              </div>

              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl  font-semibold ">Fund</div>
                <div>16-19 Lakhs</div>
                <div>20 lakhs and Above</div>
                <div>16-19 lakhs</div>
                <div>20 lakhs and above</div>
              </div>
            </div>
            <div className="w-full overflow-hidden mb-12">
              <h1 className="text-red-700 md:text-4xl text-2xl mt-4 md:mt-6 text-center font-semibold">
                Partner Institutions
                <div className="md:w-1/2 w-full mx-auto border-2 border-solid border-yellow-500"></div>
              </h1>

              <div className="flex flex-wrap  justify-center items-center mt-4">
                {university.map((uni, index) => (
                  <a href={uni.description} target="_blank">
                    <div
                      key={index}
                      className="flex flex-wrap items-center space-x-2 space-y-2 p-2 "
                    >
                      <img
                        src={uni.imageUrl}
                        alt={uni.universityName}
                        className="w-40 h-30 object-cover rounded "
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-10 max-w-4xl text-justify">
              {/* Header with image and title */}
              <div className="flex-1">
                <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                  Apply to Designated Learning Institutions (DLIs)
                </h1>
                <p className="text-gray-700 mb-4  ">
                  Nepalese students are advised to exclusively seek admission to
                  Canadian institutions duly designated by the provincial or
                  territorial government to accommodate international students.
                  These esteemed Designated Learning Institutions (DLIs) ensure
                  thorough adherence to stringent quality benchmarks and offer
                  indispensable support services catered to the needs of the
                  global student community.
                </p>

                <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                  Study Plan and Statement of Purpose
                </h1>
                <p className="text-gray-700 mb-4">
                  As part of the study permit application process, Nepalese
                  students are compelled to furnish a comprehensive study plan,
                  meticulously outlining their educational aspirations and
                  objectives concerning their prospective academic pursuits in
                  Canada. Furthermore, an artfully composed statement of
                  purpose, eloquently elucidating the rationale behind their
                  choice of a specific program and institution, assumes crucial
                  significance in bolstering the strength and impact of their
                  application. The study plan serves as a vital roadmap,
                  delineating the students’ long-term professional goals and
                  their envisioned trajectory after relocating back to Nepal. By
                  articulating their educational intentions with clarity and
                  precision, applicants showcase their commitment and
                  seriousness toward their chosen field of study. This document
                  substantiates their readiness to embark on a rigorous academic
                  journey and signifies their dedication to making the most of
                  the educational opportunities that Canada offers.
                </p>

                <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                  Study Permit
                </h1>
                <p className="text-gray-700 mb-4">
                  A study permit holds paramount significance as an
                  indispensable legal document mandatory for Nepalese students
                  aspiring to pursue academic endeavors in Canada. To procure
                  this permit, students must first secure a Letter of Acceptance
                  (LOA) from a Designated Learning Institution (DLI) in Canada
                  and subsequently present compelling evidence of possessing
                  ample financial resources to cover tuition fees, living
                  expenses, and return transportation costs.
                  <br /> <br /> Moreover, the Canadian Immigration authorities
                  enact stringent prerequisites encompassing a comprehensive
                  criminal background check, necessitating the inclusion of a
                  police report with the application, alongside a thorough
                  medical examination conducted by the TMMC or IOM. Satisfactory
                  completion of these measures is an indispensable precondition
                  for the issuance of the study permit. These exacting measures,
                  meticulously enforced, epitomize the commitment to granting
                  permission solely to deserving and highly qualified students,
                  consequently cultivating a secure and propitious milieu for
                  international scholars.{" "}
                </p>

                <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                  Proof of Financial Support
                </h1>
                <p className="text-gray-700 mb-4">
                  Nepalese students must demonstrate their ability to support
                  themselves financially during their study period in Canada.
                  This includes the funds required for tuition fees,
                  accommodation, transportation, and living expenses. Accepted
                  proof of financial support may include bank statements, income
                  documents, and a letter of financial support from sponsors.
                </p>

                <h1 className="md:text-xl md:text-justify text-left font-bold mt-4 mb-2">
                  Biometrics and Visa Application
                </h1>
                <p className="text-gray-700 mb-4">
                  As an integral facet of the application process, Nepalese
                  students are obliged to furnish biometric information,
                  encompassing their fingerprints and digital photograph. This
                  procedure has been instated to fortify the integrity of the
                  immigration system and ensure accurate identity verification.
                  The collection of biometric data aids in enhancing security
                  measures and streamlining the entry process for international
                  students.
                  <br />
                  <br />
                  <br />
                  Contact your nearest HUB International Education branch office
                  today. We have branches in Kathmandu - Hattisar, Kumaripati,
                  Pokhara, Butwal.
                </p>
              </div>
            </div>
          </div>
        </div>
        <StickyForm country="Canada"></StickyForm>


       
      </div>
    </div>
  );
};

export default Canada;
