import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { deleteContactApi, getAllContactsAPI } from "../../apis/api";
import AdminSidebar from "./AdminSidebar";

const AdminMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageId, setMessageId] = useState("");
  const [isdeleteModalOpen, setdeleteIsModalOpen] = useState(false);
  const opendeleteModal = () => setdeleteIsModalOpen(true);
  const closedeleteModal = () => setdeleteIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);

  // for fetching all the AboutUss and showing in table
  const [allmessage, setAllMessage] = useState([]);
  useEffect(() => {
    getAllContactsAPI().then((res) => {
      console.log(res.data);
      setAllMessage(res.data.contacts);
    });
  }, []);

  // delete AboutUs
  const deleteContactUs = (id) => {
    deleteContactApi(id).then((res) => {
      if (res.data.success == true) {
        toast.success(res.data.message);
        closedeleteModal(true);
        setAllMessage(allmessage.filter((item) => item._id !== id));
      } else {
        toast.error(res.data.message);
      }
    });
  };

  return (
    <AdminSidebar>
      <>
        <div className="w-full sm:px-6 h-[100vh] overflow-scroll">
          <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
            <div className="sm:flex items-center justify-between">
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                Message
              </p>
              {/* <input
                type="text"
                placeholder="Search AboutUs"
                className="px-12 py-3 border border-solid border-gray-700 rounded-lg ml-auto"
              /> */}
            </div>
          </div>
          <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
            <table className="w-full whitespace-nowrap">
              <thead>
                <tr className="h-16 w-full text-sm leading-none text-gray-800">
                  <th className="font-medium text-left pl-4">Full Name</th>
                  <th className="font-medium text-left pl-12">Email</th>
                  <th className="font-medium text-left pl-12">Phone Number</th>
                  <th className="font-medium text-left pl-12">Country</th>
                  <th className="font-medium text-left pl-12">Addresss</th>
                  <th className="font-medium text-left pl-12">Message</th>
                  <th className="font-medium text-left pl-12">Action</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {allmessage &&
                  allmessage.map((eachData) => (
                    <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100">
                      <td className="pl-12">
                        <p className=" capitalize">
                          {eachData.fullName}
                        </p>
                      </td>
                      <td className="pl-12">
                        <p className="capitalize">{eachData.email}</p>
                      </td>
                      <td className="pl-20">
                        <p className="capitalize">
                          {eachData.phoneNum}
                        </p>
                      </td>
                      <td className="pl-20">
                        <p className=" capitalize">
                          {eachData.country}
                        </p>
                      </td>
                      <td className="pl-20">
                        <p className=" capitalize">
                          {eachData.address}
                        </p>
                      </td>
                      <td className="pl-20">
                        <p className=" capitalize">
                          {eachData.message}
                        </p>
                      </td>

                      <td className="px-7 2xl:px-0">
                        <button
                          onClick={()=>{
                            setMessageId(eachData._id);
                            opendeleteModal();
                          }}
                          className="bg-[#e92939] py-2 px-3 text-white rounded m-1 text-sm"
                        >
                          <i
                            class="fa-regular fa-trash-can"
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {isdeleteModalOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
              id="my-modal"
            >
              <div className="relative mx-auto p-5 border w-1/4 shadow-lg rounded-md bg-white space-y-8 justify-center items-center flex flex-col">
                <i className="fa-solid fa-triangle-exclamation text-red-500 fa-5x"></i>
                <h1 className="font-medium w-3/4 mx-auto text-center">
                  Are you sure you want to Delete?
                </h1>

                <div className="flex flex-wrap items-center justify-between mx-auto w-full">
                  <button
                    onClick={() => deleteContactUs(messageId)}
                    type="submit"
                    className="w-1/3 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center py-2.5"
                  >
                    Delete
                  </button>
                  <button
                    type="submit"
                    className="w-1/3 text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-2.5"
                    onClick={closedeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    </AdminSidebar>
  );
};

export default AdminMessage;
