import React, { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { applicationApi } from "../apis/api";
import { toast } from "react-toastify";

const TestPreparation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [fullname, setIsFullName] = useState("");
  const [email, setIsEmail] = useState("");
  const [phone, setIsphone] = useState("");
  const [course, setIscourse] = useState("");
  const [nearestOffice, setIsnearestOffice] = useState("");
  const [date, setIsDate] = useState("");

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("fullName", fullname);
    form.append("email", email);
    form.append("phoneNum", phone);
    form.append("course", course);
    form.append("nearOffice", nearestOffice);
    form.append("date", date);
    applicationApi(form)
    .then((res) => {
      console.log(res.data);

      if (res.data.success == false) {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
        setIsFullName("");
        setIsDate("");
        setIsEmail("");
        setIsnearestOffice("");
        setIsphone("");
        setIscourse("");
        closeModal();
      }
    })
    .catch((e) => {
      toast.error(e.message);
      console.log(e);
    });
  };

  return (
    <>
      <main className="flex flex-col w-full h-auto font-[poppins] text-black bg-[#F2F1F2]">
        {/* Hero Section */}
        <div className="w-[100%] h-[400px]">
          <img
            className="w-[100%] h-[100%] object-cover"
            src="/assets/images/test-hero.png"
            alt=""
          />
          <div className="relative w-full -top-56 md:flex justify-center items-center px-20">
            <p className="text-white font-bold md:text-4xl pb-10 text-2xl">
              Test Preparation
            </p>
          </div>
        </div>

        {/* Description section */}
        <div className="py-16 md:px-20 px-5 md:w-[80%] w-95% mx-auto">
          <p className="text-neutral-800">
            IELTS is an English language exam that international candidates who
            want to study or work in a country where English is the primary
            language must take. The UK, Australia, New Zealand, the United
            States, and Canada are the most common nations where IELTS is
            recognized for university admissions. The exam primarily assesses
            test-takers’ ability to communicate in the four core English
            language abilities of hearing, reading, speaking, and writing.
          </p>
        </div>

        {/* Ielts Module Section */}
        <div className="flex flex-wrap justify-around items-start md:w-[80%] w-[95%] mx-auto h-auto md:px-20 pb-10 gap-y-10 px-5">
          <div className="flex flex-col md:w-[50%] w-full">
            <p className="text-2xl font-semibold text-black">
              <span className="border-b-4 border-black">IEL</span>TS Module
            </p>
            <p className="text-neutral-800 mt-5">
              There are two types of IELTS test: Academic and General Training.
              Listening and Speaking are the same for the both tests, however
              the topic of the Reading and Writing sections differs relying upon
              which test the candidate takes. IELTS can be taken either on paper
              or computer depending on the choice of the test taker. The test
              score is valid for two years from the test date.
            </p>{" "}
            <br />
            <p className="text-neutral-800">
              IELTS is acknowledged by more than 10,000 associations in excess
              of 140 nations. These include: colleges, schools, universities and
              training organizations, government divisions and immigration
              authorities.
            </p>
          </div>
          <div className="md:w-[47%] w-full border-2">
            <img
              className="w-[100%]"
              src="../assets/images/ielts.png"
              alt="Ielts"
            />
          </div>
        </div>

        {/* Ielts description */}
        <div className="flex flex-wrap md:w-[80%] w-full justify-evenly md:px-auto mx-auto gap-y-4">
          <div className="flex flex-col justify-center items-center md:w-[48%] w-full px-14 gap-y-4 pt-4 pb-10">
            <img
              className="w-[50px] h-[50px]"
              src="../assets/images/listening.png"
              alt=""
            />
            <p>Listening</p>
            <p className="text-neutral-700 text-center">
              Approximately 30 minutes of listening and additional 10 minutes to
              transfer answers from question booklet to answer sheet
            </p>
          </div>
          <div className="flex flex-col justify-center items-center md:w-[48%] w-full px-14 gap-y-4 pt-4 pb-10">
            <img
              className="w-[50px] h-[50px]"
              src="../assets/images/reading.png"
              alt=""
            />
            <p>Reading</p>
            <p className="text-neutral-700 text-center">
              Exactly 60 minutes including answer transfer time.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center md:w-[48%] w-full px-14 gap-y-4 pt-4 pb-10">
            <img
              className="w-[50px] h-[50px]"
              src="../assets/images/writing.png"
              alt=""
            />
            <p>Listening</p>
            <p className="text-neutral-700 text-center">
              Exactly 60 minutes. Recommended 20 minutes for task 1 and 40
              minutes for task 2.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center md:w-[48%] w-full px-14 gap-y-4 pt-4 pb-10">
            <img
              className="w-[50px] h-[50px]"
              src="../assets/images/speaking.png"
              alt=""
            />
            <p>Listening</p>
            <p className="text-neutral-700 text-center">
              It is the last part and is taken in three-part oral interview.
              Here in task one you will be giving introduction about yourself to
              the interviewer, for the second task you will be given a topic and
              a minute to prepare for it and talk about it for 1-2 minutes. And
              for the third task you will have two way discussion with the
              interviewer about the issues related to the topic in the card.
            </p>
          </div>
        </div>

        {/* Ielts preparation score data */}
        <div className="bg-white w-full py-10">
          <div className="flex flex-col py-5 md:px-0 px-5 md:w-[70%] w-full md:overflow-hidden overflow-x-auto mx-auto gap-y-4">
            <p className="text-2xl">
              <span className="border-b-4 border-black">Wh</span>at does the
              score mean?
            </p>
            <p className="text-sm text-neutral-600">
              The full length of IELTS test if four hour long which is followed
              by following structure.
            </p>
            <table className="items-center bg-transparent w-full border-collapse font-[Poppins]">
              <thead className="bg-[#F2F1F2]">
                <tr>
                  <th className="align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    Section
                  </th>
                  <th className="align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    Description
                  </th>
                  <th className="align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    Test Time
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="text-neutral-800">
                  <th class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0 text-left font-normal">
                    Reading
                  </th>
                  <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    3–4 passages, each containing 12–14 questions
                  </td>
                  <td class="border-t-0 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    60–80 minutes
                  </td>
                </tr>
                <tr className="bg-[#F2F1F2] text-neutral-800">
                  <th class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0 text-left font-normal">
                    Listening
                  </th>
                  <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    6–9 passages, each containing 5–6 questions
                  </td>
                  <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    60–90 minutes
                  </td>
                </tr>
                <tr className="text-neutral-800">
                  <th class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0 text-left font-normal">
                    Break
                  </th>
                  <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    -
                  </td>
                  <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    10 minutes
                  </td>
                </tr>
                <tr className="bg-[#F2F1F2] text-neutral-800">
                  <th class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0 text-left font-normal">
                    Speaking
                  </th>
                  <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    6 tasks
                  </td>
                  <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    20 minutes
                  </td>
                </tr>
                <tr className="text-neutral-800">
                  <th class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0 text-left font-normal">
                    Writing
                  </th>
                  <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    2 tasks
                  </td>
                  <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    50 minutes
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <p className="text-sm text-neutral-600">
              To book your IELTS/PTE test date please contact us @ 9812345678 or
              email at hubintl@gmail.com or{" "}
              <strong
                className="text-yellow-400 font-semibold cursor-pointer"
                onClick={openModal}
              >
                Click Here
              </strong>
            </p> */}
          </div>
        </div>

        {/* PTE Section */}
        {/* Description section */}
        <div className="py-16 md:px-20 px-5 md:w-[80%] w-95% mx-auto">
          <p className="text-neutral-800 py-5">
            PTE (Pearson Test of English) is a computer-based English language
            proficiency exam designed for non-native English speakers. It is
            widely accepted by universities, colleges, and governments around
            the world for academic and immigration purposes. Similar to IELTS,
            PTE evaluates test-takers' abilities in the four key English
            language skills: listening, reading, speaking, and writing.
          </p>
        </div>
        {/* PTE Module Section */}
        <div className=" flex flex-wrap justify-around items-start md:w-[80%] w-[95%] mx-auto h-auto md:px-20 pb-10 gap-y-10 px-5">
          <div className="flex flex-col md:w-[50%] w-full">
            <p className="text-2xl font-semibold text-black">
              <span className="border-b-4 border-black">PTE</span> Module
            </p>
            <p className="text-neutral-800 mt-5">
              The PTE (Pearson Test of English) evaluates the English language
              proficiency of non-native English speakers. It assesses the four
              skills: Speaking, Writing, Reading, and Listening. The test is
              computer-based and typically lasts for approximately three hours.
            </p>{" "}
            <br />
            <p className="text-neutral-800">
              PTE scores are widely accepted by universities, colleges, and
              governments around the world for academic and immigration
              purposes.
            </p>
          </div>
          <div className="md:w-[47%] w-full border-2">
            <img
              className="w-[100%]"
              src="../assets/images/ielts.png"
              alt="PTE"
            />
          </div>
        </div>

        {/* PTE description */}
        <div className="flex flex-wrap md:w-[80%] w-full justify-evenly md:px-auto mx-auto gap-y-4">
          <div className="flex flex-col justify-center items-center md:w-[48%] w-full px-14 gap-y-4 pt-4 pb-10">
            <img
              className="w-[50px] h-[50px]"
              src="../assets/images/listening.png"
              alt=""
            />
            <p>Listening</p>
            <p className="text-neutral-700 text-center">
              Approximately 45-57 minutes of listening and additional 10 minutes
              for transferring answers.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center md:w-[48%] w-full px-14 gap-y-4 pt-4 pb-10">
            <img
              className="w-[50px] h-[50px]"
              src="../assets/images/reading.png"
              alt=""
            />
            <p>Reading</p>
            <p className="text-neutral-700 text-center">
              32-41 minutes for the Reading section.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center md:w-[48%] w-full px-14 gap-y-4 pt-4 pb-10">
            <img
              className="w-[50px] h-[50px]"
              src="../assets/images/writing.png"
              alt=""
            />
            <p>Writing</p>
            <p className="text-neutral-700 text-center">
              Summarize written text, write an essay, and respond to prompts in
              32-41 minutes.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center md:w-[48%] w-full px-14 gap-y-4 pt-4 pb-10">
            <img
              className="w-[50px] h-[50px]"
              src="../assets/images/speaking.png"
              alt=""
            />
            <p>Speaking</p>
            <p className="text-neutral-700 text-center">
              Speak about familiar topics, and respond to questions in 25-30
              minutes.
            </p>
          </div>
        </div>

        {/* PTE preparation score data */}
        <div className="bg-white w-full py-10">
          <div className="flex flex-col py-5 md:px-0 px-5 md:w-[70%] w-full md:overflow-hidden overflow-x-auto mx-auto gap-y-4">
            <p className="text-2xl">
              <span className="border-b-4 border-black">What</span> does the
              score mean?
            </p>
            <p className="text-sm text-neutral-600">
              The PTE test consists of the following sections.
            </p>
            <table className="items-center bg-transparent w-full border-collapse font-[Poppins]">
              <thead className="bg-[#F2F1F2]">
                <tr>
                  <th className="align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    Section
                  </th>
                  <th className="align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    Description
                  </th>
                  <th className="align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left">
                    Test Time
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="text-neutral-800">
                  <th className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0 text-left font-normal">
                    Listening
                  </th>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    45-57 minutes
                  </td>
                  <td className="border-t-0 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    -
                  </td>
                </tr>
                <tr className="bg-[#F2F1F2] text-neutral-800">
                  <th className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0 text-left font-normal">
                    Reading
                  </th>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    32-41 minutes
                  </td>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    -
                  </td>
                </tr>
                <tr className="text-neutral-800">
                  <th className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0 text-left font-normal">
                    Speaking
                  </th>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    25-30 minutes
                  </td>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    -
                  </td>
                </tr>
                <tr className="bg-[#F2F1F2] text-neutral-800">
                  <th className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0 text-left font-normal">
                    Writing
                  </th>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    32-41 minutes
                  </td>
                  <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 px-0">
                    -
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="text-md py-10 text-neutral-600">
              To book your IELTS/PTE test date, please contact us at 9766847234
              or email us at hub.frontdesk2016@gmail.com or{" "}
              <strong
                className="text-yellow-400 font-semibold cursor-pointer"
                onClick={openModal}
              >
                Click Here
              </strong>
            </p>
          </div>
        </div>
      </main>

      {isModalOpen && (
        <div
          className="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full px-2"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-5 border md:w-1/2 w-full shadow-lg rounded-md bg-white">
            <div className="absolute top-0 right-1">
              <button
                onClick={closeModal}
                className="text-black  rounded-lg text-sm p-1.5"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>

            <form className="space-y-6">
              <h3 className="text-lg  leading-6 text-gray-900 text-center font-semibold capitalize">
                Book now your English Language class with us
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Name
                  </label>
                  <input
                    // value={blogTitle}
                    onChange={(e) => setIsFullName(e.target.value)}
                    type="text"
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    // value={author}
                    onChange={(e) => setIsEmail(e.target.value)}
                    type="email"
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Phone
                  </label>
                  <input
                    // value={shortDescription}
                    onChange={(e) => setIsphone(e.target.value)}
                    type="number"
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Course
                  </label>

                  <select
                    id="countries"
                    onChange={(e) => setIscourse(e.target.value)}
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  >
                    <option selected>Choose class</option>
                    <option value="ielts">Ielts</option>
                    <option value="pte">PTE</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Nearest Office
                  </label>

                  <select
                    id="countries"
                    onChange={(e) => setIsnearestOffice(e.target.value)}
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  >
                    <option selected>Nearest Office</option>
                    <option value="head office">
                      Head Office ( Hattisar )
                    </option>
                    <option value="lalitpur">Lalitpur</option>
                    <option value="pokhara">Pokhara</option>
                    <option value="butwal">Butwal</option>
                    <option value="chitwan">Chitwan</option>
                    <option value="bhairahawa">Bhairahawa</option>
                    <option value="nepalgunj">Nepalgunj</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    min={new Date().toISOString().split('T')[0]}
                    onChange={(e) => setIsDate(e.target.value)}
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    required
                  />
                </div>
              </div>

              <button
                onClick={handleSubmitForm}
                className="w-full text-white bg-yellow-400 hover:bg-yellow-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Apply Now
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default TestPreparation;
