// AdminSidebar.jsx
import {
  faBlog,
  faBook,
  faCalendarAlt,
  faComments,
  faEnvelope,
  faGlobe,
  faInfoCircle,
  faList,
  faMessage,
  faPhotoFilm,
  faSignOutAlt,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const AdminSidebar = ({ children }) => {
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = "/admin-login";
  };

  const sidebarItems = [
    { path: "/admin-dashboard", label: "Dashboard", icon: faTachometerAlt },
    { path: "/admin-testimonial", label: "Testimonial", icon: faMessage },
    { path: "/admin-enqueries", label: "Enqueries", icon: faList },
    { path: "/admin-gallery", label: "Gallery", icon: faPhotoFilm },
    { path: "/admin-testPreparation", label: "Test Preparation", icon: faBook },
    { path: "/admin-country", label: "Universities", icon: faGlobe },
    { path: "/admin-events", label: "Events", icon: faCalendarAlt },
    { path: "/admin-attendee", label: "Attendee", icon: faInfoCircle },
    { path: "/admin-message", label: "Messages", icon: faEnvelope },
    { path: "/admin-sessions", label: "Sessions", icon: faComments },
    { path: "/admin-blogs", label: "Blogs", icon: faBlog },
    { path: "/logout", label: "Log Out", icon: faSignOutAlt },
  ];

  return (
    <>
      <div className="flex flex-row">
        <div className="w-64 min-h-screen bg-[#facc15] text-black">
          <div className="flex items-center justify-center h-20 shadow-md">
            <h1 className="text-3xl uppercase font-bold">Dashboard</h1>
          </div>
          <ul className="py-4">
            {sidebarItems.map((item, index) => (
              <li
                key={index}
                className="pl-6 cursor-pointer py-2 hover:bg-[#ffffff]"
              > 
                <Link to={item.path} onClick={item.path === "/logout" ? handleLogout : undefined}>
                  <FontAwesomeIcon icon={item.icon} className="mr-2" />
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-full">{children}</div>
      </div>
    </>
  );
};

export default AdminSidebar;
