import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const AdminRoutes = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();

  if (!admin) {
    navigate("/");
    return null;
  }
  return <Outlet />;
};

export default AdminRoutes;
