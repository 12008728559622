import React from 'react';

const Document = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            {/* Full-width image container */}
            <div className="w-full overflow-hidden">
                <img className="w-full h-auto object-cover" src="/../assets/images/document.png" alt="Documents" />
            </div>

            {/* Content container with 75% width */}
            <div className="w-3/4 bg-gray-100  mx-auto overflow-hidden">
                <div className="p-8">
                    <h2 className="text-2xl  font-medium text-center mb-6">Download Documents</h2>
                    <p className="text-gray-600 text-md font-medium mb-8">

                        Hub International Education offers guidance and support for students looking to pursue their education in various countries, including Australia, USA, New Zealand, UK, and Ireland. As a leading consultancy in Nepal for international studies, we have established partnerships with top universities and colleges in these countries.
                        The required documents for your application depend on the type of institution and course you wish to pursue. We provide sample documents to assist students in preparing for their visa applications, such as a Financial Sponsorship Letter, Bank Statements, and Income Letters. If you need assistance or have any questions, feel free to reach out to us, and we'll help you navigate the application process effectively.


                    </p>
                    <div className="grid gap-4">
                        {/* Repeat for each document */}
                        <DocumentLink title="Document Checklist for Canada" documentUrl="/assets/documents/canada_checklist.pdf" />
                        <DocumentLink title="Document Checklist for UK" documentUrl="/assets/documents/Uk_checklist.pdf" />
                        <DocumentLink title="Document Checklist for USA" documentUrl="/assets/documents/usa_checklist.pdf"/>
                        <DocumentLink title="Document Checklist for Australia" documentUrl="/assets/documents/australia_checklist.pdf"/>
                        <DocumentLink title="Document Checklist for New Zealand" documentUrl="/assets/documents/newzealand_checklist.pdf"/>
                        <DocumentLink title="Document Checklist for Ireland" documentUrl="/assets/documents/ireland_checklist.pdf"/>
                        {/* <DocumentLink title="Other Documents"documentUrl="/assets/documents/Uk_checklist.pdf"/> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const DocumentLink = ({ title, documentUrl }) => (
    <div className="flex justify-between mb-6 border border-solid border-black items-center bg-gray-100 shadow-lg p-4 rounded-lg">
        <span className='font-bold text-md'>{title}</span>
        <a href={documentUrl} download className="bg-yellow-300 hover:bg-yellow-400 text-dark font-bold py-2 px-4 rounded-md transition-colors duration-150">
            Download
        </a>
    </div>
);


export default Document;
