import React from "react";
import { Link } from "react-router-dom";
import "../errorpage.css";

const user = JSON.parse(localStorage.getItem("admin"));

const ErrorPageUser = () => {
  return (
    <div id="ernotfounduser">
      <div>
        <img
          src="../assets/icon/err.jpg"
          alt="image"
          style={{ height: "400px", width: "400px" }}
        />
      </div>
      <div className="ernotfound">
        <h1>OOPS!</h1>
        <h2>Looks Like You lost your way</h2>
        {user ? (
          <Link className="erbtn" to={"/admin-dashboard"}>
            GoBack
          </Link>
        ) : (
          <Link className="btn" to={"/"}>
            Go back
          </Link>
        )}
      </div>
    </div>
  );
};

export default ErrorPageUser;
