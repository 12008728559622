import React, { useEffect, useState } from "react";
import { getSingleUniversityByCountryApi } from "../../apis/api";
import StickyForm from "../../components/StickyForm";

const Newzealand = () => {
  const images = [
    "/../assets/images/country/england.jpg",
    "/../assets/images/country/india.jpg",
    "/../assets/images/country/canada.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const [university, setUniversity] = useState([]);
  useEffect(() => {
    getSingleUniversityByCountryApi("Newzealand").then((res) => {
      console.log(res.data.universities);
      setUniversity(res.data.universities);
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">
      <div className="w-full flex flex-cols">
        <div className="w-full">
          <img
            src="/../assets/images/studyabroad.jpg"
            alt="Your alt text"
            className="w-full h-auto max-h-98 object-cover"
          />
          <div className="md:absolute flex flex-col top-[190px] md:w-[900px] w-full md:pl-36 pl-10 space-y-2">
            <h1 className="md:text-dark text-2xl md:text-4xl font-medium">
              Your dream of studying in
              <br /> New Zealand is closer than you think!
            </h1>
            <p className="md:text-dark w-[90%]">
              "At Hub International Education, we are dedicated to transforming
              educational aspirations into realities by providing personalized
              guidance and expert support throughout the academic journey."
            </p>
            <button className="bg-yellow-400 w-[210px] text-dark px-5  py-2 mt-2 rounded-md hover:bg-white hover:text-dark transition duration-300">
              Get Started For Free
            </button>
          </div>
        </div>
      </div>

      {/* <div className="w-full md:relative inherit top-0">


            <div className="w-full object-cover">
                <img src="/../assets/images/purple.png" alt="Your alt text" className="w-full h-auto max-h-98 object-cover" />
            </div>

            <div className="absolute flex flex-col items-start md:inset-0 justify-center w-full md:w-1/2 mx-auto">


                <h1 className="text-3xl md:text-4xl font-medium font-semibold ">
                    Your dream of studying in<br /> USA is closer than you think!
                </h1>
                <p className="w-full text-2xl">Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro voluptatum ullam blanditiis voluptas id. Ipsam minus ipsum hic! Natus dolor fuga quae. Porro facere, dicta dignissimos ipsam quos suscipit at.</p>
                <button className="bg-yellow-400 text-black mt-8 px-5 font-semibold py-4 mt-2 rounded-full hover:bg-white hover:text-dark transition duration-300">
                    Get Started For Free
                </button>
            </div>
        </div> */}
      <div className="flex flex-col md:flex-row">
        <div className="container mx-auto p-8 md:mt-8 mt-32 text-justify md:w-[75%] w-[100%]">
          <div className="flex flex-col md:flex-row gap-10 ">
            {/* first division */}
            <div className="flex-1 max-w-4xl">
              <h1 className="text-3xl font-bold mt-4 mb-2">
                Study in New Zealand from Nepal
              </h1>
              <p className="text-gray-700 mb-4">
                New Zealand caters to a wide range of academic pursuits, from
                Diplomas to Masters, ensuring that international students can
                find programs suited to their needs and abilities. It is
                recognized for its top-tier educational standards, safe
                environment, and picturesque landscapes. New Zealand
                consistently ranks among the world's top countries for its
                commitment to providing an ideal setting for student welfare and
                groundbreaking innovations.
                <br /> <br />
              </p>
            </div>
          </div>
          <br />

          <div className="max-w-4xl text-left">
            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Study Levels and Academic Requirements:{" "}
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Degree</div>
                <div>Diploma</div>
                <div>Bachelor / Graduate Diploma</div>
                <div>Post Graduate Diploma</div>
                <div>Masters</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Levels</div>
                <div>5 and 6</div>
                <div>7</div>
                <div>8</div>
                <div>9</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">
                  Academic requirement
                </div>
                <div>50% above</div>
              </div>
            </div>

            <br />
            <br />

            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Language and Funds Requirement{" "}
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Levels</div>
                <div>Diploma</div>
                <div>Bachelor</div>
                <div>Graduate Diploma</div>
                <div>Post Graduate Diploma</div>
                <div>Master</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">IELTS</div>
                <div>5.5 not less than 5</div>
                <div>6 not less than 5.5</div>
                <div>6 not less than 5.5</div>
                <div>6.5 not less than 6</div>
                <div>6.5 not less than 6</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">PTE</div>
                <div>42 not less than 38</div>
                <div>50 not less than 42</div>
                <div>50 not less than 42</div>
                <div>58 not less than 50</div>
                <div>58 not less than 50</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Fund</div>
                <div>50 - 55 lakh</div>
                <div>60 lakhs</div>
                <div>45 lakhs</div>
                <div>45 lakhs</div>
                <div>50 lakhs</div>
              </div>

              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Duration</div>
                <div>2 years</div>
                <div>3 years</div>
                <div>1 year</div>
                <div>1 year</div>
                <div>1.5 years</div>
              </div>
              <br />
            </div>

            <p>
              <p>
                If your IELTS or PTE scores are not high enough to apply
                directly for a bachelor's degree, you may still be eligible to
                apply for a Diploma program that leads to a bachelor's degree.
              </p>
            </p>
            <div className="w-full overflow-hidden mb-12">
              <h1 className="text-red-700 md:text-4xl text-2xl mt-4 md:mt-6 text-center font-semibold">
                Partner Institutions
                <div className="md:w-1/2 w-full mx-auto border-2 border-solid border-yellow-500"></div>
              </h1>

              <div className="flex flex-wrap  justify-center items-center mt-4">
                {university.map((uni, index) => (
                  <a href={uni.description} target="_blank">
                    <div
                      key={index}
                      className="flex flex-wrap items-center space-x-2 space-y-2 p-2 "
                    >
                      <img
                        src={uni.imageUrl}
                        alt={uni.universityName}
                        className="w-40 h-30 object-cover rounded "
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <br />
            <p className="text-xl font-bold ">Gap Acceptance</p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Diploma and Bachelors: up to 5 years{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Masters: up to 8 years{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Conditions apply: Showing relevant work
              experience is essential to fill the gap.{" "}
            </p>
            <br />

            <br />
            <p className="text-xl font-bold ">Intakes</p>
            <br />
            <p>
              Jan, Feb, March, April, May, July, September, October, November
            </p>
            <br />

            <br />
            <p className="text-xl font-bold ">Green List course</p>
            <br />
            <p>
              Health, Engineering, Construction Management, Agriculture, Child
              Care
            </p>
            <br />

            <br />
            <p className="text-xl font-bold ">
              Reasons to Study in New Zealand
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> The educational institutions provide students
              with a diverse array of course options, including specialized
              programs.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> New Zealand's education system ranks highly in
              global indices for preparing students for the future and teaching
              environment quality.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> The cost of living is more affordable compared
              to other study destinations.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> New Zealand offers a high standard of living,
              stunning landscapes, and a healthy lifestyle.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> The country's strong economy provides ample
              employment opportunities for international students.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> New Zealand has a peaceful and politically
              stable society, ensuring safety and security.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Studying in New Zealand provides practical,
              hands-on learning experiences and opportunities for cultural
              immersion{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Personal growth and development are
              emphasized, with a supportive environment for acquiring skills
              applicable in a globalized society.{" "}
            </p>
            <br />

            <br />
            <p className="text-xl font-bold ">Facts about New Zealand</p>
            <br />
            <p className="pl-10">
              <strong>• </strong> All universities in New Zealand are positioned
              within the top 3% globally.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> New Zealand was the first country to introduce
              pastoral care requirements aimed at supporting and safeguarding
              international students.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> New Zealand ranks first among English-speaking
              countries in preparing students for the future.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> New Zealand pioneered the implementation of a
              well-being strategy for international students, leading among all
              nations.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> New Zealand holds the second rank globally out
              of 163 countries for its peaceful living environment.{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> New Zealand ranks within the top 10 for
              creativity among 139 nations and secures the 22nd position for
              innovation out of 127 countries.{" "}
            </p>
            <br />

            <p className="text-xl font-bold ">Working Rights</p>
            <br />
            <p>
              During the academic session, international students are permitted
              to engage in part-time work for up to 20 hours per week, and
              during scheduled breaks, they may work for up to 40 hours per
              week.
            </p>
            <br />

            <h2 className="text-xl font-bold ">Application Procedure</h2>

            <br />
            <p className="pl-10">
              <strong>Step 1: </strong> Select Course and College{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>Step 2: </strong> Apply for an offer letter{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>Step 3: </strong> Prepare Ward Documents{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>Step 4: </strong> Funding Arrangements{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>Step 5: </strong> Medical{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>Step 6: </strong> Documents Assessment{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>Step 7: </strong> Visa Lodgment{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>Step 8: </strong> Apply for NOC{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>Step 9: </strong> Interview Preparation{" "}
            </p>
            <br />
          </div>
        </div>
        <StickyForm country="newzealand"></StickyForm>
      </div>
    </div>
  );
};

export default Newzealand;
