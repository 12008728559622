import React, { useEffect, useState } from "react";
import { getMultipleFilesApi, getSingleFilesApi } from "../apis/api";

const SingleImageModal = ({ image, closeModal }) => (
  <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
    <div className="relative">
      <button
        className="absolute top-0 right-0 m-4 text-white text-2xl"
        onClick={closeModal}
      >
        &times;
      </button>
      <img
        src={`${process.env.REACT_APP_BASE_URL}/${image.filePath}`}
        alt="Selected Image"
        className="max-w-[70vw] max-h-[70vh]"
      />
    </div>
  </div>
);

const SpecialEventModal = ({
  event,
  currentChildIndex,
  handlePrevChild,
  handleNextChild,
  closeModal,
}) => {
  if (!event || !event.files || event.files.length === 0) {
    return null; // Render nothing if event or childImages array is undefined or empty
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative">
        <button
          className="absolute top-1/2 left-0 m-4 text-white text-2xl"
          onClick={handlePrevChild}
        >
          &#8249;
        </button>
        <button
          className="absolute top-1/2 right-0 m-4 text-white text-2xl"
          onClick={handleNextChild}
        >
          &#8250;
        </button>
        <button
          className="absolute top-0 right-0 m-4 text-white text-2xl"
          onClick={closeModal}
        >
          &times;
        </button>
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${event.files[currentChildIndex].filePath}`}
          alt="Selected Image"
          className="max-w-[70vw] max-h-[70vh]"
        />
        <p className="absolute bottom-0 w-full bg-gray-900 bg-opacity-75 text-white text-center py-2">
          {event.title}
        </p>
      </div>
    </div>
  );
};

const GalleryPage = () => {
  const [singleFiles, setSingleFiles] = useState([]);
  const [multipleFiles, setMultipleFiles] = useState([]);

  const getSingleFileslist = async () => {
    try {
      const fileslist = await getSingleFilesApi();
      setSingleFiles(fileslist);
    } catch (error) {
      console.log(error);
    }
  };
  const getMultipleFilesList = async () => {
    try {
      const fileslist = await getMultipleFilesApi();
      setMultipleFiles(fileslist);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleFileslist();
    getMultipleFilesList();
  }, []);

  const isVideo = (url) => {
    const videoExtensions = [".mp4", ".mkv", ".webm"];
    return videoExtensions.some((extension) => url.endsWith(extension));
  };
  const [displayType, setDisplayType] = useState("special");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentChildIndex, setCurrentChildIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!modalVisible || !selectedImage) return;
      if (event.keyCode === 37) {
        // Left arrow key
        handlePrevChild();
      } else if (event.keyCode === 39) {
        // Right arrow key
        handleNextChild();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalVisible, selectedImage]);

  const openModal = (image) => {
    setSelectedImage(image);
    setCurrentChildIndex(0); // Reset the current child index when opening a new image
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalVisible(false);
  };

  const handleNextChild = () => {
    if (!selectedImage || !selectedImage.files) return;
    setCurrentChildIndex(
      (prevIndex) => (prevIndex + 1) % selectedImage.files.length
    );
  };

  const handlePrevChild = () => {
    if (!selectedImage || !selectedImage.files) return;
    setCurrentChildIndex((prevIndex) =>
      prevIndex === 0 ? selectedImage.files.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      <div className="w-[100%] h-[400px]">
        <img
          className="w-[100%] h-[100%] object-cover"
          src="/assets/images/test-hero.png"
          alt=""
        />
        <div className="relative w-full -top-56 md:flex justify-center items-center px-20">
          <p className="text-white font-bold md:text-4xl pb-10 text-2xl">
            Gallery
          </p>
        </div>
      </div>

      <div className="container mx-auto md:mt-12 mt-32">
        {/* <div className="flex justify-center rounded-lg mb-4  py-3 ">
        <button
          className={`mr-4 py-3 px-6 font-medium bg-yellow-200 rounded ${
            displayType === "single" ? "bg-yellow-400" : ""
          }`}
          onClick={() => setDisplayType("single")}
        >
          Gallery
        </button>

        <button
          className={`py-3 px-6 font-medium bg-yellow-200 rounded ${
            displayType === "special" ? "bg-yellow-400" : ""
          }`}
          onClick={() => setDisplayType("special")}
        >
          Collection
        </button>
      </div> */}

        {/* Division to display images based on displayType */}
        <div className="flex flex-wrap mb-10 rounded-md">
          {displayType === "single" && multipleFiles.length > 0 ? (
            //  singleFiles.map((image, index = image._id) => (
            //     <div key={index} className="w-[300px] mx-auto">
            //       <div
            //         className="rounded-lg overflow-hidden cursor-pointer"
            //         onClick={() => openModal(image)}
            //       >
            //         <img
            //           src={`${process.env.REACT_APP_BASE_URL}/${image.filePath}`}
            //           height="200"
            //           className="card-img-top border-dark img-responsive"
            //           alt={`Single Image ${index + 1}`}
            //         />
            //       </div>
            //     </div>
            //   ))
            <h1>No Images Found</h1>
          ) : (
            multipleFiles.map((event, index = event._id) => (
              <div key={index} className="w-[300px] mx-auto">
                <div
                  className="rounded-lg overflow-hidden cursor-pointer mb-2"
                  onClick={() => openModal(event)}
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${event.files[0].filePath}`}
                    alt={`Special Event Parent Image ${event.files[0]._id + 1}`}
                    className="w-full h-56 object-cover"
                  />
                  {/* {multipleFiles.length} */}
                  <p className="p-2 font-medium text-center bg-yellow-400">
                    {event.title}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>

        {/* Modal */}
        {modalVisible && (
          <>
            {displayType === "single" ? (
              <SingleImageModal image={selectedImage} closeModal={closeModal} />
            ) : (
              <SpecialEventModal
                event={selectedImage}
                currentChildIndex={currentChildIndex}
                handlePrevChild={handlePrevChild}
                handleNextChild={handleNextChild}
                closeModal={closeModal}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default GalleryPage;
