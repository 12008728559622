import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ErrorPage from "./components/ErrorPage";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Aboutus from "./pages/Aboutus";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import Document from "./pages/Document";
import GalleryPage from "./pages/Gallery";
import Homepage from "./pages/Homepage";
import Media from "./pages/Media";
import TestPreparation from "./pages/TestPreparation";
import AdminAttendee from "./pages/admin/Admin-EventAttendee";
import AdminAboutUs from "./pages/admin/Admin-aboutus";
import AdminEnqueries from "./pages/admin/Admin-enqueries";
import AdminMessage from "./pages/admin/Admin-message";
import AdminTextPreparation from "./pages/admin/Admin-testPrep";
import AdminBlog from "./pages/admin/AdminBlogs";
import AdminCountry from "./pages/admin/AdminCountry";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminEditAbout from "./pages/admin/AdminEditAbout";
import AdminEditBlogs from "./pages/admin/AdminEditBlogs";
import AdminEditCountry from "./pages/admin/AdminEditCountry";
import AdminEditEvents from "./pages/admin/AdminEditEvents";
import AdminEditTestiMonials from "./pages/admin/AdminEditTestimonials";
import AdminEvents from "./pages/admin/AdminEvents";
import AdminGallery from "./pages/admin/AdminGallery";
import Login from "./pages/admin/AdminLogin";
import AdminSidebar from "./pages/admin/AdminSidebar";
import AdminTestimonial from "./pages/admin/AdminTestimonials";
import BlogContent from "./pages/blogsContent";
import Canada from "./pages/country/Canada";
import England from "./pages/country/England";
import India from "./pages/country/India";
import Ireland from "./pages/country/Ireland";
import Newzealand from "./pages/country/NewZeland";
import StudyPage from "./pages/country/StudyPage";
import USA from "./pages/country/USA";
import EventBookingPage from "./pages/eventBooking";
import MyPdfViewer from "./pages/pdf_reader";
import AdminRoutes from "./protected/AdminRoutes";
import ErrorPageUser from "./components/ErrorPageUser";
import AdminCountryEnqueries from "./pages/admin/Admin-countryEnqueries";

function App() {
  const admin = JSON.parse(localStorage.getItem("admin"));

  return (
    <Router>
      {!admin && <Navbar />}

      <ToastContainer />
      <Routes>
        {!admin ? (
          <Route>
            <Route path="/study-in-australia" element={<StudyPage />} />
            <Route path="/study-in-usa" element={<USA />} />
            <Route path="/study-in-canada" element={<Canada />} />
            <Route path="/study-in-india" element={<India />} />
            <Route path="/study-in-ireland" element={<Ireland />} />
            <Route path="/study-in-uk" element={<England />} />
            <Route path="/study-in-newzeland" element={<Newzealand />} />

            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/download-important-document" element={<Document />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/test-preparation" element={<TestPreparation />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs-content" element={<BlogContent />} />
            <Route path="/gallery" element={<Media />} />
            <Route path="/galleries" element={<GalleryPage />} />
            <Route path="/admin-login" element={<Login />} />
            <Route path="/event-booking" element={<EventBookingPage />} />
            <Route path="/user-blogs/:id" element={<MyPdfViewer />} />
            <Route path="/*" element={<ErrorPageUser />} />
            <Route
              path="/read-individual-blogs/:id"
              element={<MyPdfViewer />}
            />
          </Route>
        ) : (
          <Route path="/*" element={<ErrorPage />} />
        )}
        {admin ? (
          <Route element={<AdminRoutes />}>
            <Route path="/admin-gallery" element={<AdminGallery />} />
            <Route path="/admin-sidebar" element={<AdminSidebar />} />
            {/* <Route path="/admin-blogs" element={<AddPost />} /> */}
            <Route path="/admin-blogs" element={<AdminBlog />} />
            <Route path="/admin-events" element={<AdminEvents />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/admin-country" element={<AdminCountry />} />
            <Route path="/admin-testimonial" element={<AdminTestimonial />} />
            <Route path="/admin-aboutUs" element={<AdminAboutUs />} />
            <Route path="/admin-message" element={<AdminMessage />} />
            <Route path="/admin-sessions" element={<AdminCountryEnqueries />} />
            <Route path="/admin-attendee" element={<AdminAttendee />} />
            <Route
              path="/admin-testPreparation"
              element={<AdminTextPreparation />}
            />
            <Route path="/admin-enqueries" element={<AdminEnqueries />} />
            <Route path="/admin-edit-blogs/:id" element={<AdminEditBlogs />} />
            <Route
              path="/admin-edit-testimonial/:id"
              element={<AdminEditTestiMonials />}
            />
            <Route
              path="/admin-edit-country/:id"
              element={<AdminEditCountry />}
            />
            <Route
              path="/admin-edit-events/:id"
              element={<AdminEditEvents />}
            />
            <Route
              path="/admin-edit-aboutUs/:id"
              element={<AdminEditAbout />}
            />
          </Route>
        ) : (
          <Route path="/*" element={<ErrorPage />} />
        )}
      </Routes>

      {!admin && <Footer />}
    </Router>
  );
}

export default App;
