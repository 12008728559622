import React, { useEffect, useState } from "react";
import { getMultipleFilesApi, getSingleFilesApi, deleteImageApi, update_galleryApi } from "../../apis/api"; // Assuming you have an API function to delete images
import FileUploadScreen from "../../components/FileUploadScreen";
import AdminSidebar from "./AdminSidebar";
import { toast } from "react-toastify";

function AdminGallery() {
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [editingTitleIndex, setEditingTitleIndex] = useState(null);
  const [individuallyDeletingIndex, setIndividuallyDeletingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteImageId, setDeleteImageId] = useState(null); // State to store the ID of the image to be deleted

  const getMultipleFilesList = async () => {
    try {
      const fileslist = await getMultipleFilesApi();
      setMultipleFiles(fileslist);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMultipleFilesList();
  }, []);

  const handleEditTitle = (index) => {
    setEditingTitleIndex(index);
    setIndividuallyDeletingIndex(null);
  };

  const handleTitleChange = (event, index) => {
    const newTitle = event.target.value;

    setMultipleFiles((prevState) =>
      prevState.map((file, i) =>
        i === index ? { ...file, title: newTitle } : file
      )
    );
  };
  const handleSaveTitle = () => {
    const updatedTitle = multipleFiles[editingTitleIndex].title;
    const fileId = multipleFiles[editingTitleIndex]._id;
  
    const requestData = {
      title: updatedTitle
    };
  
    update_galleryApi(fileId, requestData)
      .then((res) => {
        toast.success("Title updated!! ");
        getMultipleFilesList();
        window.location.reload(); 

        // if (res.data.success) {
        //   toast.success(res.data.message);
        // } else {
        //   toast.error(res.data.message);
        // }
      })
  };
  
  
  
  
  const handleDeleteImage = (index, imageId) => { 
    setShowDeleteModal(true);
    setDeleteIndex(index);
    setDeleteImageId(imageId); 
  };


  const confirmDelete = () => {
    deleteImageApi(deleteImageId)
    .then((response) => { 
      toast.success("successful"); 
      getMultipleFilesList();

      // if (res.data.success === true) {
      //   toast.success(res.data.message);
        // getMultipleFilesList(); 
      // }
      //  else {
      //   toast.error(res.data.message);
      // }

    });
    setShowDeleteModal(false);
  };
  

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteIndex(null);
    setDeleteImageId(null); // Reset the deleteImageId state
  };

  return (
    <AdminSidebar>
          <div className=" w-full sm:px-6 ">
          <div className=" md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
            <div className="sm:flex items-center justify-between">
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                Gallery
              </p>
              <div className="flex flex-row items-center">
              </div>
            </div>
          </div>
          </div>

      <div className="h-[100vh] overflow-scroll">
        <div className="container ">
          <FileUploadScreen getMultiple={() => getMultipleFilesList()} />
        </div>

        <div className="col-6 mt-4">
          <h1 className="text-success font-weight-bold ml-12 font-bold">
            All Images
          </h1>
          {multipleFiles.map((element, index) => (
            <div
              className="mb-4 mt-4 ml-12 border-2 border-gray-200 flex justify-between"
              key={index}
            >
              <div>
                <div className="relative">
                  {editingTitleIndex === index ? (
                    <div>
                      <input
                        type="text"
                        value={element.title}
                        onChange={(event) => handleTitleChange(event, index)}
                        onBlur={() => handleSaveTitle()}
                        className="m-1 block w-2/5 border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm p-2.5"
                      />
                       <button
                    className="ml-2 px-2 py-1 bg-yellow-400 text-black rounded"
                    onClick={() => handleSaveTitle()}
                  >
                    Save
                  </button>
                    </div>
                    
                  ) : (
                    <h6 className="text-lg font-bold p-4">{element.title}</h6>
                  )}
                </div>
                <div className="flex flex-wrap">
                  {element.files.map((file, fileIndex) => (
                    <div key={fileIndex} className="relative">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/${file.filePath}`}
                        className="img-responsive p-2"
                        alt={file.title}
                        style={{ maxWidth: "200px", maxHeight: "200px" }}
                      />
                      {/* {editingTitleIndex === index && (
                        <button className="absolute top-0 right-0 text-red-500 p-1">
                          <i className="fas fa-trash"></i>
                        </button>
                      )} */}
                    </div>
                  ))}
                </div>
              </div>

              <div className="pr-2">
                <button className="p-1" 
                onClick={() => handleEditTitle(index)}
                >
                  <i className="fas fa-edit"></i>
                </button>
                <button
                  className=" text-red-500 p-1"
                  onClick={() => handleDeleteImage(index, element._id)} // Pass the image ID
                >
                  <i className="fas fa-trash"></i>
                </button>
                {/* {editingTitleIndex === index ? (
                  <button
                    className="ml-2 px-2 py-1 bg-yellow-400 text-black rounded"
                    onClick={() => handleSaveTitle()}
                  >
                    Save
                  </button>
                ) : null} */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {showDeleteModal && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          id="my-modal"
        >
          <div className="relative mx-auto p-5 border w-1/4 shadow-lg rounded-md bg-white space-y-8 justify-center items-center flex flex-col">
            <i className="fa-solid fa-triangle-exclamation text-red-500 fa-5x"></i>
            <h1 className="font-medium w-3/4 mx-auto text-center">
              Are you sure you want to Delete?
            </h1>
            <div className="flex flex-wrap items-center justify-between mx-auto w-full">
              <button
                className="w-1/3 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center py-2.5"
                onClick={confirmDelete}
              >
                Delete
              </button>
              <button
                className="w-1/3 text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-2.5"
                onClick={cancelDelete}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </AdminSidebar>
  );
}

export default AdminGallery;