import React, { useEffect, useState } from "react";
import { getallAboutusApi } from "../apis/api";

const Aboutus = () => {
  // for fetching all the Testimonials and showing in table
  const [aboutus, setAboutus] = useState([]);
  useEffect(() => {
    getallAboutusApi().then((res) => {
      setAboutus(res.data.aboutus);
    });
  }, []);

  return (
    <div className="w-full flex flex-col">
      {/* first division */}
      <div
        className="relative bg-cover bg-center text-white"
        style={{ backgroundImage: `url('/../assets/images/Goldenbridge.png')` }}
      >
        <div className="container mx-auto px-4 py-16 md:py-32">
          <div className="text-center">
            <h1 className="text-4xl md:text-4xl font-bold">
              We help international students connect to the right study
              opportunities
            </h1>
            <p className="mt-4 md:mt-6 text-base md:text-md">
              Our extensive experience in the international education industry
              makes following your dream of study a simpler, easier process
            </p>
            <button className="mt-8 px-6 py-2 bg-yellow-400 text-white rounded-full font-bold shadow-lg hover:bg-yellow-600 transition duration-300">
              SPEAK TO US
            </button>
          </div>
        </div>
      </div>

      {/* first division upto here */}

      {/* second division */}
      <div className="bg-white p-6 md:p-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <h2 className="text-2xl font-bold">Background</h2>
            <p className="text-justify">
              Hub International Education Consultancy began its journey for
              study abroad from early 2006 and has continued to provide
              counselling services for the last 17 years. Through continuous
              diligence and hard work, only after 10 years of operation, the
              company expanded its operations at the global level and has an
              established head office in Perth, Western Australia. Hub
              International’s humble beginnings, with a sense of purpose and
              unique heritage, has been characterised by innovation and social
              commitment. Currently, the Education Consultancy boasts 500+
              institution partners in the UK, USA, Australia, Canada, India, New
              Zealand and Ireland and a strong presence of a network of offices
              in Nepal, UAE, Philippines, Vietnam, Pakistan, Sri Lanka, India
              and Myanmar with many more on the way. Hub International has been
              emerging as a global market for students to expand their
              educational portfolio abroad. The company is the most trusted
              Study Abroad Education Consultancy with a dedicated team of
              advisers and support staff, not only knowledgeable but also easily
              accessible to our students. This ensures that no query is left
              unanswered for prospective students who wish to attain
              international qualifications from reputed partner institutions of
              Hub International Education Consultant.
            </p>
          </div>

          <div className="relative">
            <img
              src="/../assets/images/hub1.png"
              alt="Consultation"
              className=" mx-auto"
              style={{ width: "80%", height: "auto" }}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="relative">
            <img
              src="/../assets/images/hub1.png"
              alt="Consultation"
              className=" mx-auto"
              style={{ width: "80%", height: "auto" }}
            />
          </div>
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mt-8 md:mt-12 ">
              Our Specialisation
            </h2>
            <p className="text-justify">
              We at Hub International make sure that our counsellors provide end
              to end individualised guidance and support for each student from
              the get-go, to facilitate custom made career pathways that
              perfectly fit students’ profiles, aspirations and future goals.
              Starting with course selection, fee structure and scholarship
              program to submission of an application to the institutions, we
              ensure that every step is meticulously taken care of to
              perfection. In addition to this, our team has the necessary
              expertise and experience to assist students with visa processing
              and arrival support to make their education journey smooth.
            </p>
          </div>
        </div>
      </div>

      {/* second division upto here */}

      {/* third division from here */}

      <div className="bg-white p-6">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="bg-yellow-200 rounded-xl p-4 shadow-lg py-12">
              <h2 className="text-2xl font-semibold text-center">Our Vision</h2>
              <p className="text-lg mt-2">
                At Hub, we intend to invest our expertise in shaping the future
                of the youth by empowering them through international education
                and engagement with informed choices for a better world.
              </p>
            </div>

            <div className="bg-yellow-200 rounded-xl p-4 shadow-lg py-12">
              <h2 className="text-2xl font-semibold text-center">
                Our Mission
              </h2>
              <p className="text-lg mt-2">
                We aspire to be the best, most reliable and most preferred brand
                in study abroad Consultancy and to shape careers and lives
                through global education and engagement.
              </p>
            </div>

            <div className="bg-yellow-200 rounded-xl p-4 shadow-lg py-12">
              <h2 className="text-2xl font-semibold text-center">Our Goal</h2>
              <p className="text-lg mt-2">
                To take pride in the highest standard services, praise, promote,
                support and deliver all that as excellent to our clients.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* third division upto here */}

      {/* fourth division from here */}
      {/* <div className="bg-white p-4 md:p-8">
        <div className="flex flex-wrap justify-center gap-4 md:gap-20">
          {aboutus.map((each) => (
            <div className="max-w-md bg-white rounded-lg shadow-md overflow-hidden border-2 border-solid border-gray-500 ">
              <div className="p-6">
                <h2 className="text-xl font-bold text-center mb-4">
                  Message From {each.post}
                </h2>
                <div className="flex justify-center">
                  <img
                      src={each.imageUrl}
                      alt="Chairman"
                    className="rounded-full w-32 h-32 mb-4"
                  />
                </div>
                <h3 className="text-lg font-semibold text-center">
                  {each.fullName}
                </h3>
                <p className="text-center text-sm mb-4">{each.post}</p>
                <p className="text-gray-700 text-md " title={each.message}>
                    {each.message}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      {/* fourth division upto here */}

      <div className="bg-white px-4 py-10 md:px-10 lg:px-20 xl:px-20 2xl:px-30 my-10">
        <div className="flex flex-col lg:flex-row items-start lg:items-center lg:space-x-12">
          {/* Image container */}
          <div className="flex-shrink-0 mb-6 lg:mb-0 lg:w-1/3 lg:mr-12">
            <img
              src="/../assets/images/about/chairman.jpeg"
              alt="Profile"
              className="w-full h-auto rounded" // Adjust this to control the size of the image
            />
          </div>

          {/* Text container */}
          <div className="flex-grow mt-4 lg:mt-0 lg:w-1/2">
            <h2 className="text-3xl font-bold text-center lg:text-left mb-4">
              Message From  Chairman
            </h2>
            <p className="text-gray-700 text-lg mb-4 leading-relaxed text-justify">
              As Chairperson of Hub International, my aim is to provide abundant
              opportunities for youth by advocating quality education. While
              Nepal’s education system progresses, it has yet to meet global
              standards. Encouraging students to pursue education abroad and
              return to apply their skills in Nepal can drive significant
              change. We serve as a conduit for youth to achieve their full
              potential. I take pride in the thousands of students who have
              realized their career aspirations through our organization. Our
              ongoing commitment to meticulous student support continues to
              attract students seeking expert guidance. 
            </p>
            <p className="text-gray-700 text-lg mb-4 leading-relaxed text-justify">
            At Hub, we attribute our
              success to trust, transparency, and accountability. Upholding the
              highest standards of corporate governance, we prioritize
              integrity, ethics, and responsibility in all our operations.
            </p>

            <p className="text-lg font-semibold text-center lg:text-left">
            Mr. Udhav Bahadur Khadka, Ph.D
            </p>
            <p className="text-center lg:text-left text-sm">
              Chairman, HUB International Education 
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white px-4 py-10 md:px-10 lg:px-20 xl:px-20 2xl:px-30 my-10">
        <div className="flex flex-col lg:flex-row items-start lg:items-center lg:space-x-12">
          {/* Image container */}
                  {/* Text container */}
          <div className="flex-grow mt-4 lg:mt-0 lg:w-1/2">
            <h2 className="text-3xl font-bold text-center lg:text-left mb-4">
              Message From  CEO
            </h2>
            <p className="text-gray-700 text-lg mb-4 leading-relaxed text-justify">
            At Hub, our unwavering commitment is to provide outstanding services that empower students to excel. Since our inception, we’ve strived to be industry leaders in education consultancy. Our dedicated team of experts is the cornerstone of our success, driving us towards our goals. We prioritize both organizational duties and strategic stakeholder relationships. Our vision remains clear: to shape the future of youth through international education, enabling them to fulfill their aspirations. We invest continuously in research, technology, infrastructure, and our employees to ensure the highest quality counseling services for our students and clients.
            </p>
           
            <p className="text-lg font-semibold text-center lg:text-left">
            Mrs. Sarita Khadka
            </p>
            <p className="text-center lg:text-left text-sm">
              CEO, HUB International Education
            </p>
          </div>
          <br />
          <div className="flex-shrink-0 mb-6 lg:mb-0 lg:w-1/3 lg:mr-12">
            <img
              src="/../assets/images/about/ceo.jpeg"
              alt="Profile"
              className="w-full h-auto rounded" // Adjust this to control the size of the image
            />
          </div>
        </div>
      </div>


    </div>
  );
};

export default Aboutus;
