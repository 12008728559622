import React, { useEffect, useState } from "react";
import { getSingleUniversityByCountryApi } from "../../apis/api";
import StickyForm from "../../components/StickyForm";

const StudyPage = () => {
  const images = [
    "/../assets/images/country/england.jpg",
    "/../assets/images/country/india.jpg",
    "/../assets/images/country/canada.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const [university, setUniversity] = useState([]);
  useEffect(() => {
    getSingleUniversityByCountryApi("Australia").then((res) => {
      console.log(res.data.universities);
      setUniversity(res.data.universities);
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">
      <div className="w-full flex flex-cols">
        <div className="w-full">
          <img
            src="/../assets/images/studyabroad.jpg"
            alt="Your alt text"
            className="w-full h-auto max-h-98 object-cover"
          />
          <div className="md:absolute flex flex-col top-[190px] md:w-[900px] w-full md:pl-36 pl-10 space-y-2">
            <h1 className="md:text-dark text-2xl md:text-4xl font-medium">
              Your dream of studying in
              <br /> Australia is closer than you think!
            </h1>
            <p className="md:text-dark w-[90%]">
              "At Hub International Education, we are dedicated to transforming
              educational aspirations into realities by providing personalized
              guidance and expert support throughout the academic journey."
            </p>
            <button className="bg-yellow-400 w-[210px] text-dark px-5  py-2 mt-2 rounded-md hover:bg-white hover:text-dark transition duration-300">
              Get Started For Free
            </button>
          </div>
        </div>
      </div>

      {/* <div className="w-full md:relative inherit top-0">


                <div className="w-full object-cover">
                    <img src="/../assets/images/purple.png" alt="Your alt text" className="w-full h-auto max-h-98 object-cover" />
                </div>

                <div className="absolute flex flex-col items-start md:inset-0 justify-center w-full md:w-1/2 mx-auto">


                    <h1 className="text-3xl md:text-4xl font-medium font-semibold ">
                        Your dream of studying in<br /> Australia is closer than you think!
                    </h1>
                    <p className="w-full text-2xl">Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro voluptatum ullam blanditiis voluptas id. Ipsam minus ipsum hic! Natus dolor fuga quae. Porro facere, dicta dignissimos ipsam quos suscipit at.</p>
                    <button className="bg-yellow-400 text-black mt-8 px-5 font-semibold py-4 mt-2 rounded-full hover:bg-white hover:text-dark transition duration-300">
                        Get Started For Free
                    </button>
                </div>
            </div> */}
      <div className="flex flex-col md:flex-row">
        <div className="container mx-auto p-8 md:mt-8 mt-32 text-justify md:w-[75%] w-[100%]">
          <div className="flex flex-col md:flex-row gap-10 ">
            {/* first division */}
            <div className="flex-1 max-w-4xl">
              <h1 className="text-3xl font-bold mt-4 mb-2">
                Study in Australia from Nepal
              </h1>
              <p className="text-gray-700 mb-4">
                Coming to Australia to study can be both an exciting and
                overwhelming experience for many students. Australia, the
                smallest continent and one of the largest countries on Earth,
                lies between the Pacific and Indian oceans in the Southern
                Hemisphere. Australia’s capital is Canberra, located in the
                southeast between the larger and more important economic and
                cultural centers of Sydney and Melbourne. • Australia is a
                popular destination for international students, known for its
                high-quality education system and universities. It is home to
                world-class institutions offering a wide range of courses. • The
                country is famous for its iconic landmarks, including the Sydney
                Opera House, the Great Barrier Reef, and Uluru. • The country is
                also known for its rich Indigenous history, with Aboriginal and
                Torres Strait Islander cultures playing a significant role in
                shaping Australia's identity.
                <br /> <br />
              </p>

              <h1 className="text-xl font-bold mt-4 mb-2">
                Reasons and benefits of Study in Australia for Nepalese students
              </h1>
              <p className="pl-10">
                <strong>• </strong> The country's emphasis on English as the
                primary language of instruction provides an immersive language
                learning experience for students, enhancing their language
                skills and increasing their employability.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Australian universities are globally
                renowned for their high-quality education and research
                opportunities, providing students with a strong academic
                foundation.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> The country boasts a diverse range of
                courses and degrees across various disciplines, ensuring that
                students can pursue their academic interests and career goals.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Lower cost of education, tuition fees start
                from as low as NPR 10 lakhs per annum{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Australia offers post-study work visas to
                international students who have completed a bachelor's degree or
                higher, allowing them to stay and work in Australia{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> International students in Australia can work
                up to 48 hours per fortnight during the semester and full-time
                during semester breaks, helping them gain work experience and
                support themselves financially. This practical work experience
                is invaluable, providing insights into the global workforce and
                enhancing resumes.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Degrees obtained from Australian
                institutions are recognized worldwide, offering students global
                career opportunities and a competitive edge in the job market{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> For Nepalese students, studying in Australia
                is not just about gaining a degree; it's about experiencing a
                new culture, improving English skills, and preparing for a
                global career in a supportive and high-quality educational
                environment{" "}
              </p>

              <br />
              <h1 className="text-xl font-bold mt-4 mb-2">
                Student Life in Australia
              </h1>
              <p className="pl-10">
                <strong>• </strong> Student life in Australia is a vibrant mix
                of high-quality academics and an engaging social atmosphere,
                enriched by a diverse and inclusive culture{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Universities boast innovative teaching and
                modern facilities, complemented by a range of extracurricular
                activities and clubs that foster community spirit and personal
                interests. International students, including those from Nepal,
                benefit from supportive services designed to ease their
                transition, such as orientation programs and academic support.
                The opportunity to work part-time helps with living expenses,
                while Australia’s safe environment and comprehensive healthcare
                ensure a secure study experience.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Moreover, the country’s diverse landscapes
                offer ample opportunities for exploration and leisure, making
                student life in Australia not just about gaining an education
                but also about personal growth and adventure. This blend of
                quality education, support, and cultural exposure makes studying
                in Australia a uniquely rewarding experience.{" "}
              </p>
              <br />

              <br />
              <h1 className="text-xl font-bold mt-4 mb-2">
                Popular Programs for International Students in Australia
              </h1>
              <p>
                Australia has a wide range of programs that are offered to
                Nepalese students that have become particularly popular due to
                their global recognition, career prospects, and alignment with
                the needs of both the Australian and Nepalese job markets.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Nursing and Healthcare:{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Information Technology (IT) and Computer
                Science{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Engineering{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Business and Management{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Hospitality and Tourism Management{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Social Work{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Education and Teaching{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Public Health{" "}
              </p>
              <br />
              <p>
                These programs are not only popular for their academic and
                career opportunities but also for the potential they offer for
                Nepalese students to gain international experience, improve
                their English skills, and, in many cases, pursue pathways to
                permanent residency in Australia.
              </p>

              <br />
              <h1 className="text-xl font-bold mt-4 mb-2">
                Academic requirement in Australia
              </h1>
              <p>Bachelor: 3-4 years</p>
              <br />
              <p className="pl-10">
                <strong>• </strong> 12 Years of full-time education{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> 2.8 GPA and above{" "}
              </p>
              <br />
              <p>Master: 2 Years</p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Bachelor’s Degree Completed{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> 2.8 GPA and above/ First Division{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> 3 years bachelor’s also accepted{" "}
              </p>

              <br />
              <p>In the case of married</p>
              <br />
              <p className="pl-10">
                <strong>• </strong> 2 Years of marriage registration{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Needed the Same qualification as Spouse{" "}
              </p>
              <br />
            </div>
          </div>
          <br />

          <div className="max-w-4xl text-left">
            <div className="w-full overflow-hidden mb-12">
              <h1 className="text-red-700 md:text-4xl text-2xl mt-4 md:mt-6 text-center font-semibold">
                Partner Institutions
                <div className="md:w-1/2 w-full mx-auto border-2 border-solid border-yellow-500"></div>
              </h1>

              <div className="flex flex-wrap  justify-center items-center mt-4">
                {university.map((uni, index) => (
                  <a href={uni.description} target="_blank">
                    <div
                      key={index}
                      className="flex flex-wrap items-center space-x-2 space-y-2 p-2 "
                    >
                      <img
                        src={uni.imageUrl}
                        alt={uni.universityName}
                        className="w-40 h-30 object-cover rounded "
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Study Levels with Entry Requirements{" "}
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Levels</div>
                <div>Bachelor</div>
                <div>Master</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">IELTS</div>
                <div>6 not less than 5.5</div>
                <div>6.5 not less than 6</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">PTE</div>
                <div>50 not less than 45</div>
                <div>58 not less than 50</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Fund</div>
                <div>55 Lakhs and above</div>
                <div>60 lakhs and above</div>
              </div>

              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Academic</div>
                <div>2.0</div>
                <div>2.2</div>
                <div>Any Percentage</div>
                <div>45% and above</div>
              </div>
            </div>

            <br />
            <h2 className="text-xl font-bold ">Intake</h2>
            <br />
            <p className="pl-10">
              <strong>• </strong> Jan/Feb{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Jun/July{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Sep/Oct{" "}
            </p>
            <br />

            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Language and Funds Requirement{" "}
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">For UG</div>
                <div>
                  SEE mark sheet, Grade sheet, and Character certificate
                </div>
                <div>
                  12th Transcript, Provisional certificate, Migration
                  Certificate and Character certificate
                </div>
                <div>Valid Passport</div>
                <div>English Proficiency Test result</div>
                <div>Resume and SOP (If applicable)</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">For PG</div>
                <div>
                  SEE mark sheet, Grade sheet, and Character certificate
                </div>
                <div>11th Transcript</div>
                <div>
                  12th Transcript, Provisional certificate, Migration
                  Certificate and Character certificate
                </div>
                <div>Bachelor’s documents</div>
                <div>Backlog Certificate</div>
                <div>Work experience (If applicable)</div>
                <div>Valid Passport</div>
                <div>English Proficiency Test result</div>
                <div>Resume and SOP (If applicable)</div>
                <div>Marriage Certificate (If applicable)</div>
                <div>Spouse documents (Academics and Passport)</div>
              </div>
            </div>
            <br />

            <br />
            {/* <p>Application Stages</p> */}
            <h2 className="text-xl font-bold ">Application Stages</h2>
            <br />
            <p className="pl-10">
              <strong>• </strong> Counselling to the students{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Shortlisting Universities{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Process for the offer letter{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Offer letter received{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Preparing GTE documents{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> GTE sent to the University{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> GTE outcome from the University{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Tuition fees and OSHC payment{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> COE request{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> COE received{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Visa Lodged{" "}
            </p>
            <br />
            <p className="pl-10">
              <strong>• </strong> Visa outcome{" "}
            </p>
            <br />
          </div>
        </div>
        <StickyForm country="australia"></StickyForm>
      </div>
    </div>
  );
};

export default StudyPage;
