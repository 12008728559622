import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const Api = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const ApiWithFormData = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

const verifyToken = localStorage.getItem("token");

const config = {
  headers: { authorization: `Bearer ${verifyToken}` },
};

// ======================================> Login api <===============================
export const loginUserApi = (data) => Api.post("/api/admin/login", data);

// ======================================> BLogs api <===============================

export const createBlogApi = (data) =>
ApiWithFormData.post("/api/blog/create_blog", data, config);

export const getallBlogsApi = (data) =>
  Api.get("/api/blog/get_blogs", data, config);
  
  export const getSingleBlogApi = (id) => Api.get(`/api/blog/get_blog/${id}`);
  
  export const deleteBlogApi = (id) =>
  Api.delete(`/api/blog/delete_blogs/${id}`, config);
  
  export const updateBlogApi = (id, formData) =>
  ApiWithFormData.put(`/api/blog/update_blog/${id}`, formData, config);
  
  export const countallBlogsApi = (data) =>
    Api.get("/api/blog/count_blogs", data);
// ======================================> Event api <===============================

export const createEventAPI = (data) =>
  Api.post("/api/event/create_event", data, config);


export const createEventPhotoAPI = (data) =>
ApiWithFormData.post("/api/event/singleFile", data);
  

export const getAllEventApi = (data) =>
  Api.get("/api/event/get_events", data, config);

export const getAllUpcommingEventApi = (data) =>
  Api.get("/api/event/get_upcomming_events", data);

export const deleteEventApi = (id) =>
  Api.delete(`/api/event/delete_event/${id}`, config);

export const updateEventAPI = (id, formData) =>
ApiWithFormData.put(`/api/event/update_event/${id}`, formData, config);

export const getSingleEventApi = (id, formData) =>
  Api.get(`/api/event/events/${id}`, formData, config);

// ==========================> Api for Contact Us <============================================
export const createContactAPI = (data) =>
  Api.post("/api/contact/contact_now", data);

export const getAllContactsAPI = () => Api.get("/api/contact/all_contacts");

export const getSingleContactApi = (id) =>
  Api.get(`/api/contact/get_single_contact/${id}`);

export const deleteContactApi = (id) =>
  Api.delete(`/api/contact/contact/${id}`);

// ==========================> Api for Testimonials <============================================
export const addTestimonialsApi = (data) =>
ApiWithFormData.post("/api/test/add_test", data);

export const showAllTestimonialsApi = () => Api.get("/api/test/get_all_test");

export const getSingleTestimonialsApi = (id) =>
Api.get(`/api/test/get_single_test/${id}`);

export const deleteTestimonialApi = (id) =>
Api.delete(`/api/test/delete_test/${id}`);

export const updateTestimonialApi = (id, formData) =>
ApiWithFormData.put(`/api/test/update_test/${id}`, formData, config);

export const countAllTestimonialsApi = () => Api.get("/api/test/count_alltest");
// ======================================> University api <===============================

export const createUniversityApi = (data) =>
ApiWithFormData.post("/api/uni/add_university", data, config);

export const getallUniversityApi = (data) =>
  Api.get("/api/uni/get_university", data, config);
  
  export const deleteUniversityApi = (id) =>
  Api.delete(`/api/uni/delete_university/${id}`, config);
  
  export const updateUniversityApi = (id, formData) =>
  ApiWithFormData.put(`/api/uni/update_university/${id}`, formData, config);
  
  export const getSingleUniversityApi = (id, formData) =>
  Api.get(`/api/uni/get_university/${id}`, formData, config);
  
  export const getSingleUniversityByCountryApi = (country, formData) =>
  Api.get(`/api/uni/get_universityByCountry/${country}`, formData, config);
  
  export const countallUniversityApi = (data) =>
    Api.get("/api/uni/count_university", data);

// ======================================> AboutUs api <===============================

export const createAboutus = (data) =>
ApiWithFormData.post("/api/about/add_aboutus", data, config);

export const getallAboutusApi = (data) =>
  Api.get("/api/about/get_all_aboutus", data, config);

export const deleteAboutusApi = (id) =>
  Api.delete(`/api/about/delete_aboutus/${id}`, config);

export const updateAboutusApi = (id, formData) =>
ApiWithFormData.put(`/api/about/update_aboutus/${id}`, formData, config);

export const getSingleAboutusApi = (id, formData) =>
  Api.get(`/api/about/get_single_aboutus/${id}`, formData, config);

// ======================================> Application api <===============================

export const applicationApi = (data) =>
  Api.post("/api/application/apply_now", data, config);

export const getAll_applicationApi = (data) =>
  Api.get("/api/application/getAll_application", data, config);

  export const deleteApplicationApi = (id) =>
  Api.delete(`/api/application/delete/${id}`, config);
  
  export const countAll_applicationApi = (data) =>
    Api.get("/api/application/count_application", data);

// ======================================> Enquery api <===============================

export const enqueryCreateApi = (data) =>
  Api.post("/api/enquery/apply_now", data, config);

export const getAll_enqueriesApi = (data) =>
  Api.get("/api/enquery/getAll_enquery", data, config);
export const deleteEnqueryApi = (id) =>
  Api.delete(`/api/enquery/delete/${id}`, config);
  
  // ======================================> // Gallery api<===============================
  
  export const singleFileUploadApi = async (data, options) => {
    try {
      await axios.post(baseURL + "/api/gallery/singleFile", data, options);
    } catch (error) {
      throw error;
    }
  };
  export const getSingleFilesApi = async () => {
    try {
      const { data } = await axios.get(baseURL + "/api/gallery/getSingleFiles");
      return data;
    } catch (error) {
      throw error;
    }
  };
  
  export const multipleFilesUploadApi = async (data, options) => {
    try {
      await axios.post(baseURL + "/api/gallery/multipleFiles", data, options);
    } catch (error) {
      throw error;
    }
  };
  export const getMultipleFilesApi = async () => {
    try {
      const { data } = await axios.get(baseURL + "/api/gallery/getMultipleFiles");
      return data;
    } catch (error) {
      throw error;
    }
  };
  
  export const deleteImageApi = async (id) => {
    try {
      await axios.delete(baseURL + `/api/gallery/delete_gallery/${id}`);
    } catch (error) {
      throw error;
    }
  };

  export const update_galleryApi = async (id,data) => {
    try {
      await axios.put(baseURL + `/api/gallery/updateTitle/${id}`,data);
    } catch (error) {
      throw error;
    }
  };
  

    // Api.delete(`/api/gallery/delete_gallery`, id);

// =================attendee===================


export const attendeeCreateApi = (data) =>
  Api.post("/api/attendee/add_attendee", data);

  
export const getAll_AttendeesApi = (data) =>
Api.get("/api/attendee/all_attendees", data);
  
export const countAll_AttendeesApi = (data) =>
Api.get("/api/attendee/count_attendees", data);



// =============================== sessions wise ====================

export const countryEnqueryCreateApi = (data) =>
  Api.post("/api/countryEnquery/create_now", data);
export const getCountryEnqueryApi = () =>
  Api.get("/api/countryEnquery/getAll_enquery");

  export const deleteCountryEnqueryApi = (id) =>
  Api.delete(`/api/countryEnquery/delete/${id}`);
  

// export const getAll_AttendeesApi = (id,data) =>
// Api.get(`/api/attendee/all_attendees/${id}`, data);

// // image upload
// export const singleEventUploadApi = async (data, options) => {
//   try {
//     await axios.post(baseURL + "/api/event/create_event", data, options);
//   } catch (error) {
//     throw error;
//   }
// };