import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {  getAllEventApi, getAll_AttendeesApi } from "../../apis/api";
import AdminSidebar from "./AdminSidebar";

const AdminAttendee = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isdeleteModalOpen, setdeleteIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [allAttendees, setAllAttendees] = useState([]);
  const [filteredAttendees, setFilteredAttendees] = useState([]);
  const opendeleteModal = () => setdeleteIsModalOpen(true);
  const closedeleteModal = () => setdeleteIsModalOpen(false);

  useEffect(() => {
    getAllEvents();
    getAllAttendees();
  }, []);

  const getAllEvents = () => {
    getAllEventApi()
      .then((res) => {
        setAllEvents(res.data.events);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  };

  const getAllAttendees = () => {
    getAll_AttendeesApi()
      .then((res) => {
        setAllAttendees(res.data.attendees);
      })
      .catch((error) => {
        console.error("Error fetching attendees:", error);
      });
  };

  useEffect(() => {
    if (selectedEvent === "") {
      setFilteredAttendees(allAttendees);
    } else {
      const attendeesFilteredByEvent = allAttendees.filter(
        (attendee) => attendee.eventId.eventTitle === selectedEvent
      );
      setFilteredAttendees(attendeesFilteredByEvent);
    }
  }, [selectedEvent, allAttendees]);



  const exportCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        "Full Name,Email,Phone Number,Event,Preferred Country",
        ...filteredAttendees.map(
          (attendee) =>
            `${attendee.fullName},${attendee.email},${attendee.phoneNumber},${attendee.eventId.eventTitle},${attendee.preferredCountry}`
        ),
      ].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "attendees.csv");
    document.body.appendChild(link);
    link.click();
  };



  const deleteContactUs = (id) => {
    // deleteContactApi(id).then((res) => {
    //   if (res.data.success === true) {
    //     toast.success(res.data.message);
    //     window.location.reload();
    //   } else {
    //     toast.error(res.data.message);
    //   }
    // });
  };

  return (
    <AdminSidebar>
      <>
        <div className="w-full sm:px-6 h-[100vh] overflow-scroll">
          <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
            <div className="sm:flex items-center justify-between">
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                Attendee
              </p>
              
              <div className="relative flex flex-wrap">
              <div>
                <button
                onClick={exportCSV}
                  className="inline-flex sm:ml-3 mr-4 sm:mt-0 items-start justify-start px-6 py-3 bg-yellow-400 hover:bg-yellow-300 focus:outline-none rounded  text-black font-semibold "
                >
                  Export csv
                </button>
              </div>
                <select
                  id="events"
                  onChange={(e) => setSelectedEvent(e.target.value)}
                  value={selectedEvent}
                  className="px-12 py-3 border border-solid border-gray-700 rounded-lg ml-auto"
                >
                  <option value="">All Events</option>
                  {allEvents.map((event) => (
                    <option key={event?._id} value={event?.eventTitle}>
                      {event?.eventTitle}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
            <table className="w-full whitespace-nowrap">
              <thead>
                <tr className="h-16 w-full text-sm leading-none text-gray-800">
                  <th className="font-medium text-left pl-4">Full Name</th>
                  <th className="font-medium text-left pl-12">Email</th>
                  <th className="font-medium text-left pl-12">Phone Number</th>
                  <th className="font-medium text-left pl-12">Event</th>
                  {/* <th className="font-medium text-left pl-12">Expectation</th> */}
                  <th className="font-medium text-left pl-12">PreferredCountry</th>
                  {/* <th className="font-medium text-center pl-12">Action</th> */}
                </tr>
              </thead>
              <tbody className="w-full">
              {filteredAttendees.map((eachData) => (

                    <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100">
                      <td className="pl-12">
                        <p className="font-normal capitalize">
                          {eachData?.fullName}
                        </p>
                      </td>
                      <td className="pl-12">
                        <p className="font-normal">{eachData?.email}</p>
                      </td>
                      <td className="pl-20">
                        <p className="font-normal capitalize">
                          {eachData?.phoneNumber}
                        </p>
                      </td>
                      <td className="pl-20">
                        <p className="font-normal capitalize">
                          {eachData.eventId?.eventTitle}
                        </p>
                      </td>
                      {/* <td className="pl-20">
                        <p className="font-normal capitalize">
                          {eachData?.expectation}
                        </p>
                      </td> */}
                      <td className="pl-20">
                        <p className="font-normal capitalize">
                          {eachData?.preferredCountry}
                        </p>
                      </td>

                      {/* <td className="px-7 2xl:px-0">
                        {isdeleteModalOpen && (
                          <div
                            className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
                            id="my-modal"
                          >
                            <div className="relative mx-auto p-5 border w-1/4 shadow-lg rounded-md bg-white space-y-8 justify-center items-center flex flex-col">
                              <i className="fa-solid fa-paper-plane text-green-500 fa-5x"></i>
                              <h1 className="font-medium w-3/4 mx-auto text-center">
                                Are you sure you want to verify?
                              </h1>

                              <div className="flex flex-wrap items-center justify-between mx-auto w-full">
                                <button
                                  onClick={() => deleteContactUs(eachData._id)}
                                  type="submit"
                                  className="w-1/3 text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center py-2.5"
                                >
                                  Verify
                                </button>
                                <button
                                  type="submit"
                                  className="w-1/3 text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-2.5"
                                  onClick={closedeleteModal}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        <button
                          onClick={opendeleteModal}
                          className="bg-green-500 py-2 px-3 text-white rounded m-1 text-sm"
                        >
                          <i
                            class="fa-regular fa-paper-plane text-center"
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </AdminSidebar>
  );
};

export default AdminAttendee;
