import React from "react";
import { Link } from "react-router-dom";
import "../errorpage.css";

const user = JSON.parse(localStorage.getItem("admin"));

const ErrorPage = () => {
  return (
    <div id="ernotfound">
      <div>
        <img
          src="../assets/icon/err.jpg"
          alt="image"
          style={{ height: "400px", width: "400px" }}
        />
      </div>
      <div className="ernotfound">
        <h1>OOPS!</h1>
        <h2>Looks Like You lost your way</h2>
        {user ? (
          <Link className="erbtn" to={"/admin-dashboard"}>
            GoBack
          </Link>
        ) : (
          <Link className="btn" to={"/"}>
            Go back
          </Link>
        )}
      </div>
      {!user && (
        <section className="erteam">
          <h2 className="ersection-heading">Contact Us </h2>
          <div className="ercontainer">
            <a href="https://www.facebook.com/HubInternationalEducation">
              <div className="erprofile">
                <img src="assets/icon/fb.png" alt="" />
                <span className="ername">Facebook</span>
              </div>
            </a>
            <a href="https://www.instagram.com/hubintl/">
              <div className="erprofile">
                <img src="assets/icon/insta.png" alt="" />
                <span className="ername">Instagram</span>
              </div>
            </a>
            <a href="">
              <div className="erprofile">
                <img src="assets/icon/ln.png" alt="" />
                <span className="ername">LinkedIn</span>
              </div>
            </a>
            <a href="https://www.youtube.com/@hubinternationaleducationp2576">
              <div className="erprofile">
                <img src="assets/icon/yt.png" alt="" />
                <span className="ername">Youtube</span>
              </div>
            </a>
            <a>
              <div className="erprofile">
                <img src="assets/icon/x.png" alt="" />
                <span className="ername">X</span>
              </div>
            </a>
          </div>
        </section>
      )}
    </div>
  );
};

export default ErrorPage;
