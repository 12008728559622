import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";

import {
  Bar,
  BarChart,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  countAllTestimonialsApi,
  countAll_AttendeesApi,
  countAll_applicationApi,
  countallBlogsApi,
  countallUniversityApi,
  getAllContactsAPI,
  getAllEventApi,
  getAll_applicationApi,
  getAll_enqueriesApi,
} from "../../apis/api";

import { faClipboardList } from "@fortawesome/free-solid-svg-icons"; // Import FontAwesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminDashboard = () => {
  // for fetching all the application and showing in table
  // test preparations
  const [application, setApplication] = useState([]);
  useEffect(() => {
    getAll_applicationApi().then((res) => {
      setApplication(res.data.application);
    });
  }, []);

  const applicationCount = application.length;
  // console.log("application", applicationCount);

  // blogs
  const [countBlogs, setCountBlogs] = useState("");
  useEffect(() => {
    countallBlogsApi().then((res) => {
      setCountBlogs(res.data.totalBlogs);
    });
  }, []);

  // university
  const [countuniversity, setCountUniversity] = useState("");
  useEffect(() => {
    countallUniversityApi().then((res) => {
      setCountUniversity(res.data.totalUniversity);
    });
  }, []);

  // event attendee
  const [countAttendee, setCountAttendee] = useState("");
  useEffect(() => {
    countAll_AttendeesApi().then((res) => {
      setCountAttendee(res.data.totalAttendee);
    });
  }, []);
  // applications
  const [countApplication, setCountApplication] = useState("");
  useEffect(() => {
    countAll_applicationApi().then((res) => {
      setCountApplication(res.data.totalApplication);
    });
  }, []);

  // testimonials
  const [countTestimonials, setCountTestimonials] = useState("");
  useEffect(() => {
    countAllTestimonialsApi().then((res) => {
      setCountTestimonials(res.data.totalTestimmonials);
    });
  }, []);

  // testimonials
  const [enqueries, setEnqueries] = useState([]);
  useEffect(() => {
    getAll_enqueriesApi().then((res) => {
      setEnqueries(res?.data?.enqueries);
    });
  }, []);

  const [allmessage, setAllMessage] = useState([]);
  useEffect(() => {
    getAllContactsAPI().then((res) => {
      // console.log("contacts",res.data);
      setAllMessage(res?.data?.contacts);
    });
  }, []);
  const messageCount = allmessage.length;

  const [event, setEvent] = useState([]);
  useEffect(() => {
    getAllEventApi().then((res) => {
      setEvent(res?.data?.events);
    });
  }, []);

  const EventCount = event.length;

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#AF19FF",
    "#FF1919",
    "#33FF19",
    "#19FFDD",
  ];

  const currentYear = new Date().getFullYear();
  const applicantsByMonth = application.reduce((acc, app) => {
    const appDate = new Date(app.date);
    if (appDate.getFullYear() === currentYear) {
      const month = appDate.getMonth();
      acc[month] = (acc[month] || 0) + 1;
    }
    return acc;
  }, {});

  const totalApplicantsbyMonth = [
    { name: "Jan", Applicants: applicantsByMonth[0] || 0 },
    { name: "Feb", Applicants: applicantsByMonth[1] || 0 },
    { name: "Mar", Applicants: applicantsByMonth[2] || 0 },
    { name: "Apr", Applicants: applicantsByMonth[3] || 0 },
    { name: "May", Applicants: applicantsByMonth[4] || 0 },
    { name: "Jun", Applicants: applicantsByMonth[5] || 0 },
    { name: "July", Applicants: applicantsByMonth[6] || 0 },
    { name: "Aug", Applicants: applicantsByMonth[7] || 0 },
    { name: "Sep", Applicants: applicantsByMonth[8] || 0 },
    { name: "Oct", Applicants: applicantsByMonth[9] || 0 },
    { name: "Nov", Applicants: applicantsByMonth[10] || 0 },
    { name: "Dec", Applicants: applicantsByMonth[11] || 0 },
  ];

  const [usCount, setUsCount] = useState(0);
  const [ukCount, setUkCount] = useState(0);
  const [indiaCount, setIndiaCount] = useState(0);
  const [ausCount, setAusCount] = useState(0);
  const [ireCount, setIreCount] = useState(0);
  const [newZeaCount, setnewZeaCount] = useState(0);
  const [canadaCount, setCanadaCount] = useState(0);

  useEffect(() => {
    if (enqueries) {
      const usEnqueries = enqueries.filter((acc) => acc.destination === "US");
      setUsCount(usEnqueries.length);
      const ukEnqueries = enqueries.filter((acc) => acc.destination === "UK");
      setUkCount(ukEnqueries.length);
      const ausEnqueries = enqueries.filter((acc) => acc.destination === "Australia");
      setAusCount(ausEnqueries.length);
      const ireEnqueries = enqueries.filter((acc) => acc.destination === "Ireland");
      setIreCount(ireEnqueries.length);
      const newZeaEnqueries = enqueries.filter((acc) => acc.destination === "New Zealand");
      setnewZeaCount(newZeaEnqueries.length);
      const canEnqueries = enqueries.filter((acc) => acc.destination === "Canada");
      setCanadaCount(canEnqueries.length);
      const indiaEnqueries = enqueries.filter(
        (acc) => acc.destination === "India"
      );
      setIndiaCount(indiaEnqueries.length);
    }
  }, [enqueries]);

  const eventsData = [
    { name: "US", Country: usCount },
    { name: "U.K", Country: ukCount },
    { name: "Ireland", Country: ireCount },
    { name: "Australia", Country: ausCount },
    { name: "India", Country: indiaCount },
    { name: "Canada", Country: setCanadaCount },
    { name: "New Zealand", Country: newZeaCount },
  ];


  return (
    <AdminSidebar>
      <div className="container p-8  h-[100vh] overflow-scroll ">
        <div className="flex flex-wrap">
          <div className="chart mt-10 border border-yellow-300 p-4 rounded-md shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4 m-5">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-4xl text-blue-400 mr-2"
              />
              <div>
                <h3 className="text-lg font-semibold">Total Testemonials</h3>
                <p className="text-2xl font-bold text-red-500">
                  {countTestimonials}
                </p>
              </div>
            </div>
          </div>
          <div className="chart mt-10 border border-yellow-300 p-4 rounded-md shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4 m-5">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-4xl text-blue-400 mr-2"
              />
              <div>
                <h3 className="text-lg font-semibold">Total Enqueries</h3>
                <p className="text-2xl font-bold text-red-500">
                  {countApplication}
                </p>
              </div>
            </div>
          </div>
          <div className="chart mt-10 border border-yellow-300 p-4 rounded-md shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4 m-5">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-4xl text-blue-400 mr-2"
              />
              <div>
                <h3 className="text-lg font-semibold">Total Preparations</h3>
                <p className="text-2xl font-bold text-red-500">
                  {applicationCount}
                </p>
              </div>
            </div>
          </div>
          <div className="chart mt-10 border border-yellow-300 p-4 rounded-md shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4 m-5">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-4xl text-blue-400 mr-2"
              />
              <div>
                <h3 className="text-lg font-semibold">Total Universities</h3>
                <p className="text-2xl font-bold text-red-500">
                  {countuniversity}
                </p>
              </div>
            </div>
          </div>

          <div className="chart mt-10 border border-yellow-300 p-4 rounded-md shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4 m-5">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-4xl text-blue-400 mr-2"
              />
              <div>
                <h3 className="text-lg font-semibold">Total Events</h3>
                <p className="text-2xl font-bold text-red-500">{EventCount}</p>
              </div>
            </div>
          </div>

          <div className="chart mt-10 border border-yellow-300 p-4 rounded-md shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4 m-5">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-4xl text-blue-400 mr-2"
              />
              <div>
                <h3 className="text-lg font-semibold">Total Attendees</h3>
                <p className="text-2xl font-bold text-red-500">
                  {countAttendee}
                </p>
              </div>
            </div>
          </div>
          <div className="chart mt-10 border border-yellow-300 p-4 rounded-md shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4 m-5">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-4xl text-blue-400 mr-2"
              />
              <div>
                <h3 className="text-lg font-semibold">Total Messages</h3>
                <p className="text-2xl font-bold text-red-500">
                  {messageCount}
                </p>
              </div>
            </div>
          </div>

          <div className="chart mt-10 border border-yellow-300 p-4 rounded-md shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4 m-5">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-4xl text-blue-400 mr-2"
              />
              <div>
                <h3 className="text-lg font-semibold">Total Blogs</h3>
                <p className="text-2xl font-bold text-red-500">{countBlogs}</p>
              </div>
            </div>
          </div>
        </div>

        {/* <h1 class="mb-4 text-xl text-center font-extrabold text-gray-900 dark:text-white md:text-xl lg:text-xl">
          <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            Admin DashBoard
          </span>
        </h1> */}
        <div className="charts flex flex-row flex-wrap justify-around">
          {/* Hospital Chart */}
          <div className="chart mt-10 border border-sky-500 p-8">
            <h3 className="text-center font-bold mb-5">
              Applicants By Month {`(${currentYear})`}
            </h3>
            <LineChart width={500} height={300} data={totalApplicantsbyMonth}>
              <XAxis dataKey="name" style={{ fontSize: 10 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotoneX" dataKey="Applicants" stroke="#8884d8" />
            </LineChart>
          </div>
          {/* Blood Request Chart */}
          <div className="chart mt-10 border border-sky-500 p-8">
            <h3 className="text-center font-bold mb-5">
              Enqueries per Destiantion
            </h3>
            {/* 'line' | 'plainline' | 'square' | 'rect'| 'circle' | 'cross' | 'diamond' | 'square' | 'star' | 'triangle' | 'wye' | 'none' */}
            <BarChart width={500} height={300} data={eventsData}>
              <XAxis dataKey="name" style={{ fontSize: 10 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                barSize={40}
                fill="#413ea0"
                legendType="plainline"
                dataKey="Country"
              />
            </BarChart>
          </div>
          {/* <div className="chart mt-10 border border-sky-500 p-8">
            <h3 className="text-center">Blood Bank Data</h3>
            <PieChart width={500} height={300}>
              <Pie
                data={bloodBankData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={150}
                fill="#8884d8"
              >
                {bloodBankData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
          <div className="chart mt-10 border border-sky-500 p-8">
            <h3 className="text-center">Donor Data</h3>
            <RadarChart
              outerRadius={90}
              width={500}
              height={300}
              data={donorData}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis />
              <Radar
                name="Donors"
                dataKey="value"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
              <Tooltip />
              <Legend />
            </RadarChart>
          </div> */}
        </div>
      </div>
    </AdminSidebar>
  );
};

export default AdminDashboard;
