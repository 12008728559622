import React, { useState } from "react";
import { multipleFilesUploadApi, singleFileUploadApi } from "../apis/api";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";


const FileUploadScreen = (props) => {
  const [singleFile, setSingleFile] = useState(null);
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [loading, setLoading] = useState(false); // State for loader
  const [progress, setProgress] = useState(false); // State for progress


  const SingleFileChange = (e) => {
    setSingleFile(e.target.files[0]);
  };

  // const MultipleFileChange = (e) => {
  //   const filesArray = Array.from(e.target.files);
  //   setMultipleFiles(filesArray);
  // };
  const MultipleFileChange = (e) => {
    const filesArray = Array.from(e.target.files);
    setMultipleFiles(filesArray);
    setProgress(true);
  
    // Check if all files are loaded
    let loadedCount = 0;
    filesArray.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        loadedCount++;
        if (loadedCount === filesArray.length) {
          setProgress(false);
        }
      };
      reader.readAsDataURL(file);
    });
  };


  const removeImage = (index) => {
    const updatedFiles = [...multipleFiles];
    updatedFiles.splice(index, 1);
    setMultipleFiles(updatedFiles);
  };

  const validateTitle = () => {
    if (title.trim() === "") {
      setTitleError("Title is required");
      return false;
    }
    setTitleError("");
    return true;
  };

  const uploadSingleFile = async () => {
    const formData = new FormData();
    formData.append("file", singleFile);
    
    try {
      await singleFileUploadApi(formData);
      toast.success("Image uploaded successfully");
      props.getsingle();
      setSingleFile(null);
      window.location.reload(); 
    } catch (error) {
      toast.error("Select image First", error);
    }
  }

  const uploadMultipleFiles = async () => {
    if (validateTitle()) {
      try {
        setLoading(true)
        const formData = new FormData();
        formData.append("title", title);
        multipleFiles.forEach((file) => {
          formData.append("files", file);
        });
        await multipleFilesUploadApi(formData);
        toast.success("Images uploaded successfully");
        props.getMultiple();
        setMultipleFiles([]);
        setLoading(false)
        window.location.reload();
      } catch (error) {
        toast.error("Select image First", error);

      }
    }
  };
  

  return (
    <>
      {/* <div className="col-span-12 ml-12">
        <h1 className="text-xl font-bold mb-4">Single File Upload</h1>
        <div className="mb-4">
          <label className="block">Select Single File</label>
          <input
            type="file"
            onChange={(e) => SingleFileChange(e)}
            className="border border-gray-300 rounded p-2 w-full"
          />
          {singleFile && (
            <div className="mt-2">
              <img
                src={URL.createObjectURL(singleFile)}
                alt="Preview"
                className="w-20 h-20 mr-2 object-cover rounded"
              />
              <button
                className="text-red-500"
                onClick={() => setSingleFile(null)}
              >
                &#10005;
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-start">
          <button
            type="button"
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={() => uploadSingleFile()}
          >
            Upload
          </button>
        </div>
      </div> */}

      <div className="col-span-12 ml-12 mt-8">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <label className="block">Title</label>
            <input
              required
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter title for your gallery"
              className={`border border-gray-300 rounded p-2 w-full ${
                titleError ? "border-red-500" : ""
              }`}
            />
            {titleError && (
              <div className="text-red-500 mt-1">{titleError}</div>
            )}
          </div>
          <div className="col-span-1">
            <div className="mb-4">
              <label className="block">Select Multiple Files</label>
              <input
                type="file"
                onChange={(e) => MultipleFileChange(e)}
                className="border border-gray-300 rounded p-2 w-full"
                multiple
              />
                {progress && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <Circles color="#FDE047" height={80} width={80} />
        </div>
      )}
              {multipleFiles.length > 0 && (
                <div className="mt-2 flex flex-wrap">
                  {multipleFiles.map((file, index) => (
                    <div key={index} className="flex items-center mr-2 mb-2">
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Preview"
                        className="w-20 h-20 mr-2 object-cover rounded"
                      />
                      <button
                        className="text-red-500"
                        onClick={() => removeImage(index)}
                      >
                        {/* &#10005; */}
                         <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-start">
          <button
            type="button"
            onClick={() => uploadMultipleFiles()}
            className="bg-yellow-400 text-black px-4 py-2 rounded"
          >
            Upload
          </button>
        </div>
      </div>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <Circles color="#FDE047" height={80} width={80} />
        </div>
      )}
    </>
  );
};

export default FileUploadScreen;