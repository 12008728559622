import React from 'react';
import { Link } from 'react-router-dom';



const Footer = () => {
  return (
    <>

      <footer className="bg-yellow-400 w-full py-10">
        <div className="text-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-9 w-5/6 mx-auto justify-center space-x-4">
          {/* Section 1 */}

          {/* Section 1 */}
          <div className="flex flex-col items-center  md:space-y-4">
            <img className="w-40 h-40 object-cover" src="/../assets/images/logo.png" target="_blank" rel="noopener noreferrer" alt="" />
            <div className="flex space-x-4 mt-1 md:mt-0">
              <a href="https://www.facebook.com/HubInternationalEducation" target="_blank" rel="noopener noreferrer" className="text-black text-lg hover:text-white">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/hubintl/" target="_blank" rel="noopener noreferrer" className="text-black text-lg hover:text-white">
                <i className="fab fa-instagram"></i>
              </a>
              {/* <a href="#" className="text-black text-lg hover:text-white">
                <i className="fab fa-pinterest"></i>
              </a> */}
              <a href="https://www.youtube.com/@hubinternationaleducationp2576" target="_blank" rel="noopener noreferrer" className="text-black text-lg hover:text-white">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>



          {/* Section 2 */}
          <div className="flex flex-col text-black items-center md:items-start md:pl-16">
            <span className="text-2xl font-medium">About Us</span>
            <div className="flex flex-col w-full md:items-start items-center">
              <Link to="/" className="mt-2 font-medium cursor-pointer hover:text-white">Home</Link>
              <Link to="/galleries" className="mt-2 font-medium cursor-pointer hover:text-white">Gallery</Link>
              <Link to="/test-preparation" className="mt-2 font-medium cursor-pointer hover:text-white">Test</Link>
            </div>
          </div>

          {/* Section 3 */}
          <div className="flex flex-col text-black md:pl-16 md:items-start items-center">
            <span className="text-2xl font-medium">Quick Links</span>
            <div className="flex flex-col w-full md:items-start items-center ">
              <Link to="/study-in-australia" className="mt-2 font-medium cursor-pointer hover:text-white">Australia</Link>
              <Link to="/study-in-canada" className="mt-2 font-medium cursor-pointer hover:text-white">Canada</Link>
              <Link to="/study-in-india" className="mt-2 font-medium cursor-pointer hover:text-white">India</Link>
            </div>
          </div>

          {/* Section 4 */}
          <div className="flex flex-col text-black md:pl-16 md:items-start items-center">
            {/* <span className="text-xl font-medium"></span> */}
            <div className="flex flex-col w-full md:items-start items-center">
              <Link to="/study-in-ireland" className="mt-2 font-medium cursor-pointer hover:text-white">Ireland</Link>
              <Link to="/study-in-newzeland" className="mt-2 font-medium cursor-pointer hover:text-white">NewZealand</Link>
              <Link to="/study-in-uk" className="mt-2 font-medium cursor-pointer hover:text-white">UK</Link>
              <Link to="/study-in-usa" className="mt-2 font-medium cursor-pointer hover:text-white">USA</Link>
              {/* <Link to="/property" className="mt-2 font-medium cursor-pointer hover:text-white">Health & Allied Health</Link>
              <Link to="/gallery" className="mt-2 font-medium cursor-pointer hover:text-white">Food, Hospitality & Personal Services</Link>
              <Link to="/contact" className="mt-2 font-medium cursor-pointer hover:text-white">Creative Arts , Design & Communication</Link>
              <Link to="/contact" className="mt-2 font-medium cursor-pointer hover:text-white">Business, Commerce & Management</Link> */}
            </div>
          </div>
        </div>

        <div className="w-5/6 mx-auto border-2 border-solid border-black mt-8"></div>

        <div className=" flex flex-col md:flex-row justify-center mt-4 w-5/6 mx-auto">
          <span className='mb-4 md:mb-0 md:mr-4 max-w-[400px]'>© Copyright 2024, Property All Rights Reserved</span>
        </div>

      </footer>

    </>
  )
}

export default Footer
