import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSingleTestimonialsApi, updateTestimonialApi } from "../../apis/api";
import { toast } from "react-toastify";

const AdminEditTestiMonials = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getSingleTestimonialsApi(id).then((res) => {
      setFullName(res.data.testimonials.fullName);
      setPost(res.data.testimonials.post);
      setMessage(res.data.testimonials.message);
      setOldImagePreview(res.data.testimonials.imageUrl);
    });
  }, [id]);

  const [fullName, setFullName] = useState("");
  const [post, setPost] = useState("");
  const [message, setMessage] = useState("");
  const [ImageUrl, setImageUrl] = useState(null); // New state for the image file
  const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview URL
  const [oldimagePreview, setOldImagePreview] = useState(null); // State to hold the image preview URL

  const updateTestimonial = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("post", post);
    formData.append("message", message);
    formData.append("imageUrl", ImageUrl);

    updateTestimonialApi(id, formData)
      .then((res) => {
        console.log(res.data);

        if (res.data.success == false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          navigate("/admin-testimonial");
        }
      })
      .catch((e) => {
        toast.error(e.message);
        console.log(e);
      });
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImageUrl(selectedImage);
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto  w-full h-[100vh] overflow-scroll"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
        <div className="absolute top-0 right-0 pt-4 pr-4">
          <Link
            to={"/admin-testimonial"}
            className="text-black bg-gray-200 hover:bg-gray-300 rounded-lg text-sm p-1.5"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Link>
        </div>
        <form className="space-y-6">
          <h3 className=" leading-6 text-gray-900 text-center font-semibold text-2xl">
            Edit Testimonial
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="Testimonial"
                className="block text-sm font-medium text-gray-900"
              >
                Full Name
              </label>
              <input
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Testimonial"
                className="block text-sm font-medium text-gray-900"
              >
                Post
              </label>
              <input
                value={post}
                onChange={(e) => setPost(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="EventImage"
              className="block text-sm font-medium text-gray-900"
            >
                        Testimonial Image
            </label>
            <input
              type="file"
              onChange={handleImageChange}
              accept="image/*"
              className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
              required
            />
          </div>
          <div className="flex">
            {oldimagePreview && (
              <img
                src={oldimagePreview}
                alt="Event Preview"
                className="mt-2 w-32 h-32 p-4 object-cover"
              />
            )}
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Event Preview"
                className="mt-2 w-32 h-32 p-4 object-cover"
              />
            )}
          </div>
          <div>
            <label
              htmlFor="Testimonial"
              className="block text-sm font-medium text-gray-900"
            >
              Message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              type="text"
              rows={7}
              className="mt-1 block peer  min-h-[100px]  resize-none w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
              required
            />
          </div>
          <button
            onClick={updateTestimonial}
            className="w-full bg-yellow-400 hover:bg-yellow-300 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 text-center text-black font-semibold"
          >
            Update Testimonial
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminEditTestiMonials;
