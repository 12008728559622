import React, { useEffect, useState } from "react";
import { getSingleUniversityByCountryApi } from "../../apis/api";
import StickyForm from "../../components/StickyForm";

const USA = () => {
  const images = [
    "/../assets/images/country/england.jpg",
    "/../assets/images/country/india.jpg",
    "/../assets/images/country/canada.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const [university, setUniversity] = useState([]);
  useEffect(() => {
    getSingleUniversityByCountryApi("USA").then((res) => {
      console.log(res.data.universities);
      setUniversity(res.data.universities);
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">
      <div className="w-full flex flex-cols">
        <div className="w-full">
          <img
            src="/../assets/images/studyabroad.jpg"
            alt="Your alt text"
            className="w-full h-auto max-h-98 object-cover"
          />
          <div className="md:absolute flex flex-col top-[190px] md:w-[900px] w-full md:pl-36 pl-10 space-y-2">
            <h1 className="md:text-dark text-2xl md:text-4xl font-medium">
              Your dream of studying in
              <br /> USA is closer than you think!
            </h1>
            <p className="md:text-dark w-[90%]">
              "At Hub International Education, we are dedicated to transforming
              educational aspirations into realities by providing personalized
              guidance and expert support throughout the academic journey."
            </p>
            <button className="bg-yellow-400 w-[210px] text-dark px-5  py-2 mt-2 rounded-md hover:bg-white hover:text-dark transition duration-300">
              Get Started For Free
            </button>
          </div>
        </div>
      </div>

      {/* <div className="w-full md:relative inherit top-0">


            <div className="w-full object-cover">
                <img src="/../assets/images/purple.png" alt="Your alt text" className="w-full h-auto max-h-98 object-cover" />
            </div>

            <div className="absolute flex flex-col items-start md:inset-0 justify-center w-full md:w-1/2 mx-auto">


                <h1 className="text-3xl md:text-4xl font-medium font-semibold ">
                    Your dream of studying in<br /> USA is closer than you think!
                </h1>
                <p className="w-full text-2xl">Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro voluptatum ullam blanditiis voluptas id. Ipsam minus ipsum hic! Natus dolor fuga quae. Porro facere, dicta dignissimos ipsam quos suscipit at.</p>
                <button className="bg-yellow-400 text-black mt-8 px-5 font-semibold py-4 mt-2 rounded-full hover:bg-white hover:text-dark transition duration-300">
                    Get Started For Free
                </button>
            </div>
        </div> */}
      <div className="flex flex-col md:flex-row">
        <div className="container mx-auto p-8 md:mt-8 mt-32 text-justify md:w-[75%] w-[100%]">
          <div className="flex flex-col md:flex-row gap-10 ">
            {/* first division */}
            <div className="flex-1 max-w-4xl">
              <h1 className="text-3xl font-bold mt-4 mb-2">
                Study in USA from Nepal
              </h1>
              <p className="text-gray-700 mb-4">
                The USA, or United States of America, is a large country in
                North America known for its diverse culture, strong economy, and
                beautiful landscapes. It's made up of 50 states, each with its
                own attractions. From famous cities like New York and Los
                Angeles to natural wonders like the Grand Canyon and Yellowstone
                National Park, there's plenty to see and do. The USA is a
                melting pot of cultures, offering a wide variety of foods,
                traditions, and experiences. Whether you're exploring cities,
                relaxing on beaches, or hiking in national parks, the USA has
                something for everyone to enjoy.
              </p>
            </div>
          </div>

          {/* Key Facts Section */}
          <div className="max-w-4xl">
            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Study Levels and Academic Requirements:
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-4">
                <div className="font-semibold">Degree</div>
                <div>Bachelor (4 years)</div>
                <div>Bachelor (4 years)</div>
                {/* <div>Post Graduate Certificate</div>
              <div>Masters</div>
              <div>PHD</div> */}
              </div>
              <div className="p-4">
                <div className="font-semibold items-center">
                  Academic Requirement
                </div>
                <div>GPA- 2.50</div>
                {/* div      <div>GPA- 2.8 and above</div>
                            <div>50% and above</div> */}
                <div>GPA- 2.50</div>
              </div>
            </div>
          </div>
          <br />
          <div className="max-w-4xl text-left">
            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Language and Funds Requirements{" "}
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-6 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Levels</div>
                <div>Bachelor Degree</div>
                <div>Master’s Degree</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Min IELTS</div>
                <div>6 not less than 5.5</div>
                <div>6.5 not less than 6</div>
                {/* <div>5.5 not less than 5.5</div>
            <div>6 not less than 5.5</div> */}
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">PTE</div>
                <div>Overall 53</div>
                <div>Overall 53</div>
                {/* <div>43 not less than 43</div>
            <div>50 not less than 43</div> */}
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Duolingo</div>
                <div>110</div>
                <div>110</div>
                {/* <div>43 not less than 43</div>
            <div>50 not less than 43</div> */}
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Scholarship Test</div>
                <div>SAT (1100)</div>
                <div>GRE (300) GMAT (700)</div>
                {/* <div>3-9 Months</div>
            <div>1 years</div> */}
              </div>

              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">TOEFL IBT</div>
                <div>80</div>
                <div>80</div>
                {/* <div>Any Percentage</div>
              <div>45% and above</div> */}
              </div>
            </div>
          </div>

          {/* Additional info link */}
          <div className="mt-4 max-w-4xl justify-between">
            <h2 className="text-xl font-bold">Gap Acceptance</h2>
            <p className="text-gray-700 justify-between pl-10">
              <strong>•</strong> Bachelors: up to 3 years
              <br />
            </p>
            <p className="text-gray-700 justify-between pl-10">
              <strong>•</strong> Masters: up to 5 years
              <br />
            </p>
            <p className="text-gray-700 justify-between pl-10">
              <strong>•</strong> Conditions apply: Showing relevant work
              experience is essential to fulfill the gap
              <br />
            </p>
          </div>
          <div className="mt-4 max-w-4xl justify-between">
            <h2 className="text-xl font-bold">Intakes</h2>
            <p className="text-gray-700 justify-between">
              Spring Intake: Jan/Feb
              <br />
            </p>
            <p className="text-gray-700 justify-between">
              Summer Intake: April/May <br />
            </p>
            <p className="text-gray-700 justify-between">
              Fall Intake: Aug/Sep <br />
            </p>
          </div>

          <div className="mt-4 max-w-4xl justify-between">
            <h2 className="text-2xl font-bold">Optional Practice Training</h2>
            <br />
            <h2 className="text-xl font-bold">Bachelors</h2>
            <p className="text-gray-700 justify-between">
              STEM Course: 3 years
              <br />
            </p>
            <p className="text-gray-700 justify-between">
              NON-STEM Course: 2 years <br />
            </p>
            <br />
            <h2 className="text-xl font-bold">Master</h2>
            <p className="text-gray-700 justify-between">
              2 years of OPT <br />
            </p>
            <br />

            <h2 className="text-xl font-bold">Letter of Recommendations</h2>
            <p className="text-gray-700 justify-between">
              Bachelors: One <br />
            </p>
            <p className="text-gray-700 justify-between">
              Masters: Two <br />
            </p>
          </div>

          <div className="max-w-4xl text-left">
            <div className="w-full overflow-hidden mb-12">
              <h1 className="text-red-700 md:text-4xl text-xl mt-4 md:mt-6 text-center font-semibold">
                Partner Institutions
                <div className="md:w-1/2 w-full mx-auto border-2 border-solid border-yellow-500"></div>
              </h1>

              <div className="flex flex-wrap  justify-center items-center mt-4">
                {university.map((uni, index) => (
                  <a href={uni.description} target="_blank">
                    <div
                      key={index}
                      className="flex flex-wrap items-center space-x-2 space-y-2 p-2 "
                    >
                      <img
                        src={uni.imageUrl}
                        alt={uni.universityName}
                        className="w-40 h-30 object-cover rounded "
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <div className="flex-1 max-w-4xl text-justify">
              <h1 className="text-xl font-bold mt-4 mb-2">
                Reasons to Study in USA{" "}
              </h1>
              <p className="pl-10">
                <strong>• </strong> Studying in an English-speaking country like
                the USA enhances international students' English language
                proficiency.
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> US universities offer comprehensive support
                services for international students, including academic
                advising, counseling, English language programs, and cultural
                adjustment support.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> International students in the USA have
                access to state-of-the-art research facilities and opportunities
                to collaborate with leading researchers.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> The USA provides international students with
                a safe, welcoming environment and a high quality of life,
                featuring diverse cultural and recreational offerings, vibrant
                cities, and stunning landscapes.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> The USA offers abundant internship and job
                opportunities, particularly in industries such as technology,
                finance, healthcare, and engineering.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Degrees from US universities are widely
                recognized and respected worldwide.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> The USA is home to many top-ranking
                universities renowned for their academic excellence and
                cutting-edge research across various fields.{" "}
              </p>
            </div>

            <div className="flex-1 max-w-4xl text-justify">
              <h1 className="text-xl font-bold mt-4 mb-2">Facts About USA </h1>
              <p className="pl-10">
                <strong>• </strong> The USA is the world's largest economy by
                nominal GDP and a global leader in finance, and technology.
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> The USA is a land of opportunities.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> The USA has a vast and varied landscape,
                ranging from bustling urban centers to sprawling national parks
                and scenic coastlines.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> It has a long history of scientific and
                technological advancement, including breakthroughs in space
                exploration, medicine, and information technology.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Sports play a significant role in American
                culture, with baseball, basketball, American football, and
                soccer among the most popular.{" "}
              </p>
            </div>
            <div className="mt-4 max-w-4xl justify-between">
              <h2 className="text-xl font-bold">Working Rights</h2>
              <p className="text-gray-700 justify-between">
                During the academic session, international students are
                permitted to engage in part-time work for up to 20 hours per
                week, and during scheduled breaks, they may work for up to 40
                hours per week.
                <br />
              </p>
            </div>

            <div className="flex-1 max-w-4xl text-justify pt-5 ">
              <h1 className="text-xl font-bold mt-4 mb-2">
                Application Procedure{" "}
              </h1>
              <p className="pl-10">
                <strong>Step 1: </strong>Select Course and College.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 2: </strong> Apply for an offer letter.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 3: </strong> Get ready with the Bank Balance
                Certificate{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 4: </strong> Submission of financial form along
                with sponsorship letter{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 5: </strong> Fill the I-20 request form{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 6: </strong> Receive the I-20{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 7: </strong> Fill out Ds-160 form and create CGI
                profile{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 8: </strong> Make your SEVIS and MRV fee payment
                done{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 9: </strong> Book an appointment, prepare for
                interview and appear your Visa interview session{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 10: </strong> Get your visa, make the fee payment
                and go for medical checkup.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 11: </strong> Hey! Congrats{" "}
              </p>
              <br />
              <p className="text-gray-700 justify-between">
                Here’s to all that the USA has over 55 hundred universities on
                its lap where each of them has their own way of dealing in the
                admission process. So, the admission process varies accordingly
                top the university and the file of the applicant. Personal
                Statement may or may not require. Medium of Instruction (MOI) is
                also mandatorily not required.
                <br />
              </p>
            </div>
          </div>
        </div>
        <StickyForm country="USA"></StickyForm>
      </div>
    </div>
  );
};

export default USA;
