import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSingleUniversityApi, updateUniversityApi } from "../../apis/api";

const AdminEditCountry = () => {
  // recieve id from URl
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getSingleUniversityApi(id).then((res) => {
      setUniversityName(res.data.university.universityName);
      setCountry(res.data.university.country);
      setDescription(res.data.university.description);
      setOldImagePreview(res.data.university.imageUrl);

    });
  }, [id]);

  const [universityName, setUniversityName] = useState("");
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [ImageUrl, setImageUrl] = useState(null); // New state for the image file
  const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview URL
  const [oldimagePreview, setOldImagePreview] = useState(null); // State to hold the image preview URL


  const editUniversity = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("universityName", universityName);
    formData.append("country", country);
    formData.append("description", description);
    formData.append("imageUrl", ImageUrl);


    updateUniversityApi(id, formData)
      .then((res) => {
        if (res.data.success == false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          navigate("/admin-country");
        }
      })
      .catch((e) => {
        toast.error(e.message);
        console.log(e);
      });
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImageUrl(selectedImage);
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
        <div className="absolute top-0 right-0 pt-4 pr-4">
          <Link
            to={"/admin-country"}
            className="text-black bg-gray-200 hover:bg-gray-300 rounded-lg text-sm p-1.5"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Link>
        </div>

        <form className="space-y-6">
          <h3 className=" leading-6 text-gray-900 text-center font-semibold text-2xl">
            Edit Institute
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="Country"
                className="block text-sm font-medium text-gray-900"
              >
                Institute Name
              </label>
              <input
                value={universityName}
                onChange={(e) => setUniversityName(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Country"
                className="block text-sm font-medium text-gray-900"
              >
                Country
              </label>
              <select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              >
                <option disabled>Select Country</option>
                <option value={"USA"}>USA</option>
                <option value={"Australia"}>Australia</option>
                <option value={"Ireland"}>Ireland</option>
                <option value={"India"}>India</option>
                <option value={"UK"}>UK</option>
                <option value={"Canada"}>Canada</option>
                <option></option>
              </select>
            </div>

            <div>
              <label
                htmlFor="Country"
                className="block text-sm font-medium text-gray-900"
              >
                URL of the Institute
              </label>
              <input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
            <label
              htmlFor="EventImage"
              className="block text-sm font-medium text-gray-900"
            >
                        Logo
            </label>
            <input
              type="file"
              onChange={handleImageChange}
              accept="image/*"
              className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
              required
            />
          </div>
          <div className="flex">
            {oldimagePreview && (
              <img
                src={oldimagePreview}
                alt="Event Preview"
                className="mt-2 w-32 h-32 p-4 object-cover"
              />
            )}
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Event Preview"
                className="mt-2 w-32 h-32 p-4 object-cover"
              />
            )}
          </div>
          </div>
          <button
            type="submit"
            onClick={editUniversity}
            className="w-full bg-yellow-400 hover:bg-yellow-300 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 text-center text-black font-semibold"
          >
            Edit Institute
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminEditCountry;
