import React from 'react'

const BlogContent = () => {
    return (
        <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">

        <div className="w-full flex flex-cols">

            <div className="w-full">
                <img src="/../assets/images/purple.png" alt="Your alt text" className="w-full h-auto max-h-98 object-cover" />
                <div className="md:absolute flex flex-col top-[190px] md:w-[900px] w-full md:pl-36 pl-10 space-y-2">

                    <h1 className="md:text-dark text-2xl md:text-4xl font-medium">
                        Your dream of studying in<br /> USA is closer than you think!
                    </h1>
                    <p className="md:text-dark w-[90%]">"At Hub International Education, we are dedicated to transforming educational aspirations into realities by providing personalized guidance and expert support throughout the academic journey."</p>
                   
                </div>
            </div>
        </div>


        <div className="container mx-auto p-8 md:mt-8 mt-32">
            <div className="flex flex-col md:flex-row gap-10">
                {/* Header with image and title */}
                <div className="flex-1">
                    <h1 className="text-3xl font-bold mt-4 mb-2">Study in USA from Nepal</h1>
                    <p className="text-gray-700 mb-4">
                        USA is a contemporary, democratic nation with excellent healthcare, top-notch educational
                        institutions, and a unique geography. Studying in USA is particularly a terrific option for students to
                        advance their education and professional opportunities, whether it’s because of the exciting way of life,
                        welcoming culture, or excellent educational resources.
                    </p>
                </div>


            </div>

            {/* Key Facts Section */}
            <div className="max-w-4xl">
                <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
                    <h2 className="text-xl font-bold">Key Facts to Study in USA</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
                    <div className="p-4">
                        <div className="font-semibold">Language spoken</div>
                        <div>English</div>
                    </div>
                    <div className="p-4">
                        <div className="font-semibold">Cost of study</div>
                        <div>Undergraduate- AUD 20,000 to AUD 45,000 per year,</div>
                        <div>Post-graduate- AUD 22,000 to AUD 50,000 per year.</div>
                    </div>
                    <div className="p-4">
                        <div className="font-semibold">Source of funding</div>
                        <div>Scholarships, personal savings, part-time work, educational loans, and sponsorships</div>
                    </div>
                    <div className="p-4">
                        <div className="font-semibold">Exams required</div>
                        <div>IELTS, TOEFL, or PTE Academic</div>
                    </div>
                    <div className="p-4">
                        <div className="font-semibold">Degrees</div>
                        <div>Undergraduate, post-graduate, Doctoral, vocational courses.</div>
                    </div>
                    <div className="p-4">
                        <div className="font-semibold">Intakes</div>
                        <div>February/March and July/August.</div>
                    </div>
                    <div className="p-4">
                        <div className="font-semibold">Visa</div>
                        <div className="text-blue-600 cursor-pointer">Student visa subclass 500.</div>
                    </div>
                </div>
            </div>

            {/* Additional info link */}
            <div className="mt-4">
                <a href="/link-to-more-info" className="text-blue-600 hover:underline">
                    If you are interested to know more about Studying in USA from Nepal, here is all the information
                    related to studying in USA.
                </a>
            </div>
            <div className="mt-12 max-w-4xl ">
                <h2 className="text-2xl font-bold text-center md:text-left md:text-3xl  mb-6">
                    Benefits of Studying in USA for Nepalese Students
                </h2>
                <p className="text-md md:text-lg text-gray-700 mb-4">
                    In recent years, the number of students from around the world pursuing their studies in the Land Down
                    Under has increased tremendously. This influx of foreign students into USAn higher education can be
                    attributed to a variety of factors. Here is a list of <strong>benefits of Studying in USA</strong> as a Nepali student:
                </p>
                <div className="space-y-4">
                    <h3 className="text-xl font-semibold">World-class education system:</h3>
                    <p className="text-md md:text-lg text-gray-700">
                        USAn institutions are renowned for their world-class standards of education, robust educational standards which equip students with the knowledge and skills sought by employers worldwide. Studying in this country can therefore provide both personal enrichment and the opportunity for a successful career path.
                    </p>
                    <h3 className="text-xl font-semibold">Easy to adapt culture for Nepalese students:</h3>
                    <p className="text-md md:text-lg text-gray-700">
                        For Nepali students, USA is a great option to consider when deciding on a study destination. With its safe and secure atmosphere, the country provides peace of mind for those who are interested in studying abroad. Furthermore, it has modern infrastructure and top-notch healthcare services that guarantee a high standard of living. The friendly culture in USA also means that Nepali students can easily integrate into their new environment with greater ease compared to many other countries.
                    </p>
                    <h3 className="text-xl font-semibold">Easy to adapt culture for Nepalese students:</h3>
                    <p className="text-md md:text-lg text-gray-700">
                        For Nepali students, USA is a great option to consider when deciding on a study destination. With its safe and secure atmosphere, the country provides peace of mind for those who are interested in studying abroad. Furthermore, it has modern infrastructure and top-notch healthcare services that guarantee a high standard of living. The friendly culture in USA also means that Nepali students can easily integrate into their new environment with greater ease compared to many other countries.
                    </p><h3 className="text-xl font-semibold">Easy to adapt culture for Nepalese students:</h3>
                    <p className="text-md md:text-lg text-gray-700">
                        For Nepali students, USA is a great option to consider when deciding on a study destination. With its safe and secure atmosphere, the country provides peace of mind for those who are interested in studying abroad. Furthermore, it has modern infrastructure and top-notch healthcare services that guarantee a high standard of living. The friendly culture in USA also means that Nepali students can easily integrate into their new environment with greater ease compared to many other countries.
                    </p>
                </div>
            </div>

            <div className="flex flex-col md:flex-row mb-6 justify-evenly gap-4 bg-yellow-200 max-w-4xl px-4 md:px-12 py-12 rounded-lg items-center">
                {/* Card 1 */}
                <div className="flex flex-col bg-yellow-300 rounded-lg p-4 shadow-md w-full md:w-1/3 mb-4 md:mb-0">
                    <i className="fas fa-user-graduate text-xl"></i>
                    <span className="text-3xl font-bold">50,000+</span>
                    <span className="text-gray-700">Nepalese Students</span>
                </div>
                {/* Card 2 */}
                <div className="flex flex-col items-center bg-yellow-300 rounded-lg p-4 shadow-md w-full md:w-1/3 mb-4 md:mb-0">
                    <i className="fas fa-book text-xl"></i>
                    <span className="text-3xl font-bold">22,000+</span>
                    <span className="text-gray-700">Courses</span>
                </div>
                {/* Card 3 */}
                <div className="flex flex-col items-center bg-yellow-300 rounded-lg p-4 shadow-md w-full md:w-1/3">
                    <i className="fas fa-school text-xl"></i>
                    <span className="text-3xl font-bold">1,100+</span>
                    <span className="text-gray-700">Institutions</span>
                </div>
            </div>

            <div className="max-w-4xl">
                <p className="text-md text-gray-700 mb-6">
                    Need to learn more about Applying to <a href="#study-in-USA" className="text-blue-600 hover:underline">Study in USA</a> and all the benefits that you can gain by it? Contact
                    <br /><span className='text-black font-medium pl-32 ml-32'>HUB INTERNATIONAL,<br /></span> <span className='font-semibold ml-16 pl-16'>the best education consultancy in Nepal for USA!</span>
                </p>
            </div>
        </div>
    </div>
    )
}

export default BlogContent
