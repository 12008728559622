import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSingleEventApi, updateEventAPI } from "../../apis/api";

const AdminEditEvents = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getSingleEventApi(id).then((res) => {
      setEventTitle(res.data.events.eventTitle);
      setEventType(res.data.events.eventType);
      setStartDate(res.data.events.startDate);
      setEventDescription(res.data.events.eventDescription);
      setEndDate(res.data.events.endDate);
      setOldImagePreview(res.data.events.eventImageUrl);
      setLocation(res.data.events.location)
    });
  }, [id]);

  const [eventTitle, setEventTitle] = useState("");
  const [eventType, setEventType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState("");

  const [eventImage, setEventImage] = useState(null); // New state for the image file
  const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview URL
  const [oldimagePreview, setOldImagePreview] = useState(null); // State to hold the image preview URL

  const editEvent = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("eventTitle", eventTitle);
    formData.append("eventType", eventType);
    formData.append("eventDescription", eventDescription);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("eventImageUrl", eventImage);
    formData.append("location", location);

    updateEventAPI(id, formData)
      .then((res) => {
        if (res.data.success == false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          navigate("/admin-events");
        }
      })
      .catch((e) => {
        toast.error(e.message);
        console.log(e);
      });
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setEventImage(selectedImage);
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
        <div className="absolute top-0 right-0 pt-4 pr-4">
          <Link
            to={"/admin-events"}
            className="text-black bg-gray-200 hover:bg-gray-300 rounded-lg text-sm p-1.5"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Link>
        </div>

        <form className="space-y-6">
          <h3 className=" leading-6 text-gray-900 text-center font-semibold text-2xl">
            Edit Event
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="Event"
                className="block text-sm font-medium text-gray-900"
              >
                Event Title
              </label>
              <input
                value={eventTitle}
                onChange={(e) => setEventTitle(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Event"
                className="block text-sm font-medium text-gray-900"
              >
                Event Type
              </label>
              <input
                value={eventType}
                onChange={(e) => setEventType(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Event"
                className="block text-sm font-medium text-gray-900"
              >
                Event Start Date
              </label>
              <input
                value={startDate.toString().split("T")[0]}
                min={new Date().toISOString().split('T')[0]}
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Event"
                className="block text-sm font-medium text-gray-900"
              >
                Event End Date
              </label>
              <input
              min={startDate}
                value={endDate.toString().split("T")[0]}
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="EventImage"
              className="block text-sm font-medium text-gray-900"
            >
              Event Image
            </label>
            <input
              type="file"
              onChange={handleImageChange}
              accept="image/*"
              className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
              required
            />
          </div>
          <div>
                      <label
                        htmlFor="Blog"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Location
                      </label>

                      <select
                        value={location}
                        id="countries"
                        onChange={(e) => {
                          if (e.target.value !== "Nearest Office") {
                            setLocation(e.target.value);
                          }
                        }}

                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                      >
<option value="Nearest Office">Nearest Office</option>
                        <option value="head office">
                          Head Office ( Hattisar )
                        </option>
                        <option value="lalitpur">Lalitpur</option>
                        <option value="pokhara">Pokhara</option>
                        <option value="butwal">Butwal</option>
                        <option value="chitwan">Chitwan</option>
                        <option value="bhairahawa">Bhairahawa</option>
                        <option value="nepalgunj">Nepalgunj</option>
                      </select>
                    </div>
          <div className="flex">
            {oldimagePreview && (
              <img
                src={oldimagePreview}
                alt="Event Preview"
                className="mt-2 w-32 h-32 p-4 object-cover"
              />
            )}
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Event Preview"
                className="mt-2 w-32 h-32 p-4 object-cover"
              />
            )}
          </div>
          <div>
            <label
              htmlFor="Event"
              className="block text-sm font-medium text-gray-900"
            >
              Event
            </label>
            <input
              value={eventDescription}
              onChange={(e) => setEventDescription(e.target.value)}
              type="text"
              className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
              required
            />
          </div>
          <button
            onClick={editEvent}
            className="w-full bg-yellow-400 hover:bg-yellow-300 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 text-center text-black font-semibold"
            >
            Update Event
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminEditEvents;
