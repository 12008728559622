import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { getSingleBlogApi } from '../apis/api';
import { useParams } from 'react-router-dom';
import CircularLoader from '../components/CircularLoader';
import { Circles } from "react-loader-spinner";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function MyPdfViewer() {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.5);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true); 
    const delay = setTimeout(() => {
      getSingleBlogApi(id)
        .then((res) => {
          setPdfPreview(res.data.blog.pdfUrl);
        })
        .finally(() => setLoading(false)); 
    }, 1000);

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) { 
        setScale(0.5);
      } else if (screenWidth >= 640 && screenWidth < 768) {
        setScale(0.8);
      } else {
        setScale(1.5);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(delay); 
    };
  }, [id]);

  const [pdfPreview, setPdfPreview] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="flex justify-center">
      <div className="w-full p-4">
        {loading ? (
          // <CircularLoader />
          <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <Circles color="#FDE047" height={80} width={80} />
            </div>
            
        ) : (
          <Document
            file={pdfPreview}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={scale}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            ))}
          </Document>
        )}
      </div>
    </div>
  );
}

export default MyPdfViewer;
