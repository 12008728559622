import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginUserApi } from "../../apis/api";

const Login = () => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userName, password);

    const formData = new FormData();
    formData.append("userName", userName);
    formData.append("password", password);

    loginUserApi(formData)
      .then((res) => {
        if (res.data.success == false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          localStorage.setItem("token", res.data.token);

          const jsonDecode = JSON.stringify(res.data.userData);
          localStorage.setItem("admin", jsonDecode);
          const user = res.data.userData;
          if (!user) {
            navigate("/home");
            return;
          } else {
              window.location.href = "/admin-dashboard";
            //   navigate("/admin-dashboard");
          }
        }
      })
      .catch((err) => {
        toast.error("Server Error");
        console.log(err.message);
      });
  };

  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md w-full sm:w-96">
          <h2 className="text-3xl font-semibold text-center mb-6">Login</h2>
          <form>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Username
              </label>
              <input
                onChange={(e) => setUserName(e.target.value)}
                type="text"
                id="username"
                name="username"
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Enter your username"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Password
              </label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="password"
                name="password"
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Enter your password"
              />
            </div>
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-full bg-yellow-400 text-dark font-semibold p-2 rounded hover:bg-yellow-500 transition duration-300"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
