import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addTestimonialsApi,
  deleteTestimonialApi,
  showAllTestimonialsApi,
} from "../../apis/api";
import AdminSidebar from "./AdminSidebar";

const AdminTestimonial = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [testiId, setTestiId] = useState("");
  const [isdeleteModalOpen, setdeleteIsModalOpen] = useState(false);
  const opendeleteModal = () => setdeleteIsModalOpen(true);
  const closedeleteModal = () => setdeleteIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // for fetching all the Testimonials and showing in table
  const [testimonial, setTestimonial] = useState([]);
  useEffect(() => {
    showAllTestimonialsApi().then((res) => {
      console.log(res.data);
      setTestimonial(res.data.testimonials);
    });
  }, []);

  const [fullName, setFullName] = useState("");
  const [post, setPost] = useState("");
  const [message, setMessage] = useState("");
  const [ImageUrl, setImageUrl] = useState(null); // New state for the image file
  const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview URL

  const handleCreateTestimonial = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("post", post);
    formData.append("message", message);
    formData.append("ImageUrl", ImageUrl);
    console.log(formData);
    addTestimonialsApi(formData)
      .then((res) => {
        console.log(res.data);

        if (res.data.success == false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          setFullName("");
          setMessage("");
          setPost("");
          setImageUrl(null);
          closeModal();
          const newTest = res.data.testimonials;
          setTestimonial((prevTest) => [...prevTest, newTest]);
        }
      })
      .catch((e) => {
        toast.error(e.message);
        console.log(e);
      });
  };

  // delete Testimonial
  const deleteTestimonials = (id) => {
    deleteTestimonialApi(id).then((res) => {
      if (res.data.success == true) {
        toast.success(res.data.message);
        closedeleteModal(true);
        setTestimonial(testimonial.filter((item) => item._id !== id));
      } else {
        toast.error(res.data.message);
      }
    });
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImageUrl(selectedImage);
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };
  return (
    <AdminSidebar>
      <>
        <div className="w-full sm:px-6 h-[100vh] overflow-scroll">
          <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
            <div className="sm:flex items-center justify-between">
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                Testimonial
              </p>
              {/* <input
                type="text"
                placeholder="Search Testimonial"
                className="px-12 py-3 border border-solid border-gray-700 rounded-lg ml-auto"
              /> */}
              <div>
                <button
                  className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-yellow-400 hover:bg-yellow-300 focus:outline-none rounded  text-black font-semibold "
                  onClick={openModal}
                >
                  Add Testimonial
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
            <table className="w-full whitespace-nowrap">
              <thead>
                <tr className="h-16 w-full text-sm leading-none text-gray-800">
                  <th className="font-medium text-center pl-4">Full Name</th>
                  <th className="font-medium text-center pl-12">Post</th>
                  <th className="font-medium text-center pl-12">Image</th>
                  <th className="font-medium text-center pl-12">Action</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {testimonial &&
                  testimonial.map((eachData) => (
                    <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100">
                      <td className="pl-12">
                        <p className="font-normal capitalize">
                          {eachData.fullName}
                        </p>
                      </td>
                      <td className="pl-20">
                        <p className="font-normal capitalize">
                          {" "}
                          {eachData.post}
                        </p>
                      </td>
                      <td className="pl-20">
                        <img src={eachData.imageUrl} height={50} width={50} />
                      </td>

                      <td className="px-7 2xl:px-0">
                        <Link
                          to={`/admin-edit-testimonial/${eachData._id}`}
                          className="bg-[#123697] py-2 px-3 text-white rounded m-1 text-sm"
                        >
                          <i
                            class="fa-regular fa-pen-to-square "
                            style={{ color: "white" }}
                          ></i>
                        </Link>
                        <button
                          onClick={() => {
                            opendeleteModal();
                            setTestiId(eachData._id);
                          }}
                          className="bg-[#e92939] py-2 px-3 text-white rounded m-1 text-sm"
                        >
                          <i
                            class="fa-regular fa-trash-can"
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {isdeleteModalOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
              id="my-modal"
            >
              <div className="relative mx-auto p-5 border w-1/4 shadow-lg rounded-md bg-white space-y-8 justify-center items-center flex flex-col">
                <i className="fa-solid fa-triangle-exclamation text-red-500 fa-5x"></i>
                <h1 className="font-medium w-3/4 mx-auto text-center">
                  Are you sure you want to Delete?
                </h1>

                <div className="flex flex-wrap items-center justify-between mx-auto w-full">
                  <button
                    onClick={() => deleteTestimonials(testiId)}
                    type="submit"
                    className="w-1/3 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center py-2.5"
                  >
                    Delete
                  </button>
                  <button
                    type="submit"
                    className="w-1/3 text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-2.5"
                    onClick={closedeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {isModalOpen && (
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
              id="my-modal"
            >
              <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    onClick={closeModal}
                    className="text-black bg-gray-200 hover:bg-gray-300 rounded-lg text-sm p-1.5"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>

                <form className="space-y-6">
                  <h3 className=" leading-6 text-gray-900 text-center font-semibold text-2xl">
                    Add New Testimonial
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label
                        htmlFor="Testimonial"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Full Name
                      </label>
                      <input
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="Testimonial"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Post
                      </label>
                      <input
                        value={post}
                        onChange={(e) => setPost(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="EventImage"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Testimonial Image
                      </label>
                      <input
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    {imagePreview && (
                      <img
                        src={imagePreview}
                        alt="Event Preview"
                        className="mt-2 w-32 h-32 object-cover"
                      />
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="Testimonial"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Message
                    </label>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      type="text"
                      rows={7}
                      className="mt-1 block peer min-h-[100px]  resize-none w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                      required
                    />
                  </div>
                  <button
                    onClick={handleCreateTestimonial}
                    className="w-full bg-yellow-400 hover:bg-yellow-300 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 text-center text-black font-semibold"
                  >
                    Add Testimonial
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </>
    </AdminSidebar>
  );
};

export default AdminTestimonial;
