import { faCalendar, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllUpcommingEventApi, showAllTestimonialsApi } from "../apis/api";

const Homepage = () => {
  // const totalItems = 5;
  // const itemsPerPage = 3;
  // const [startIndex, setStartIndex] = useState(0);

  const images = [
    "/../assets/images/partner.jpg",
    "/../assets/images/partner2.jpg",
    // "/../assets/images/country/canada.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);



  // const handlePrev = () => {
  //   setStartIndex(
  //     (prevIndex) => (prevIndex - itemsPerPage + totalItems) % totalItems
  //   );
  // };

  // const handleNext = () => {
  //   setStartIndex((prevIndex) => (prevIndex + itemsPerPage) % totalItems);
  // };

  const [testimonial, setTestimonial] = useState([]);
  useEffect(() => {
    showAllTestimonialsApi().then((res) => {
      console.log(res.data);
      setTestimonial(res.data.testimonials);
    });
  }, []);


  const [currentIndextest, setCurrentIndextest] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndextest((prevIndex) => (prevIndex + 1) % testimonial.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndextest, testimonial.length]);


  // const [allEvents, setAllEvents] = useState("");
  // useEffect(() => {
  //   getAllEventApi().then((res) => {
  //     console.log(res.data);
  //     setAllEvents(res.data.events);
  //   });
  // }, []);

  const [allEvents, setAllEvents] = useState("");

  // method to format the dateString fetched fromm the bankend
  const getFormattedMonth = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { month: "short" });
  };

  const getFormattedDay = (dateString) => {
    const date = new Date(dateString);
    return date.getDate();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllUpcommingEventApi();
        console.log(response.data);

        const formattedEvents = response.data.events.map((event) => ({
          ...event,
          formattedDay: getFormattedDay(event.startDate),
          formattedMonth: getFormattedMonth(event.startDate),
        }));

        setAllEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [isInfoOpen, setIsInfoOpen] = useState(true);
  const [isInfo2Open, setIsInfo2Open] = useState(false);
  const [isInfo3Open, setIsInfo3Open] = useState(false);
  const [isInfo4Open, setIsInfo4Open] = useState(false);

  // const carouselItems = [
  //   {
  //     title: "Title 1",
  //     icon: "fa-solid fa-circle-check",
  //     description: "Description for item 1.",
  //   },
  //   {
  //     title: "Title 2",
  //     icon: "fa-solid fa-circle-exclamation",
  //     description: "Description for item 2.",
  //   },
  //   // Add more objects for additional carousel items as needed
  // ];

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    // Add leading zeros if necessary
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  }

  // const [currentIndexone, setCurrentIndexone] = useState(0);

  // const getReviewIndex = (index) => {
  //   const totalReviews = testimonial.length;
  //   return (index + totalReviews) % totalReviews;
  // };

  // const slideNext = () => {
  //   // Calculate the maximum index that can be reached while displaying at least three testimonials
  //   const maxIndex = testimonial.length - 3;
  //   // Calculate the next index without exceeding the maximum index
  //   const nextIndex = currentIndexone + 1 > maxIndex ? maxIndex : currentIndexone + 1;
  //   setCurrentIndexone(nextIndex);
  // };
  // const slidePrev = () => {
  //   // Calculate the previous index without going below 0
  //   const prevIndex = currentIndexone - 1 < 0 ? 0 : currentIndexone - 1;
  //   setCurrentIndexone(prevIndex);
  // };

  // const sliderStyle = {
  //   transform: `translateX(-${currentIndexone * (100 / 3)}%)`,
  //   transition: "transform 0.5s ease-in-out",
  // };

  const [currentIndexevent, setCurrentIndexevent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndexevent((prevIndex) => (prevIndex + 1) % allEvents.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndexevent, allEvents.length]);


  // const getReviewIndexevent = (index) => {
  //   const totalReviews = allEvents.length;
  //   return (index + totalReviews) % totalReviews;
  // };

  // const slideNextevent = () => {
  //   // Calculate the maximum index that can be reached while displaying at least three events
  //   const maxIndex = allEvents.length - 3;
  //   // Check if the current index is less than the maximum index
  //   if (currentIndexevent < maxIndex) {
  //     setCurrentIndexevent((prevIndex) => prevIndex + 1);
  //   }
  // };

  // const slidePrevevent = () => {
  //   // Check if the current index is not at the beginning
  //   if (currentIndexevent > 0) {
  //     setCurrentIndexevent((prevIndex) => prevIndex - 1);
  //   }
  // };

  // const sliderStyleevent = {
  //   transform: `translateX(-${currentIndexevent * (100 / 3)}%)`,
  //   transition: "transform 0.5s ease-in-out",
  // };

  return (
    <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">
      {/* ==================================first before study abroad from here ==================================*/}
      <div className="w-full flex flex-cols">
        <div className="w-full">
          <img
            src="/../assets/images/Section2.png"
            alt="Your alt text"
            className="w-full h-auto max-h-98 object-cover"
          />
          <div className="md:absolute flex flex-col top-[190px] md:w-[900px] w-full md:pl-36 pl-10 space-y-2">
            <h1 className="md:text-white text-xl md:text-xl font-semibold mt-4 md:mt-16">
              Hello Students
            </h1>
            <h1 className="md:text-white text-2xl md:text-4xl font-medium">
              Welcome to Hub International Education
            </h1>
            <p className="md:text-white w-[90%]">
              "At Hub International Education, we are dedicated to transforming
              educational aspirations into realities by providing personalized
              guidance and expert support throughout the academic journey."
            </p>
            <button className="bg-yellow-400 w-[200px] text-black px-5  py-2 mt-2 rounded-md hover:bg-white hover:text-dark transition duration-300">
              Learn More
            </button>
          </div>
        </div>

        {/* <div className="absolute md:inset-0 flex flex-col items-start mt-16 w-full md:w-3/5 md:ml-32 pl-36 pt-20 ">
          <h1 className="text-white text-xl md:text-xl font-semibold mt-4 md:mt-16">
            Hello Students
          </h1>
          <h1 className="text-white text-2xl md:text-4xl font-medium ">
            Welcome to Hub International
          </h1>
          <p className="text-white w-4/5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro voluptatum ullam blanditiis voluptas id. Ipsam minus ipsum hic! Natus dolor fuga quae. Porro facere, dicta dignissimos ipsam quos suscipit at.</p>
          <button className="bg-yellow-400 text-black px-5  py-2 mt-2 rounded-md hover:bg-white hover:text-dark transition duration-300">
            Learn More
          </button>
        </div> */}
      </div>
      {/*============================= first before study abroad upto===================================== */}
      <div
        className="w-full mx-auto items-center flex flex-col justify-center gap-10 px-2 mt-10 "

      >
        {/* left button */}
        {/* <div
          className="hidden md:block left-0 md:top-1/2 transform -translate-y-1/2"
          onClick={handlePrev}
        >
          <i
            className="fas fa-chevron-left text-2xl text-red-500 cursor-pointer"
            data-carousel-prev
          ></i>
        </div> */}
        {/* left button up to here */}

        {/* division */}
        <h1 className="text-red-700 md:text-4xl text-3xl mt-4 md:mt-6 text-center font-semibold">
          OUR SERVICES
          <div className=" mx-auto border-2 border-solid border-yellow-500 mt-2"></div>
        </h1>
        <div className="flex flex-wrap justify-evenly items-center gap-8">
          {/* {Array.from({ length: itemsPerPage }, (_, index) => {
            const currentIndex = (startIndex + index) % totalItems; */}

          <div
            // key={currentIndex}
            className={`bg-red-700 rounded-md flex flex-col items-center justify-center md:w-[400px] w-full py-16`}
          >
            {/* Your carousel item content goes here */}
            <i className="fa-solid fa-plane fa-4x text-yellow-600"></i>
            <span className="mt-4 text-white font-medium">Study Abroad</span>
            {/* <p className="text-md font-[Montserrat] mt-3 ml-5 text-center text-white overflow-hidden">
              This is content for content 1
            </p> */}
          </div>
          <div
            // key={currentIndex}
            className={`bg-red-700 rounded-md flex flex-col items-center justify-center md:w-[400px] w-full py-16`}
          >
            {/* Your carousel item content goes here */}
            <i className="fa-solid fa-book fa-4x text-yellow-600"></i>
            <span className="mt-4 text-white font-medium">
              Test Preparation
            </span>
            {/* <p className="text-md font-[Montserrat] mt-3 ml-5 text-center text-white w-full overflow-hidden">
              Prepare from best instructor.
            </p> */}
          </div>
          <div
            // key={currentIndex}
            className={`bg-red-700 rounded-md flex flex-col items-center justify-center md:w-[400px] w-full py-16`}
          >
            {/* Your carousel item content goes here */}
            <i className="fa-solid fa-graduation-cap fa-4x text-yellow-600"></i>
            <span className="mt-4 text-white font-medium">
              Career Counselling
            </span>
            {/* <p className="text-md font-[Montserrat] mt-3 ml-5 text-center text-white w-fill overflow-hidden">
              Counselling from best Counselor.
            </p> */}
          </div>

          <div className="md:hidden w-full flex justify-center items-center gap-5">
            {/* <div onClick={handlePrev}>
              <i
                className="fas fa-chevron-left text-2xl text-red-500 cursor-pointer"
                data-carousel-prev
              ></i>
            </div> */}
            {/* <div onClick={handleNext}>
              <i
                className="fas fa-chevron-right text-2xl text-red-500 cursor-pointer "
                data-carousel-next
              ></i>
            </div> */}
          </div>
        </div>
        {/* <div
          className="md:block hidden right-0 top-1/2 transform -translate-y-1/2"
          onClick={handleNext}
        >
          <i
            className="fas fa-chevron-right text-2xl text-red-500 cursor-pointer "
            data-carousel-next
          ></i>
        </div> */}
      </div>
      {/* ======================================= study abroad from here================================ */}
      <div className="bg-white w-full flex flex-col items-center justify-center py-12 md:py-24">
        <h1 className="text-red-700 md:text-4xl text-3xl mt-4 md:mt-6 text-center font-semibold">
          STUDY ABROAD
          <div className=" mx-auto border-2 border-solid border-yellow-500 mt-2"></div>
        </h1>
        {/* division for grid items */}
        <div className="w-full mx-auto items-center flex flex-wrap justify-center gap-10 mt-12 hover:cursor-pointer">
          {/* first grid division  */}
          <Link to="/study-in-australia">
            <div
              className="bg-black flex flex-col items-center justify-center w-[250px] md:w-[400px] py-20"
              style={{
                backgroundImage: 'url("/../assets/images/country/aus.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className="mt-4  text-xl md:text-2xl text-white font-semibold">
                Study In Australia
              </span>
            </div>
          </Link>

          {/* first grid division up to here */}

          {/* second grid divison */}
          <Link to={"/study-in-usa"}>
            <div
              className="bg-black flex flex-col items-center justify-center w-[250px] md:w-[400px] py-20"
              style={{
                backgroundImage: 'url("/../assets/images/country/usa.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className="mt-4 text-xl md:text-2xl text-white font-semibold">
                Study In USA
              </span>
            </div>
          </Link>
          {/* second grid division up to here */}

          <Link to={"/study-in-newzeland"}>
            <div
              className="bg-black flex flex-col items-center justify-center w-[250px] md:w-[400px] py-20"
              style={{
                backgroundImage: 'url("/../assets/images/country/newzealand.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className="mt-4 text-xl md:text-2xl text-white font-semibold">
                Study In NewZeland
              </span>
            </div>
          </Link>

          {/* third grid division  */}
          <Link to={"/study-in-canada"}>
            <div
              className="bg-black flex flex-col items-center justify-center w-[250px] md:w-[400px] py-20"
              style={{
                backgroundImage: 'url("/../assets/images/country/canada.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className="mt-4 text-xl md:text-2xl text-white font-semibold">
                Study In Canada
              </span>
            </div>
          </Link>
          {/* third grid division up to here */}

          {/* fourth grid division from here */}

          <Link to={"/study-in-ireland"}>
            <div
              className="bg-black flex flex-col items-center justify-center w-[250px] md:w-[400px] py-20"
              style={{
                backgroundImage: 'url("/../assets/images/country/ireland.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className="mt-4 text-xl md:text-2xl text-white font-semibold">
                Study In Ireland
              </span>
            </div>
          </Link>

          {/* fourth grid division upto here */}

          {/* fifth grid division from here */}
          <Link to="/study-in-uk">
            <div
              className="bg-black flex flex-col items-center justify-center w-[250px] md:w-[400px] py-20"
              style={{
                backgroundImage: 'url("/../assets/images/country/england.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className="mt-4 text-white  text-xl md:text-2xl font-semibold">
                Study In UK
              </span>
            </div>
          </Link>

          {/* fifth grid division upto here */}

          {/* sixth grid division from here */}

          <Link to="/study-in-india">
            <div
              className="bg-black flex flex-col items-center justify-center w-[250px] md:w-[400px] py-20"
              style={{
                backgroundImage: 'url("/../assets/images/country/india.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className="mt-4 text-xl md:text-2xl text-white font-semibold">
                Study In India
              </span>
            </div>
          </Link>
        </div>
      </div>

      {/* =======================Apply for Bachelor Degree Division================================================ */}
      <div
        className="w-full flex flex-cols items-center justify-center h-auto py-10"
        style={{ backgroundImage: "url('/assets/images/third2.png')" }}
      >
        <div className="w-[90%] flex flex-row flex-wrap justify-center items-center gap-10">
          {/* Left side - Vertical column */}
          <div className="flex flex-col items-center gap-10">
            {/* First grid division */}
            <div className="rounded-md flex flex-col items-start justify-center w-full md:w-3/4 lg:w-[600px] space-x-4 md:space-x-12 py-8 md:py-10 text-white bg-neutral-50 bg-opacity-20">
              <span className="mt-4 font-semibold text-2xl ml-3 md:ml-12">
                APPLY FOR BACHELOR DEGREE
              </span>
              <p className="text-sm font-[Montserrat] mt-3 mx-4 pr-4  text-justify md:mx-12">
                At our consultancy, we offer guidance and support to help you
                navigate the process of applying for bachelor's degree programs.
                From selecting the right institution and program to preparing
                application materials and understanding admission requirements,
                we are here to assist you every step of the way.
              </p>
              <Link
                to={"/contact-us"}
                className="bg-yellow-400 text-black mt-6 px-4 md:px-5 py-2 rounded-md hover:bg-white hover:text-dark transition duration-300"
              >
                Learn More
              </Link>
            </div>

            {/* Second grid division */}
            <div className="rounded-md flex flex-col items-start justify-center w-full md:w-3/4 lg:w-[600px] space-x-4 md:space-x-12 py-8 md:py-10 text-white bg-neutral-50 bg-opacity-20">
              <span className="mt-4 font-semibold text-2xl ml-3 md:ml-12">
                APPLY FOR MASTER DEGREE
              </span>
              <p className="text-sm font-[Montserrat] mt-3 mx-4 pr-4 text-justify md:mx-12">
                At our consultancy, we provide personalized guidance to help you
                navigate the process of applying for master's degree programs.
                From identifying the right program and institution to preparing
                application materials and understanding admission requirements,
                our experienced consultants are here to support you in achieving
                your academic and professional goals.
              </p>
              <Link
                to={"/contact-us"}
                className="bg-yellow-400 text-black mt-6 px-4 md:px-5 py-2 rounded-md hover:bg-white hover:text-dark transition duration-300"
              >
                Learn More
              </Link>
            </div>
          </div>

          {/* Right side - New vertical div */}
          <div className="bg-white rounded-lg shadow-md overflow-hidden w-full md:w-1/2 lg:w-1/3">
            <div className="border-b border-gray-200">
              <div className="flex justify-between items-center w-full py-4 md:py-5 px-4 md:px-6 text-left">
                <span className="font-semibold text-gray-800">
                  Background of Hub International Education Consultancy
                </span>
                <span
                  className={`toggle-arrow cursor-pointer ${isInfoOpen ? "text-yellow-300" : "text-gray-500"
                    }`}
                  onClick={() => setIsInfoOpen(!isInfoOpen)}
                >
                  {isInfoOpen ? "▼" : "▶"}
                </span>
              </div>
              {isInfoOpen && (
                <div className="pb-4 md:pb-5 px-4 md:px-6" id="about-edu-info">
                  <p>
                    Hub International Education Consultancy, established in the
                    early 2006s, has expanded globally over 17 years.
                    Headquartered in Perth, Western Australia, it has
                    partnerships with 500+ institutions worldwide and offices in
                    Nepal, UAE, Philippines, Vietnam, Pakistan, Sri Lanka,
                    India, and Myanmar.
                  </p>
                </div>
              )}
            </div>
            <div className="border-b border-gray-200">
              <div className="flex justify-between items-center w-full py-4 md:py-5 px-4 md:px-6 text-left">
                <span className="font-semibold text-gray-800">
                  Services of Hub International Education Consultancy
                </span>
                <span
                  className={`toggle-arrow cursor-pointer ${isInfo2Open ? "text-yellow-300" : "text-gray-500"
                    }`}
                  onClick={() => setIsInfo2Open(!isInfo2Open)}
                >
                  {isInfo2Open ? "▼" : "▶"}
                </span>
              </div>
              {isInfo2Open && (
                <div className="pb-4 md:pb-5 px-4 md:px-6" id="about-edu-info">
                  <p>
                    Hub International provides personalized counseling,
                    assistance with course selection, fees, scholarships,
                    application processing, visa support, and arrival assistance
                    for students.
                  </p>
                </div>
              )}
            </div>
            <div className="border-b border-gray-200" id="learnAboutUs">
              <div className="flex justify-between items-center w-full py-4 md:py-5 px-4 md:px-6 text-left">
                <span className="font-semibold text-gray-800">
                  Vision of Hub International Education Consultancy
                </span>
                <span
                  className={`toggle-arrow cursor-pointer ${isInfo3Open ? "text-yellow-300" : "text-gray-500"
                    }`}
                  onClick={() => setIsInfo3Open(!isInfo3Open)}
                >
                  {isInfo3Open ? "▼" : "▶"}
                </span>
              </div>
              {isInfo3Open && (
                <div className="pb-4 md:pb-5 px-4 md:px-6" id="about-edu-info">
                  <p>
                    Hub International aims to empower youth through
                    international education, fostering informed choices for a
                    better world.
                  </p>
                </div>
              )}
            </div>
            <div className="border-b border-gray-200">
              <div className="flex justify-between items-center w-full py-4 md:py-5 px-4 md:px-6 text-left">
                <span className="font-semibold text-gray-800">
                  Mission of Hub International Education Consultancy
                </span>
                <span
                  className={`toggle-arrow cursor-pointer ${isInfo4Open ? "text-yellow-300" : "text-gray-500"
                    }`}
                  onClick={() => setIsInfo4Open(!isInfo4Open)}
                >
                  {isInfo4Open ? "▼" : "▶"}
                </span>
              </div>
              {isInfo4Open && (
                <div className="pb-4 md:pb-5 px-4 md:px-6" id="about-edu-info">
                  <p>
                    Hub International seeks to be the premier study abroad
                    consultancy, shaping careers and lives through global
                    education and engagement, committed to excellence in
                    services and support.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* ========================================Testimonials========================================================== */}
      {/* <div className="bg-gray-100 py-12 w-full">
        <div className="container mx-auto px-6">
          <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mx-auto">
            <div className="border-2 border-solid border-yellow-300"></div>
            <h2 className="text-3xl font-bold text-center text-red-500 mb-8 mt-2">
              Testimonials
            </h2>
          </div>
          <div className="flex flex-wrap justify-center items-center gap-8">
            {testimonial &&
              testimonial.map((eachTest) => (
                <div className="max-w-sm w-full  md:w-1/3 ">
                  <div className="bg-white rounded-lg shadow-lg p-4 text-center">
                    <img
                      className="w-24 h-24 rounded-full mx-auto object-cover"
                      src={eachTest.imageUrl}
                      alt="Person One"
                    />
                    <p className="mt-4 text-gray-600 text-start">
                      {eachTest.message}
                    </p>
                    <h5 className="text-xl font-bold mt-4 capitalize">
                      {eachTest.fullName}
                    </h5>
                    <p className="text-gray-500 capitalize">{eachTest.post}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div> */}

      <div
        className="bg-gray-100 w-full py-12  justify-center items-center space-y-10"
        id="review"
      >

        <div className="flex flex-col w-full md:w-1/5 mx-auto">
          <h2 className="text-4xl font-bold text-center text-red-700 mb-8 mt-2">
            Testimonials
            <div className="border-2 border-solid border-yellow-300 mt-2"></div>
          </h2>
        </div>
        <div className="relative w-[90%] mx-auto   overflow-hidden">
          <div className="flex gap-4 mb-1">
            {testimonial.map((testimonial, index) => (
              <div
                key={index}
                style={{
                  transform: `translateX(calc(${0.2 * index}% - ${index * 4}px - ${currentIndextest * (100 + 4)}%))`,
                  transition: "transform 1s ease-in-out",
                }}
                className="md:w-[450px] w-[95%] flex-shrink-0 md:ml-8 shadow-lg rounded-lg p-4 text-left flex flex-col justify-start items-start gap-4"
              >
                <img
                  src={testimonial.imageUrl}
                  className="w-36 h-36 object-cover mx-auto rounded-full"
                  alt="Avatar"
                />
                <p className="text-md">{testimonial.message}</p>
                <div className="flex flex-col items-center w-full">
                  <p className="text-sm font-semibold">{testimonial.fullName}</p>
                  <p className="text-sm font-semibold">{testimonial.post}</p>
                </div>
              </div>
            ))}
          </div>

           {/* <div className="flex items-center justify-center mt-6 space-x-4">
            <button
              onClick={slidePrev}
              className="bg-yellow-400 rounded-lg h-10 w-10 flex justify-center items-center"
            >
              <i className="fas fa-arrow-left text-dark"></i>
            </button>
            <button
              onClick={slideNext}
              className="bg-yellow-400 rounded-lg h-10 w-10 flex justify-center items-center"
            >
              <i className="fas fa-arrow-right text-dark"></i>
            </button>
          </div> */}


        </div>
      </div>

      {/* ================================================few facts===================================================== */}
      <section
        className="py-12 w-full flex flex-wrap justify-center items-center gap-x-5 h-stretch object-cover"
        style={{ backgroundImage: "url('/../assets/images/third2.png')" }}
      >
        <div className="container mx-auto px-2 flex flex-col md:flex-row justify-center items-center">
          {/* Wrapper for Stats Cards and Heading */}
          <div className="flex flex-col justify-center items-center md:w-[600px]">
            <h2 className="text-3xl font-bold text-white mb-8 text-center w-full">
              A Few Facts About Our Consultancy
            </h2>

            {/* Stats Cards */}
            <div className="flex flex-wrap justify-center items-center gap-2">
              {/* Card 1 */}
              <div className="bg-white bg-opacity-20 rounded-lg p-6 text-center shadow-md">
                <span className="text-5xl font-bold text-orange-500">96%</span>
                <p className="text-xl text-white mt-2">Succesed Students</p>
              </div>

              {/* Card 2 */}
              <div className="bg-white bg-opacity-20 rounded-lg p-6 text-center shadow-md mt-8 md:mt-0 md:ml-4">
                <span className="text-5xl font-bold text-orange-500">5089</span>
                <p className="text-xl text-white mt-2">Total Students</p>
              </div>

              {/* Card 3 */}
              <div className="bg-white bg-opacity-20 rounded-lg p-6 text-center shadow-md">
                <span className="text-5xl font-bold text-orange-500">7</span>
                <p className="text-xl text-white mt-2">Total Branches</p>
              </div>

              {/* Card 4 */}
              <div className="bg-white bg-opacity-20 rounded-lg p-6 text-center shadow-md mt-8 md:mt-0 md:ml-4">
                <span className="text-5xl font-bold text-orange-500">7</span>
                <p className="text-xl text-white mt-2">Countries Applied</p>
              </div>
            </div>
          </div>

          {/* Right side - Image */}
          <div className="w-full md:w-1/2 mt-8 md:mt-0 md:block ">
            {/* <iframe
              className="w-full object-cover h-96"
              src="https://www.youtube.com/watch?v=VsSR__N-7ik"
              alt="Consultancy"
            /> */}
            <iframe
              className="w-full object-cover h-96"
              src="https://www.youtube.com/embed/dNVnstChov8?si=ouC3gqSKDuzYteTG"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
      {/* ===============================================upcoming events======================================== */}
      <div className="bg-gray-100 py-12 w-full">
        <div className="container mx-auto px-6">
          <div className="flex flex-col w-full md:w-1/4 mx-auto">
            <h2 className="text-4xl font-bold text-center text-red-700 mb-8 mt-2">
              Upcoming Events
              <div className="border-2 border-solid border-yellow-300 mt-2"></div>
            </h2>
          </div>
          <div className="relative w-full mx-auto justify-center items-center overflow-hidden">
            <div className="flex gap-8 mb-1" >
              {allEvents &&
                allEvents.map((eachEvent, index) => (
                  <div
                    key={index}
                    className="md:w-[450px] w-[95%] flex-shrink-0 md:ml-8 shadow-lg rounded-lg p-4 text-left flex flex-col justify-start items-start gap-4"
                    style={{
                      transform: `translateX(calc(${0.01 * index}% - ${index * 4}px - ${currentIndexevent * (100 + 4)}%))`,
                      transition: "transform 1s ease-in-out",
                    }}

                  >
                    <div className="relative">
                      <span className="bg-yellow-300 text-black py-1 px-3 rounded-br-lg capitalize">
                        {eachEvent.eventType}
                      </span>
                    </div>
                    <div className="w-full overflow-hidden px-6 py-4">
                      <div className="font-bold text-2xl mb-2">
                        {eachEvent.eventTitle}
                      </div>
                      <p className="text-gray-700 text-base">
                        {eachEvent.eventDescription}
                      </p>
                    </div>
                    <div className="flex justify-between  w-full px-4 py-4">
                      <span className="inline-block rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
                        <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                        {formatDate(eachEvent.startDate)}
                        {"  --  "}
                        <span className="text-red-500">
                          {formatDate(eachEvent.endDate)}
                        </span>
                      </span>
                      <span className="inline-block  rounded-full px-3 py-1 text-sm font-semibold text-gray-700 capitalize">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="mr-2"
                        />
                        {eachEvent.location}
                      </span>
                    </div>
                    <div className="flex ml-auto pr-6 pb-6">
                      <Link
                        to={`/event-booking?id=${eachEvent._id}&image=${eachEvent.eventImageUrl}`}
                        className="bg-yellow-300 text-black-500  py-2 px-4 rounded md:ml-auto"
                      >
                        Register
                      </Link>
                    </div>
                  </div>
                  // <div
                  //   key={eachEvent.id}
                  //   className="max-w-md md:w-full rounded  shadow-lg relative"
                  // >
                  //   <div className="relative">
                  //     <span className="bg-yellow-300 text-black py-1 px-3 rounded-br-lg capitalize">
                  //       {eachEvent.eventType}
                  //     </span>
                  //   </div>
                  //   <div className="flex justify-between items-center px-6 py-4">
                  //     <div>
                  //       <div className="font-bold text-2xl mb-2">
                  //         {eachEvent.eventTitle}
                  //       </div>
                  //       <p className="text-gray-700 text-base">
                  //         {eachEvent.eventDescription}
                  //       </p>
                  //     </div>
                  //   </div>
                  //   <div className="flex justify-between items-center px-6 py-4">
                  //     <span className="inline-block rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
                  //       <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                  //       {formatDate(eachEvent.startDate)}
                  //       {"  --  "}
                  //       <span className="text-red-500">
                  //         {formatDate(eachEvent.endDate)}
                  //       </span>
                  //     </span>
                  //     <span className="inline-block rounded-full px-3 py-1 text-sm font-semibold text-gray-700 capitalize">
                  //       <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                  //       {eachEvent.location}
                  //     </span>
                  //   </div>
                  //   <div className="flex justify-end pr-6 pb-6">
                  //     <Link
                  //       to={`/event-booking?id=${eachEvent._id}&image=${eachEvent.eventImageUrl}`}
                  //       className="bg-yellow-300 text-black-500  py-2 px-4 rounded"
                  //     >
                  //       Register
                  //     </Link>
                  //   </div>
                  // </div>
                ))}
            </div>

            {/* <div className="flex gap-4" style={sliderStyle}>
            {testimonial.map((testimonial, index) => (
              <div key={testimonial.id} className="md:w-[500px] w-[95%] flex-shrink-0 bg-white shadow-md rounded-lg p-4 text-left flex flex-col justify-start items-start gap-4">
                <img src={testimonial.imageUrl} className='w-36 h-36 object-cover mx-auto rounded-full' alt="Avatar" />
                <p className="text-md">{testimonial.message}</p>
                  <div className='flex flex-col items-center  w-full'>
                    <p className='text-sm font-semibold'>{testimonial.fullName}</p>
                    <p className='text-sm font-semibold'>{testimonial.post}</p>
                  </div>
              </div>
            ))}
          </div> */}
            {/* <div className="flex items-center justify-center mt-6 space-x-4">
              <button
                onClick={slidePrevevent}
                className="bg-yellow-400 rounded-lg h-10 w-10 flex justify-center items-center"
              >
                <i className="fas fa-arrow-left text-dark"></i>
              </button>
              <button
                onClick={slideNextevent}
                className="bg-yellow-400 rounded-lg h-10 w-10 flex justify-center items-center"
              >
                <i className="fas fa-arrow-right text-dark"></i>
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {/* ===============================affilated universities =============================================*/}
      {/* universities */}
      <div className="w-[90%] h-[300px] relative overflow-hidden mb-12">
        <h1 className="text-red-700 md:text-4xl text-3xl mt-4 md:mt-6 text-center font-semibold">
          Member Associations
          <div className="md:w-1/3 w-full mx-auto border-2 border-solid border-yellow-500 mt-2"></div>
        </h1>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt=""
            className="w-full h-full object-contain absolute mt-4"
            style={{
              transform: `translateX(${100 * (index - currentIndex)}%)`,
              transition: "transform 1s ease-in-out",
            }}
          />
        ))}
      </div>
      {/* ============================== affiliated universities uotp here======================================*/}
    </div>
  );
};

export default Homepage;
