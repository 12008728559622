import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { enqueryCreateApi } from "../apis/api";

// 	162.215.241.63

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const links = [
    { name: "HOME", link: "/" },
    {
      name: "ABOUT US",
      link: "/about-us",
      // sublinks: [
      //   { name: "Media", link: "/galleries" },
      //   { name: "Downloads", link: "/document" },
      //   { name: "Blogs", link: "/blogs" },
      // ],
    },
    {
      name: "STUDY ABROAD",
      sublinks: [
        { name: "AUSTRALIA", link: "/study-in-australia" },
        { name: "CANADA", link: "/study-in-canada" },
        { name: "INDIA", link: "/study-in-india" },
        { name: "IRELAND", link: "/study-in-ireland" },
        { name: "NewZeland", link: "/study-in-newzeland" },
        { name: "UK", link: "/study-in-uk" },
        { name: "USA", link: "/study-in-usa" },
      ],
    },
    {
      name: "Media",
      // link: "/about",
      sublinks: [
        { name: "Gallery", link: "/galleries" },
        { name: "Downloads", link: "/download-important-document" },
        { name: "Blogs", link: "/blogs" },
      ],
    },
    {
      name: "TEST PREPARATION",
      link: "/test-preparation",
      // sublinks: [
      //   { name: "Ielts", link: "/" },
      //   { name: "PTE", link: "/" },
      // ],
    },
    { name: "CONTACT US", link: "/contact-us" },
  ];

  const [open, setOpen] = useState(false);

  const [fullname, setIsFullName] = useState("");
  const [email, setIsEmail] = useState("");
  const [phone, setIsphone] = useState("");
  const [destination, setDestination] = useState("");
  const [nearestOffice, setIsnearestOffice] = useState("");
  const handleSubmitForm = (e) => {
    e.preventDefault();
    const privacyCheckbox = document.getElementById("policyAgreement");

    // Check if the privacy checkbox is checked
    if (privacyCheckbox.checked) {
      const form = new FormData();
      form.append("fullName", fullname);
      form.append("email", email);
      form.append("phoneNum", phone);
      form.append("destination", destination);
      form.append("nearOffice", nearestOffice);

      // Perform form submission
      enqueryCreateApi(form)
        .then((res) => {
          console.log(res.data);
          if (res.data.success == false) {
            toast.error(res.data.message);
          } else {
            toast.success(res.data.message);
            setIsFullName("");
            setDestination("");
            setIsEmail("");
            setIsnearestOffice("");
            setIsphone("");
            closeModal();
          }
        })
        .catch((e) => {
          toast.error(e.message);
          console.log(e);
        });
    } else {
      // Show an error message or handle the case where the privacy checkbox is not checked
      toast.error("Please agree to the Privacy Policy to submit the form.");
    }
  };


  // navbar

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <>
      <nav className="shadow-md w-full sticky top-0 left-0 font-[Poppins] font-medium text-medium md:bg-yellow-400 z-[11]">
        <div className="lg:flex items-center justify-between bg-yellow-380 lg:py-3.5 lg:px-10 px-7">
          <a className="flex items-center ">
          <img
            className={`w-[80px] h-[90px] absolute top-0 ${isMobile ? 'bg-yellow-400' : ''}`}
            src="/assets/images/logo.png"
            alt="Hub Logo"
          />

          </a>
          <div
            onClick={() => setOpen(!open)}
            className="text-3xl absolute right-8 top-6 cursor-pointer lg:hidden"
          >
            <ion-icon
              name={open ? "close" : "menu"}
              style={{ color: "#FACC15" }}
            ></ion-icon>
          </div>
          <ul
            className={`lg:flex bg-yellow-400 lg:items-center lg:pb-0 pb-12 absolute lg:static lg:z-auto z-[-1] left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in ${
              open ? "top-20 " : "top-[-550px]"
            }`}
          >
            {links.map((link) => (
              <li
                key={link.name}
                className={`lg:ml-6 lg:my-0 my-7 ${
                  link.sublinks ? "relative group" : ""
                }`}
              >
                <Link
                  to={link.link}
                  className={`text-black hover:text-white duration-500 ${
                    link.sublinks ? "cursor-pointer" : ""
                  }`}
                >
                  <div className="flex items-center">
                    {link.name}
                    {link.sublinks && (
                      <i
                        className={`fa-solid fa-chevron-down hover:text-white ml-2`}
                        style={{ color: "black", fontSize: "12px" }}
                      ></i>
                    )}
                  </div>
                </Link>
                {link.sublinks && (
                  <ul className="absolute top-4 left-0 hidden mt-2 space-y-4 w-[150px] px-3 py-3 z-20 bg-gray-50 text-black group-hover:block">
                    {link.sublinks.map((sublink) => (
                      <li
                        className="hover:text-yellow-400 cursor-pointer"
                        key={sublink.name}
                      >
                        <Link to={sublink.link}>{sublink.name}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            <li className="p-3 md:block bg-red-600 rounded-[10px] text-white hover:bg-white hover:text-black w-fit ml-3">
              <button onClick={openModal} to="" className="duration-500">
                APPLY NOW
              </button>
            </li>
          </ul>
        </div>
      </nav>

      {isModalOpen && (
        <div
          className="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full px-2"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto md:w-1/2 p-5 border w-full shadow-lg rounded-md bg-white">
            <div className="absolute top-0 right-1 ">
              <button
                onClick={closeModal}
                className="text-black rounded-lg text-sm p-1.5"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>

            <form className="space-y-6">
              <h3 className="text-lg  leading-6 text-gray-900 text-center font-semibold ">
                Apply Now for{" "}
                <strong className="text-yellow-400 font-bold ">
                  Free Consultation
                </strong>{" "}
                session with Experts
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Name
                  </label>
                  <input
                    // value={blogTitle}
                    onChange={(e) => setIsFullName(e.target.value)}
                    type="text"
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    // value={author}
                    onChange={(e) => setIsEmail(e.target.value)}
                    type="email"
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Phone
                  </label>
                  <input
                    // value={shortDescription}
                    onChange={(e) => setIsphone(e.target.value)}
                    type="number"
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="Blog"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Destination Country
                  </label>

                  <select
                    id="countries"
                    onChange={(e) => setDestination(e.target.value)}
                    className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">United States</option>
                    <option value="Canada">Canada</option>
                    <option value="Australia">Australia</option>
                    <option value="Newzealand">Newzealand</option>
                    <option value="UK">UK</option>
                    <option value="Ireland">Ireland</option>
                    <option value="India">India</option>
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="Blog"
                  className="block text-sm font-medium text-gray-900"
                >
                  Nearest Office
                </label>

                <select
                  id="countries"
                  onChange={(e) => setIsnearestOffice(e.target.value)}
                  className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                >
                  <option selected>Nearest Office</option>
                  <option value="head office">Head Office ( Hattisar )</option>
                  <option value="lalitpur">Lalitpur</option>
                  <option value="pokhara">Pokhara</option>
                  <option value="butwal">Butwal</option>
                  <option value="chitwan">Chitwan</option>
                  <option value="bhairahawa">Bhairahawa</option>
                  <option value="nepalgunj">Nepalgunj</option>
                </select>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="policyAgreement"
                  className="text-yellow-400 rounded border-gray-300 focus:ring-yellow-400"
                  required
                />
                <label
                  htmlFor="policyAgreement"
                  className="ml-2 block text-sm text-gray-900 font-semibold "
                >
                  I agree to the{" "}
                  <strong className="text-yellow-400 font-bold ">
                    Privacy Policy
                  </strong>{" "}
                  of{" "}
                  <strong className="text-yellow-400 font-bold ">
                    Hub International Education
                  </strong>
                </label>
              </div>
              <button
                onClick={handleSubmitForm}
                type="submit"
                className="w-full text-white bg-yellow-400 hover:bg-yellow-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Apply Now
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
