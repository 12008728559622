import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSingleBlogApi, updateBlogApi } from "../../apis/api";

const AdminEditBlogs = () => {
  // recieve id from URl
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getSingleBlogApi(id).then((res) => {
      setBlogTitle(res.data.blog.blogTitle);
      setPdfPreview(res.data.blog.pdfUrl);
      setOldImagePreview(res.data.blog.imageUrl);
      setDesc(res.data.blog.shortDescription);
      setAuthor(res.data.blog.author);
    });
  }, [id]);

  const [blogDetails, setDetails] = useState("");

  const [blogTitle, setBlogTitle] = useState("");
  const [author, setAuthor] = useState("Admin");
  const [shortDescription, setDesc] = useState("");
  const [PdfUrl, setPdfUrl] = useState(null);
  const [ImageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);
  const [oldimagePreview, setOldImagePreview] = useState(null); // State to hold the image preview URL

  const editBlog = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("blogTitle", blogTitle);
    formData.append("author", author);
    formData.append("shortDescription", shortDescription);
    formData.append("PdfUrl", PdfUrl);
    formData.append("ImageUrl", ImageUrl);

    updateBlogApi(id, formData)
      .then((res) => {
        if (res.data.success == false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          navigate("/admin-blogs");
        }
      })
      .catch((e) => {
        toast.error(e.message);
        console.log(e);
      });
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImageUrl(selectedImage);
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setPdfUrl(selectedFile);
    if (selectedFile) {
      setPdfPreview(URL.createObjectURL(selectedFile));
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
        <div className="absolute top-0 right-0 pt-4 pr-4">
          <Link
            to={"/admin-blogs"}
            className="text-black bg-gray-200 hover:bg-gray-300 rounded-lg text-sm p-1.5"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Link>
        </div>

        <form className="space-y-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 text-center">
            Edit BLog
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="Blog"
                className="block text-sm font-medium text-gray-900"
              >
                Blog Title
              </label>
              <input
                value={blogTitle}
                onChange={(e) => setBlogTitle(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Blog"
                className="block text-sm font-medium text-gray-900"
              >
                author
              </label>
              <input
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Blog"
                className="block text-sm font-medium text-gray-900"
              >
                shortDescription
              </label>
              <input
                value={shortDescription}
                onChange={(e) => setDesc(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="EventImage"
                className="block text-sm font-medium text-gray-900"
              >
                Banner Image
              </label>
              <input
                type="file"
                onChange={handleImageChange}
                accept="image/*"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div className="flex">
              {oldimagePreview && (
                <img
                  src={oldimagePreview}
                  alt="Event Preview"
                  className="mt-2 w-32 h-32 p-4 object-cover"
                />
              )}
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Event Preview"
                  className="mt-2 w-32 h-32 p-4 object-cover"
                />
              )}
            </div>

            <div>
              <label
                htmlFor="EventImage"
                className="block text-sm font-medium text-gray-900"
              >
                Blog Contnet
              </label>
              <input
                type="file"
                onChange={handleFileChange}
                accept="pdf/*"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            
          </div>

          <button
            onClick={editBlog}
            className="w-full text-white bg-yellow-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Edit Blog
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminEditBlogs;
