import React, { useEffect, useState } from "react";
import { getSingleUniversityByCountryApi } from "../../apis/api";
import  StickyForm  from "../../components/StickyForm";

const England = () => {
  const images = [
    "/../assets/images/country/england.jpg",
    "/../assets/images/country/india.jpg",
    "/../assets/images/country/canada.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const [university, setUniversity] = useState([]);
  useEffect(() => {
    getSingleUniversityByCountryApi("UK").then((res) => {
      console.log(res.data.universities);
      setUniversity(res.data.universities);
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">
      <div className="w-full flex flex-cols">
        <div className="w-full">
          <img
            src="/../assets/images/studyabroad.jpg"
            alt="Your alt text"
            className="w-full h-auto max-h-98 object-cover"
          />
          <div className="md:absolute flex flex-col top-[190px] md:w-[900px] w-full md:pl-36 pl-10 space-y-2">
            <h1 className="md:text-dark text-2xl md:text-4xl font-medium">
              Your dream of studying in
              <br /> United Kingdom is closer than you think!
            </h1>
            <p className="md:text-dark w-[90%]">
              "At Hub International Education, we are dedicated to transforming
              educational aspirations into realities by providing personalized
              guidance and expert support throughout the academic journey."
            </p>
            <button className="bg-yellow-400 w-[210px] text-dark px-5  py-2 mt-2 rounded-md hover:bg-white hover:text-dark transition duration-300">
              Get Started For Free
            </button>
          </div>
        </div>
      </div>

      {/* <div className="w-full md:relative inherit top-0">


            <div className="w-full object-cover">
                <img src="/../assets/images/purple.png" alt="Your alt text" className="w-full h-auto max-h-98 object-cover" />
            </div>

            <div className="absolute flex flex-col items-start md:inset-0 justify-center w-full md:w-1/2 mx-auto">


                <h1 className="text-3xl md:text-4xl font-medium font-semibold ">
                    Your dream of studying in<br /> United Kingdom is closer than you think!
                </h1>
                <p className="w-full text-2xl">Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro voluptatum ullam blanditiis voluptas id. Ipsam minus ipsum hic! Natus dolor fuga quae. Porro facere, dicta dignissimos ipsam quos suscipit at.</p>
                <button className="bg-yellow-400 text-black mt-8 px-5 font-semibold py-4 mt-2 rounded-full hover:bg-white hover:text-dark transition duration-300">
                    Get Started For Free
                </button>
            </div>
        </div> */}

      <div className="flex flex-col md:flex-row">
        <div className="container mx-auto p-8 md:mt-8 mt-32 text-justify md:w-[75%] w-[100%]">
          <div className="flex flex-col md:flex-row gap-10 ">
            {/* first division */}
            <div className="flex-1 max-w-4xl">
              <h1 className="text-3xl font-bold mt-4 mb-2">
                Study in UK from Nepal
              </h1>
              <p className="text-gray-700 mb-4">
                Every year, 500,000 international students enroll in the UK,
                making it one of the most popular destinations in the world for
                higher education. The UK has a long and proud history of
                academic excellence being world-famous in the higher education
                system. International students are heartily welcomed since UK
                education degrees and certifications are recognized
                internationally. Quality education, a wide range of course
                availability, affordable tuition fees, and scholarships are some
                of the attractions for Nepalese students to study in the UK.
                <br /> <br />
              </p>

              <h1 className="text-xl font-bold mt-4 mb-2">
                Reasons to study in the United Kingdom
              </h1>
              <p className="pl-10">
                <strong>• </strong> The UK has the highest number of ranked
                universities with the size of the country: 111 ranked
                universities. Imperial College London, the University of
                Cambridge, and the University of Oxford are three institutions
                that are consistently ranked in the top ten worldwide.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> UK courses are generally shorter than other
                countries, helping to reduce overall tuition fees and
                accommodation costs.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> In UK universities, English is the language
                of instruction. Studying in the UK provides a rich linguistic
                experience for non-native English speakers, enhancing their
                communication and English competence.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Due to the cultural diversity in the UK,
                students have the opportunity to explore and experience the
                history and share cultural and religious practices.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Students have the opportunity to gain
                practical work experience in the UK during the period of
                post-study work visas which could be beneficial for the
                student’s professional career as well.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> Fees and living expenses are generally
                affordable in the UK.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong> The United Kingdom is often considered a
                safe destination for international students with low crime rates
                as compared to other countries.{" "}
              </p>
            </div>
          </div>
          <br />

          <div className="max-w-4xl text-left">
            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Study Levels with Entry Requirements{" "}
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Levels</div>
                <div>Foundation leading to Bachelor</div>
                <div>Bachelor Degree</div>
                <div>Pre-Master leading to Masters</div>
                <div>Master’s Degree</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Min IELTS</div>
                <div>5.5 not less than 5.5</div>
                <div>5.5 not less than 5.5</div>
                <div>5.5 not less than 5.5</div>
                <div>6 not less than 5.5</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">PTE</div>
                <div>43 not less than 43</div>
                <div>42 not less than 42</div>
                <div>43 not less than 43</div>
                <div>50 not less than 43</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Duration</div>
                <div>4 years</div>
                <div>3 years</div>
                <div>3-9 Months</div>
                <div>1 years</div>
              </div>

              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Academic</div>
                <div>2.0</div>
                <div>2.2</div>
                <div>Any Percentage</div>
                <div>45% and above</div>
              </div>
            </div>

            <br />
            <br />

            <p className="text-gray-700 mb-4">
              If your IELTS/PTE scores and academic GPA are not high enough to
              apply directly for a bachelor's or master’s degree program in the
              UK then you could still be eligible to apply for a program that
              leads to a bachelor's or master’s degree.
            </p>

            <br />
            <p className="text-gray-700 mb-4">
              In place of IELTS/ PTE students can still take the university’s
              English Language Tests which are available only at limited
              universities.{" "}
            </p>
            <div className="w-full overflow-hidden mb-12">
              <h1 className="text-red-700 md:text-4xl text-2xl mt-4 md:mt-6 text-center font-semibold">
              Partner Institutions
                <div className="md:w-1/2 w-full mx-auto border-2 border-solid border-yellow-500"></div>
              </h1>

              <div className="flex flex-wrap  justify-center items-center mt-4">
                {university.map((uni, index) => (
                  <a href={uni.description} target="_blank">
                    <div
                      key={index}
                      className="flex flex-wrap items-center space-x-2 space-y-2 p-2 "
                    >
                      <img
                        src={uni.imageUrl}
                        alt={uni.universityName}
                        className="w-40 h-30 object-cover rounded "
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <br />

            <h1 className="text-xl font-bold mt-4 mb-2">Gap Acceptance* </h1>
            <p className="text-gray-700 mb-4">
              * Relevant gap is accepted by showing justifiable and genuine work
              experience to fill the gap.
            </p>

            <h1 className="text-xl font-bold mt-4 mb-2">Intake </h1>
            <p className="text-gray-700 mb-4">
              Intakes may vary as per the universities but January, April, and
              September are some of the popular intakes in the UK among which
              September is considered as the main intake with the availability
              of the most course options.{" "}
            </p>

            <div className="flex flex-col gap-10 max-w-4xl text-justify">
              {/* Header with image and title */}
              <div className="flex-1">
                <h1 className="text-xl font-bold mt-4 mb-2">
                  Guidance from Application to Visa
                </h1>
                <p className="text-gray-700 mb-4  ">
                  Our Hub professionals will give you a proper guidance
                  regarding the renowned universities and course options as per
                  the qualifications and location preferences of an applicant.
                  After confirming the course and the university by the
                  applicant we require the following documents to proceed with
                  an application.
                </p>

                <div>
                  <h1 className="text-xl font-bold mt-4 mb-2">
                    Documents Checklist for an Offer Letter
                  </h1>
                  <p className="pl-10">
                    <strong> •</strong> Academic Documents (Individual Mark
                    sheets, Transcript, Completion Certificate, Provisional,
                    Certificates of all levels){" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Passport{" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> English Proficiency Certificate
                    (IELTS/PTE/TOEFL/UKVI){" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> GAP/Experience Letter (If Applicable){" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Statement of Purpose (Sop){" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Details of CV{" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Two Recommendation Letters
                    (Work/Education){" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Medium of Instruction (MOI) from
                    Education (If Applicable){" "}
                  </p>
                </div>

                {/* <br /> */}
                <div>
                  <p className="text-gray-700 mb-4">
                    In the UK most of the Higher Education providers require an
                    interview which could be before/after an offer letter or
                    before a CAS Letter as well. Most of the interview questions
                    are related to your applied course, university, future
                    plans, gap explanations, and many other details. But, we Hub
                    team are always ready for your successful application and
                    visa so we will be guiding and preparing you throughout this
                    journey.
                  </p>
                  {/* <br /> */}
                  <p className="text-gray-700 mb-4">
                    On successfully receiving an unconditional offer letter we
                    need to step forward for a CAS Letter i.e., Confirmation of
                    Acceptance for Studies, which will be provided by the
                    university upon submitting the following documents.
                  </p>
                  <br />
                </div>

                <div>
                  <h1 className="text-xl font-bold mt-4 mb-2">
                    Documents Checklist for CAS Letter
                  </h1>
                  <p className="pl-10">
                    <strong> •</strong> Medical Test (TB Test Certificate){" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Swift Receipt (Min Tuition Fee payment
                    slip for applying CAS){" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Interview (As per the University
                    requirement){" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Financial Statement (28 Days bank
                    statement)/ Loan and Cash Deposit allowed{" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Other Assessment documentation{" "}
                  </p>
                  <p className="text-gray-700 mb-4">
                    After receiving a CAS Letter, the final step is to apply for
                    a UK Visa which includes submitting all the required
                    documents at the VFS Center Nepal.
                  </p>
                </div>

                <div>
                  <h1 className="text-xl font-bold mt-4 mb-2">
                    Documents Checklist for Visa{" "}
                  </h1>
                  <p className="pl-10">
                    <strong> •</strong> All documents submitted for the CAS and
                    Offer Letter{" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Cover Letter{" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Visa application form{" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Financial documents{" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> IHS Surcharge ( Pay before visa
                    application and get reference Number ){" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Visa Fee payment slip{" "}
                  </p>
                  <p className="pl-10">
                    <strong> •</strong> Other supporting documents{" "}
                  </p>
                  <p className="text-gray-700 mb-4">
                    You will receive an email from the UKVI regarding visa
                    decisions or any additional documents required in support of
                    the application.
                  </p>
                </div>
                <br />

                <div>
                  <h1 className="text-xl font-bold mt-4 mb-2">
                    Some Facts about UK for the International Students
                  </h1>
                  <p className="text-gray-700 mb-4">
                    <strong>Living Expenses:</strong> The living expenses set by
                    the UKVI for international students are; £1,334 per month
                    for courses inside London and £1,023 per month for courses
                    outside London.
                  </p>

                  <p className="text-gray-700 mb-4">
                    <strong>IELTS Waiver:</strong> The UK is an English-speaking
                    country and yes! it is possible to study in the UK without
                    IELTS or any other English Language Proficiency
                    Certificates. Some universities/ institutes in the UK assess
                    the applicant's English from their High School or taking an
                    online interview conducted by the university or proof of
                    English as your medium of instruction in your school.
                  </p>

                  <p className="text-gray-700 mb-4">
                    <strong>Work Hour Limit:</strong> Students on full-time
                    degree-level courses holding a Student visa are permitted to
                    work in the UK. The requirements and rules are as follows:
                    Up to 20 hours per week during University term time.
                  </p>

                  <p className="text-gray-700 mb-4">
                    <strong>Visa Type:</strong> As an international student you
                    need to apply for a Student Visa category.
                  </p>

                  <p className="text-gray-700 mb-4">
                    <strong>Post-Study Work:</strong> After completing your
                    course (UK bachelor’s degree, postgraduate degree or other
                    eligible course) in the UK you are eligible for a Post Study
                    Work Visa i.e., a Graduate visa which permits you to stay in
                    the UK for at least 2 years.
                  </p>

                  <p className="text-gray-700 mb-4">
                    <strong>Dependent Visa:</strong> As per the recent changes
                    in the UKVI rules, only PhD or other doctorate (RQF level 8)
                    and research-based higher degree applicants are eligible to
                    bring the dependents by showing the required funds.
                  </p>

                  <p className="text-gray-700 mb-4">
                    <strong>Under-age Applicant:</strong> You can apply for a
                    Student visa if you're 16 or over (Under-18) and want to
                    study on a further or higher education course in the UK.
                    Parents' consent letters and other additional financial
                    evidence could be required for such cases.
                  </p>
                  <p className="text-blue-700 mb-4">
                    You can check on the UKVI website:
                    <a
                      href=" https://www.gov.uk/student-visa "
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      {" "}
                      https://www.gov.uk/student-visa{" "}
                    </a>
                    , for more detailed information regarding the Visa and
                    Immigration rules/ regulations.
                  </p>
                </div>
                <br />
                <p className="text-black mb-4 font-semibold">
                  Hub International Education can guide you through the
                  admission process to experience the best education in the
                  world.
                </p>
              </div>
            </div>
          </div>
        </div>
        <StickyForm country="UK"></StickyForm>
      </div>
    </div>
  );
};

export default England;
