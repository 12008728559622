import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { createContactAPI } from "../apis/api";

const Contact = () => {
  // making usestate for state variables
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [country, setCountry] = useState("US");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [activeCountry, setActiveCountry] = useState("Kathmandu");

  // making a function to send the data from button

  const handleContactSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("phoneNum", phoneNum);
    formData.append("country", country);
    formData.append("address", address);
    formData.append("message", message);

    createContactAPI(formData)
      .then((res) => {
        if (res.data.success === false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);

          // Reset the form after successful submission
          setFullName("");
          setEmail("");
          setPhoneNum("");
          setCountry("");
          setAddress("");
          setMessage("");
        }
      })
      .catch((e) => {
        toast.error("Server Error !!!");
        console.log(e);
      });
  };

  const renderContactInfo = () => {
    switch (activeCountry) {
      case "Kathmandu":
        return (
          <>
            <div className="flex flex-wrap justify-center items-center gap-8 w-full my-10">
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/phone-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Phone</p>
                  <p className="text-sm text-neutral-500">
                    +977 01-5342096
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/email-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">E-mail</p>
                  <p className="text-sm text-neutral-500">
                    info@hubintl.edu.np
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/location-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Location</p>
                  <p className="text-sm text-neutral-500">
                    Hub Complex Hattisar | Kathmandu | Head Office
                    {/* HUB COMPLEX HATTISAR | KATHMANDU | HEAD OFFICE */}
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case "Lalitpur":
        return (
          <>
            <div className="flex flex-wrap justify-center items-center gap-8 w-full my-10">
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/phone-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Phone</p>
                  <p className="text-sm text-neutral-500">
                    +977 01-5400445
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/email-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">E-mail</p>
                  <p className="text-sm text-neutral-500">
                    lalitpur@hubintlglobal.com
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/location-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Location</p>
                  <p className="text-sm text-neutral-500">
                   Kumaripati | Lalitpur
                  </p>
                  <p className="text-sm text-neutral-500">
                  Opposite of Global IME Bank 
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case "Pokhara":
        return (
          <>
            <div className="flex flex-wrap justify-center items-center gap-8 w-full my-10">
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/phone-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Phone </p>
                  <p className="text-sm text-neutral-500">
                  +977 061-417092
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/email-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">E-mail</p>
                  <p className="text-sm text-neutral-500">
                    pokhara@hubintlglobal.com
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/location-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Location</p>
                  <p className="text-sm text-neutral-500">
                   Newroad-9, Pokhara
                  </p>
                  <p className="text-sm text-neutral-500">
                    Purnima Complex
                     </p>
                </div>
              </div>

            </div>
          </>
        );
      case "Bhutwal":
        return (
          <>
            <div className="flex flex-wrap justify-center items-center gap-8 w-full my-10">
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/phone-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Phone </p>
                  <p className="text-sm text-neutral-500">
                    +977 071-591799, 9857075799
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/email-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">E-mail</p>
                  <p className="text-sm text-neutral-500">
                    butwal@hubintl.edu.np
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/location-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Location</p>
                  <p className="text-sm text-neutral-500">
                    NMB Bank Building
                  </p>
                  <p className="text-sm text-neutral-500">
                   Milan chowk, Butwal
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case "Birtamode":
        return (
          <>
            <div className="flex flex-wrap justify-center items-center gap-8 w-full my-10">
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/phone-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Phone</p>
                  <p className="text-sm text-neutral-500">
                    +977 9851120433, 023-591148
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/email-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">E-mail</p>
                  <p className="text-sm text-neutral-500">
                    birtamode@hubintl.edu.np
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/location-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Location</p>
                  <p className="text-sm text-neutral-500">
                   Sanischare Road
                  </p>
                  <p className="text-sm text-neutral-500">
                  Opposite to Nepal Bank
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case "Bhairahawa":
        return (
          <>
            <div className="flex flex-wrap justify-center items-center gap-8 w-full my-10">
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/phone-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Phone</p>
                  <p className="text-sm text-neutral-500">
                    +977 9857015982
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/email-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">E-mail</p>
                  <p className="text-sm text-neutral-500">
                    bhairahawa@hubintlglobal.com
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/location-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Location</p>
                  <p className="text-sm text-neutral-500">
                    Prem Sadan
                  </p>
                  <p className="text-sm text-neutral-500">
                   Milan Chowk, Siddhartha Nagar
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case "Nepalgunj":
        return (
          <>
            <div className="flex flex-wrap justify-center items-center gap-8 w-full my-10">
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/phone-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Phone</p>
                  <p className="text-sm text-neutral-500">
                    +977 9768276378
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/email-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">E-mail</p>
                  <p className="text-sm text-neutral-500">
                    nepalgunj@hubintlglobal.com
                  </p>
                </div>
              </div>
              <div className="flex w-[320px] h-[150px] px-4 py-6 gap-4 shadow-lg border border-neutral-100 rounded-lg hover:bg-yellow-300 hover:text-black">
                <img
                  className="w-[38px] h-[38px]"
                  src="/assets/images/location-hub.png"
                  alt=""
                />
                <div className="flex flex-col flex-1 gap-2">
                  <p className="text-lg font-medium">Location</p>
                  <p className="text-sm text-neutral-500">
                    SCS Building 
                  </p>
                  <p className="text-sm text-neutral-500">
                    Opposite to Western Mart 
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <main className="flex flex-col w-full h-auto font-[poppins] text-black bg-[#F2F1F2]">
        {/* Hero Section */}
        {/* <div className="w-[100%] max-h-[600px]">
          <img
            className="w-[100%] h-[100%]"
            src="/assets/images/contact-hero.png"
            alt=""
          />
          <div className="md:relative bottom-56 w-full md:flex justify-between items-center px-20">
            <p className="md:text-white font-bold md:text-3xl pb-10 md:mt-0 mt-10 text-2xl flex">
              CONTACT <span className="md:hidden block ml-2"> US</span>
            </p>
          </div>
        </div> */}

        {/* Country Contact Information Section */}
        <div className="md:w-[85%] w-95% mx-auto px-1 pt-8 rounded-3xl bg-white my-10 flex flex-col items-center">
          <p className="md:text-black items-start font-bold md:text-3xl pb-10 md:mt-0 mt-10 text-2xl flex">
            Our Branches{" "}
          </p>
          <ul className="flex flex-wrap justify-start w-fit">
            <li
              className={`py-4 px-11 hover:bg-red-600 hover:text-white ${
                activeCountry === "Kathmandu"
                  ? "bg-red-600 text-white"
                  : "bg-neutral-200"
              }`}
              onClick={() => setActiveCountry("Kathmandu")}
            >
              Kathmandu
            </li>
            <li
              className={`py-4 px-11 hover:bg-red-600 hover:text-white ${
                activeCountry === "Lalitpur"
                  ? "bg-red-600 text-white"
                  : "bg-neutral-200"
              }`}
              onClick={() => setActiveCountry("Lalitpur")}
            >
              Lalitpur
            </li>
            <li
              className={`py-4 px-11 hover:bg-red-600 hover:text-white ${
                activeCountry === "Pokhara"
                  ? "bg-red-600 text-white"
                  : "bg-neutral-200"
              }`}
              onClick={() => setActiveCountry("Pokhara")}
            >
              Pokhara
            </li>
            <li
              className={`py-4 px-11 hover:bg-red-600 hover:text-white ${
                activeCountry === "Bhutwal"
                  ? "bg-red-600 text-white"
                  : "bg-neutral-200"
              }`}
              onClick={() => setActiveCountry("Bhutwal")}
            >
              Butwal
            </li>
            <li
              className={`py-4 px-11 hover:bg-red-600 hover:text-white ${
                activeCountry === "Birtamode"
                  ? "bg-red-600 text-white"
                  : "bg-neutral-200"
              }`}
              onClick={() => setActiveCountry("Birtamode")}
            >
              Birtamode
            </li>
            <li
              className={`py-4 px-11 hover:bg-red-600 hover:text-white ${
                activeCountry === "Bhairahawa"
                  ? "bg-red-600 text-white"
                  : "bg-neutral-200"
              }`}
              onClick={() => setActiveCountry("Bhairahawa")}
            >
              Bhairahawa
            </li>
            <li
              className={`py-4 px-11 hover:bg-red-600 hover:text-white ${
                activeCountry === "Nepalgunj"
                  ? "bg-red-600 text-white"
                  : "bg-neutral-200"
              }`}
              onClick={() => setActiveCountry("Nepalgunj")}
            >
              Nepalgunj
            </li>
            {/* Add more countries here */}
          </ul>
          <div className="flex flex-wrap justify-center items-center gap-8 w-full my-10">
            {renderContactInfo()}
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="md:w-[75%] w-[95%] mx-auto my-auto mb-10 md:px-12 md:py-12 p-5 rounded-3xl md:mt-14 mt-0 bg-white">
          <p className="text-2xl font-medium ">Contact Us</p>
          <p className="text-md font-normal text-neutral-400">
            Your email address will not be provided.
          </p>
          <br />
          <form>
            <input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="bg-neutral-200 px-3 py-2 rounded-md w-full outline-none"
              type="text"
              placeholder="Fullname"
            />
            <div className="flex flex-wrap w-full my-8 justify-between gap-y-8">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-neutral-200 px-3 py-2 rounded-md w-full md:w-[48%] outline-none"
                type="email"
                placeholder="E-mail"
              />
              <select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="bg-neutral-200 px-3 py-2 rounded-md w-full md:w-[48%] outline-none"
                type="text"
                placeholder="Select Country"
              >
                <option selected>Choose a country</option>
                <option value="US">United States</option>
                <option value="Canada">Canada</option>
                <option value="Australia">Australia</option>
                <option value="Newzealand">Newzealand</option>
                <option value="UK">UK</option>
                <option value="Ireland">Ireland</option>
                <option value="India">India</option>
              </select>
            </div>
            <div className="flex flex-wrap w-full my-8 justify-between gap-y-8">
              <input
                value={phoneNum}
                onChange={(e) => setPhoneNum(e.target.value)}
                className="bg-neutral-200 px-3 py-2 rounded-md w-full md:w-[48%] outline-none"
                type="number"
                placeholder="Phone number"
              />
              <input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="bg-neutral-200 px-3 py-2 rounded-md w-full md:w-[48%] outline-none"
                type="text"
                placeholder="Address"
              />
            </div>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full px-3 h-56 py-2 bg-neutral-200 rounded-md outline-none"
              name=""
              placeholder="Message"
            ></textarea>
            <div className="flex justify-end">
              <button
                onClick={handleContactSubmit}
                className="mt-5 px-6 py-3 bg-yellow-400 rounded-lg"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default Contact;
