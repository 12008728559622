import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getallBlogsApi } from "../apis/api";
import { Link } from "react-router-dom";

const Blogs = () => {
  const navigate = useNavigate();
  // const handleIndividualBlog = (id) => {
  //   navigate(`/admin-edit-blogs/${id}`);
  // };

  // useEffect for fetching all the blogs
  const [blogs, setBlogs] =
    useState();
    // latest: [],
    // recent: [],
    // others: [],
  useEffect(() => {
    getallBlogsApi().then((res) => {
      console.log(res.data);
      setBlogs(res.data.blogs);
    });
  }, []);
  return (
    <>
      <div class="bg-gray-50 text-gray-900 font-[Montserrat]">
        <main class="container mx-auto relative">
          {/* <div class="bg-[#EBE8FF] justify-between mx-auto flex p-20 relative">
            <section class="mb-6 w-1/5 ml-20 relative">
              <h2 class="text-3xl font-bold mb-2">Blogs</h2>
              <p class="text-lg mb-4">Read everything about studying abroad!</p>
            </section>
            <div class="">
              <img
                src="/../assets/images/blogs/hub.png"
                height="50"
                width="50"
                class="w-2/5"
                alt=""
              />
            </div>
          </div> */}

<div className="w-full max-h-600px relative">
    <img
        className="w-full h-full object-cover"
        src="/assets/images/contact-hero.png"
        alt=""
    />
    {/* Overlay div with semi-transparent background */}
    <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="text-start px-20">
            <p className="text-white font-bold  pb-10 mt-0   md:text-4xl text-2xl">Blogs </p>
            {/* Additional content can be added here */}
        </div>
    </div>
</div>


          <div class="flex flex-col lg:flex-row justify-evenly m-10">
            <div class="flex-col w-full lg:w-4/5  relative mb-5 lg:mb-0">
              <h3 class="text-3xl font-bold mb-2 p-2">New Posts</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {blogs &&
                  blogs.map((each) => (
                    <section key={each._id} className="bg-white p-4 rounded-lg shadow-md relative overflow-hidden">
                      {/* <Link to={`/user-blogs/${each._id}`} className="block hover:opacity-90"> */}
                      <div className="overflow-hidden h-48 w-full">
                          <img src={each.imageUrl} alt="Post Title" className="h-full w-full object-cover transform transition duration-500 hover:scale-110" />
                        </div>
                        <div className="text-center mt-4">
                          <h4 className="text-xl font-bold mb-2">{each.blogTitle}</h4>
                          <p className="text-gray-600 text-sm">{`By ${each.author} on ${new Date(each.createdAt).toLocaleDateString()}`}</p>
                          <Link  to={`/read-individual-blogs/${each._id}`}> <button className="mt-4 bg-red-500 text-white font-bold py-2 px-4 rounded  transition duration-300">Read More</button></Link>
                        </div>
                      {/* </Link> */}
                    </section>
                  ))}
              </div>
            </div>
            {/* <section class="mb-6 w-full lg:w-3/12">
              <h3 class="text-3xl font-bold mb-2 p-2">Related Content</h3>
              <div class="p-3 rounded bg-yellow-400">
                <article
                  // onClick={handleIndividualBlog(blogs._id)}
                  class="bg-white flex rounded-lg shadow-md border-2 border-black mb-4 relative"
                >
                  <img
                    src="/../assets/images/blogs/canada.jpg"
                    alt="Content Title"
                    width="100"
                    class="rounded"
                  />
                  <div class="flex-col ml-3 mb-2">
                    <h4 class="font-bold mb-2">
                      Canada Student Visa Requirements 2024 - Study
                    </h4>
                    <p class="">Hub International</p>
                  </div>
                </article>

                <article
                  // onClick={handleIndividualBlog(blogs._id)}
                  class="bg-white flex rounded-lg shadow-md border-2 border-black mb-4 mt-5 relative"
                >
                  <img
                    src="/../assets/images/blogs/pte.jpg"
                    alt="Content Title"
                    width="100"
                    class="rounded"
                  />
                  <div class="flex-col ml-3 mb-2">
                    <h4 class="font-bold mb-2">
                      Canada Student Visa Requirements 2024 - Study
                    </h4>
                    <p class="">Hub International</p>
                  </div>
                </article>

                <article
                  // onClick={handleIndividualBlog(blogs._id)}
                  class="bg-white flex rounded-lg shadow-md border-2 border-black mb-4 mt-5 relative"
                >
                  <img
                    src="/../assets/images/blogs/canada.jpg"
                    alt="Content Title"
                    width="100"
                    class="rounded"
                  />
                  <div class="flex-col ml-3 mb-2">
                    <h4 class="font-bold mb-2">
                      Canada Student Visa Requirements 2024 - Study
                    </h4>
                    <p class="">Hub International</p>
                  </div>
                </article>

                <article
                  // onClick={handleIndividualBlog(blogs._id)}
                  class="bg-white flex rounded-lg shadow-md border-2 border-black mb-4 mt-5 relative"
                >
                  <img
                    src="/../assets/images/blogs/pte.jpg"
                    alt="Content Title"
                    width="100"
                    class="rounded"
                  />
                  <div class="flex-col ml-3 mb-2">
                    <h4 class="font-bold mb-2">
                      Canada Student Visa Requirements 2024 - Study
                    </h4>
                    <p class="">Hub International</p>
                  </div>
                </article>
              </div>
            </section> */}
          </div>
        </main>
      </div>
    </>
  );
};

export default Blogs;
