import React, { useState,useEffect } from "react";
import { toast } from "react-toastify";
import { countryEnqueryCreateApi } from "../apis/api";

const StickyForm = ({ country }) => {
  const [fullname, setIsFullName] = useState("");
  const [email, setIsEmail] = useState("");
  const [phone, setIsphone] = useState("");
  const [nearestOffice, setIsnearestOffice] = useState("");
  const [destination, setDestination] = useState(country);
  const handleSubmitForm = (e) => {
    e.preventDefault();

      const form = new FormData();
      form.append("fullName", fullname);
      form.append("email", email);
      form.append("phoneNum", phone);
      form.append("destination", destination);
      form.append("nearOffice", nearestOffice);
      // Perform form submission
      countryEnqueryCreateApi(form)
        .then((res) => {
          console.log(res.data);
          if (res.data.success == false) {
            toast.error(res.data.message);
          } else {
            toast.success(res.data.message);
            setIsFullName("");
            setDestination("");
            setIsEmail("");
            setIsnearestOffice("");
            setIsphone("");
            // closeModal();
          }
        })
        .catch((e) => {
          toast.error(e.message);
          console.log(e);
        });

  };

  return (
    <div className="md:w-[35%] w-[100%]  h-[100vh] p-4 right-4 sticky md:top-[20vh]">
      <div className="relative top-0 md:top-20 md:w-2/2 p-5 border  shadow-lg rounded-md bg-white">
        <div className="absolute top-0 right-1 ">
          <button
            // onClick={closeModal}
            className="text-black rounded-lg text-sm p-1.5"
          >
            {/* <FontAwesomeIcon icon={faTimes} /> */}
          </button>
        </div>

        <form className="space-y-6">
          <h3 className="text-lg  leading-6 text-gray-900 text-center font-semibold capitalize ">
            Apply {" "}
            <strong className="text-yellow-400 font-bold ">
              Free Consultation 
            </strong>{" "}
            session with Experts for {country}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="Blog"
                className="block text-sm font-medium text-gray-900"
              >
                Name
              </label>
              <input
                value={fullname}
                onChange={(e) => setIsFullName(e.target.value)}
                type="text"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Blog"
                className="block text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                value={email}
                onChange={(e) => setIsEmail(e.target.value)}
                type="email"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Blog"
                className="block text-sm font-medium text-gray-900"
              >
                Phone
              </label>
              <input
                value={phone}
                onChange={(e) => setIsphone(e.target.value)}
                type="number"
                className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                required
              />
            </div>
            <div>
            <label
              htmlFor="Blog"
              className="block text-sm font-medium text-gray-900"
            >
              Nearest Office
            </label>

            <select
              id="countries"
              value={nearestOffice}
              onChange={(e) => setIsnearestOffice(e.target.value)}
              className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
            >
              <option selected>Nearest Office</option>
              <option value="head office">Head Office ( Hattisar )</option>
              <option value="lalitpur">Lalitpur</option>
              <option value="pokhara">Pokhara</option>
              <option value="butwal">Butwal</option>
              <option value="chitwan">Chitwan</option>
              <option value="bhairahawa">Bhairahawa</option>
              <option value="nepalgunj">Nepalgunj</option>
            </select>
          </div>
          </div>
          <button
            onClick={handleSubmitForm}
            type="submit"
            className="w-full text-white bg-yellow-400 hover:bg-yellow-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Apply Now
          </button>
        </form>
      </div>
    </div>
  );
};

export default StickyForm;
