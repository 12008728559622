import React, { useEffect, useState } from "react";
import { getMultipleFilesApi, getSingleFilesApi } from "../apis/api";

const Media = () => {
  const [singleFiles, setSingleFiles] = useState([]);
  const [multipleFiles, setMultipleFiles] = useState([]);

  const getSingleFileslist = async () => {
    try {
      const fileslist = await getSingleFilesApi();
      setSingleFiles(fileslist);
    } catch (error) {
      console.log(error);
    }
  };
  const getMultipleFilesList = async () => {
    try {
      const fileslist = await getMultipleFilesApi();
      setMultipleFiles(fileslist);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleFileslist();
    getMultipleFilesList();
  }, []);

  const isVideo = (url) => {
    const videoExtensions = [".mp4", ".mkv", ".webm"];
    return videoExtensions.some(extension => url.endsWith(extension));
    
  };

  return (
    <div>
      <main className="flex flex-col w-full h-auto font-[poppins] text-black bg-[#F2F1F2]">
        {/* Hero Section */}
        <div className="w-[100%] max-h-[600px]">
          <img
            className="w-[100%] h-[100%]"
            src="/assets/images/contact-hero.png"
            alt=""
          />
          <div className="md:relative bottom-56 w-full md:flex justify-between items-center px-20">
            <img
              src="/assets/images/contact-one.png"
              className="md:w-[300px] w-[200px] md:block hidden"
              alt=""
            />
            <p className="md:text-white font-bold md:text-3xl pb-10 md:mt-0 mt-10 text-2xl flex">
              Gallery <span className="md:hidden block ml-2"> US</span>
            </p>
          </div>
        </div>
        <div className="w-full flex flex-wrap justify-start items-start md:px-20 px-2 gap-10">
          <div className="container">
            <h3 className="text-danger font-weight-bolder border-bottom text-center">
              Gallery Singles
            </h3>
            <div className="col-12">
              <h4 className="text-success font-weight-bold"></h4>
              <div className="row border border-dark w-100">
                {singleFiles.map((file, index) => (
                  <div className="col-6">
                    <div className="card mb-2 border-0 p-0">
                      <img
                        src={`http://localhost:5000/${file.filePath}`}
                        height="200"
                        className="card-img-top img-responsive"
                        alt="img"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12">
              <h4 className="text-success font-weight-bold">
                Gallery Collections
              </h4>
              {multipleFiles.map((element, index) => (
                <div key={element._id}>
                  <h6 className="text-danger font-weight-bold">
                    Album Title : {element.title}
                  </h6>
                  <div className="row">
                    {element.files.map((file, index) => (
                      <div className="col-6">
                        <div className="card mb-2 border-0 p-0">
                          <img
                            src={`http://localhost:5000/${file.filePath}`}
                            height="40"
                            width={40}
                            className="card-img-top img-responsive"
                            alt="img"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Media;
