import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createUniversityApi,
  deleteUniversityApi,
  getallUniversityApi,
} from "../../apis/api";
import AdminSidebar from "./AdminSidebar";

const AdminCountry = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isdeleteModalOpen, setdeleteIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [uniId, setUniId] = useState("");
  const opendeleteModal = () => setdeleteIsModalOpen(true);
  const closedeleteModal = () => setdeleteIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [count, setCount]=useState()
  const [originalUniversity, setOriginalUniversity] = useState([]);

  // for fetching all the University and showing in table
  const [university, setUniversity] = useState([]);
  useEffect(() => {
    getallUniversityApi().then((res) => {
      console.log(res.data);
      setUniversity(res.data.university);
    });
  }, []);

  const [universityName, setUniversityName] = useState("");
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [eventImage, setEventImage] = useState(null); // New state for the image file
  const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview URL

  const createUniversity = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("universityName", universityName);
    formData.append("country", country);
    formData.append("description", description);
    formData.append("imageUrl", eventImage);

    createUniversityApi(formData)
      .then((res) => {
        console.log(res.data);

        if (res.data.success === false) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          setUniversityName("");
          setCountry("");
          setDescription("");
          setEventImage(null);
          closeModal();
          const newUni = res.data.university;
          setUniversity((prevUni) => [...prevUni, newUni]);
        }
      })
      .catch((e) => {
        toast.error(e.message);
        console.log(e);
      });
  };
  useEffect(() => {
    getallUniversityApi().then((res) => {
      console.log(res.data);
      setOriginalUniversity(res.data.university); // Store original data
      setUniversity(res.data.university); // Initialize university state
    });
  }, []);

  // delete University
  const deleteUniversity = (id) => {
    // make Api call
    deleteUniversityApi(id)
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          closedeleteModal(true);
          setUniversity(university.filter((item) => item._id !== id));
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setEventImage(selectedImage);
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };

  useEffect(() => {
    if (selectedCountry !== "") {
      const filteredUniversities = originalUniversity.filter(
        (uni) => uni.country === selectedCountry
      );
      setUniversity(filteredUniversities);
      const universityCount = filteredUniversities.length;
      setCount(universityCount);
    } else {
      setUniversity(originalUniversity);
      setCount(originalUniversity.length); // Count of all universities
    }
  }, [selectedCountry, originalUniversity]);
  

  const exportCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        "University Name,Country,URL",
        ...university.map(
          (uni) =>
            `"${uni.universityName}","${uni.country}","${uni.description}"`
        ),
      ].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "university.csv");
    document.body.appendChild(link);
    link.click();
  };

  // Filter universities based on the selected country

  return (
    <AdminSidebar>
      <>
        <div className="w-full sm:px-6 h-[100vh] overflow-scroll">
          <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
            <div className="sm:flex items-center justify-between">
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                Institute
              </p>
              <div className="flex flex-row items-center">
              <div>
                <button
                onClick={exportCSV}
                  className="inline-flex sm:ml-3 mr-4 sm:mt-0 items-start justify-start px-6 py-3 bg-yellow-400 hover:bg-yellow-300 focus:outline-none rounded  text-black font-semibold "
                >
                  Export csv
                </button>
              </div>

              {/* <p className="text-base sm:text-lg md:text-xl lg:text-xl font-normal leading-normal text-gray-800">
               Count: {count}
              </p> */}

                <select
                  className="ml-3 px-4 py-2 bg-white text-dark rounded border-2 border-gray-300"
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  <option className="text-lg font-normal" value="">
                    Select by Country
                  </option>
                  <option className="text-lg font-normal" value="Australia">
                    Australia
                  </option>
                  <option className="text-lg font-normal" value="Canada">
                    Canada
                  </option>
                  <option className="text-lg font-normal" value="UK">
                    UK
                  </option>
                  <option className="text-lg font-normal" value="USA">
                    USA
                  </option>
                  <option className="text-lg font-normal" value="Ireland">
                    Ireland
                  </option>
                  <option className="text-lg font-normal" value="India">
                    India
                  </option>
                  <option className="text-lg font-normal" value="NewZeland">
                    NewZeland
                  </option>
                </select>
                <button
                  className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-yellow-400 hover:bg-yellow-300 focus:outline-none rounded text-black font-semibold"
                  onClick={openModal}
                >
                  Add Institute
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
            <table className="w-full whitespace-nowrap">
              <thead>
                <tr className="h-16 w-full text-sm leading-none text-gray-800">
                  <th className="font-medium text-left pl-4">Institute Name</th>
                  <th className="font-medium text-left pl-12">Country</th>
                  <th className="font-medium text-left pl-12">URL</th>
                  <th className="font-medium text-left pl-12">Image</th>
                  <th className="font-medium text-left pl-12">Action</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {university &&
                  university.map((eachUni) => (
                    <tr className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100">
                      <td className="pl-4 font-normal">
                        {eachUni.universityName}
                      </td>
                      <td className="pl-12">
                        <p className="font-normal ">{eachUni.country}</p>
                      </td>
                      <td className="pl-12">
                      <a href={eachUni.description} target="_blank">
                        <p className="font-normal text-blue-500">{eachUni.description}</p>
                        </a>
                      </td>
                      <td className="pl-12">
                        <img src={eachUni.imageUrl} height={50} width={50} />
                      </td>
                      <td className="px-7 2xl:px-0">
                        <Link
                          to={`/admin-edit-country/${eachUni._id}`}
                          className="bg-[#123697] py-2 px-3 text-white rounded m-1 text-sm"
                        >
                          <i
                            className="fa-regular fa-pen-to-square"
                            style={{ color: "white" }}
                          ></i>
                        </Link>

                        <button
                          onClick={() => {
                            opendeleteModal();
                            setUniId(eachUni._id);
                          }}
                          className="bg-[#e92939] py-2 px-3 text-white rounded m-1 text-sm"
                        >
                          <i
                            className="fa-regular fa-trash-can"
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {isdeleteModalOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
              id="my-modal"
            >
              <div className="relative mx-auto p-5 border w-1/4 shadow-lg rounded-md bg-white space-y-8 justify-center items-center flex flex-col">
                <i className="fa-solid fa-triangle-exclamation text-red-500 fa-5x"></i>
                <h1 className="font-medium w-3/4 mx-auto text-center">
                  Are you sure you want to Delete?
                </h1>
                <div className="flex flex-wrap items-center justify-between mx-auto w-full">
                  <button
                    onClick={() => deleteUniversity(uniId)}
                    className="w-1/3 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center py-2.5"
                  >
                    Delete
                  </button>
                  <button
                    type="submit"
                    className="w-1/3 text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-2.5"
                    onClick={closedeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          {isModalOpen && (
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
              id="my-modal"
            >
              <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    onClick={closeModal}
                    className="text-black bg-gray-200 hover:bg-gray-300 rounded-lg text-sm p-1.5"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>

                <form className="space-y-6">
                  <h3 className="leading-6 text-gray-900 text-center font-semibold text-2xl">
                    Add New University
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label
                        htmlFor="Country"
                        className="block text-sm font-medium text-gray-900"
                      >
                        University Name
                      </label>
                      <input
                        value={universityName}
                        onChange={(e) => setUniversityName(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="Country"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Country
                      </label>
                      <select
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        type="text"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      >
                        <option>Select Country</option>
                        <option value={"USA"}>USA</option>
                        <option value={"Australia"}>Australia</option>
                        <option value={"Ireland"}>Ireland</option>
                        <option value={"India"}>India</option>
                        <option value={"UK"}>UK</option>
                        <option value={"Canada"}>Canada</option>
                        <option value={"Newzealand"}>Newzealand</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="Country"
                        className="block text-sm font-medium text-gray-900"
                      >
                        URL of the Institute
                      </label>
                      <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        type="text"
                        rows={5}
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="EventImage"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Logo
                      </label>
                      <input
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        required
                      />
                    </div>
                    {imagePreview && (
                      <img
                        src={imagePreview}
                        alt="Event Preview"
                        className="mt-2 w-32 h-32 object-cover"
                      />
                    )}
                  </div>
                  <button
                    type="submit"
                    onClick={createUniversity}
                    className="w-full bg-yellow-400 hover:bg-yellow-300 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 text-center text-black font-semibold"
                  >
                    Add Institute
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </>
    </AdminSidebar>
  );
};

export default AdminCountry;
