import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { attendeeCreateApi } from "../apis/api";
import { toast } from "react-toastify";

const EventBookingPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get("id");
  const eventImage = searchParams.get("image");
  //   const eventDetails = searchParams.get('details');
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [Expectation, setExpectation] = useState("");
  const [PreferedCountry, setPreferedCountry] = useState("US");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    // formData.append("expectation", Expectation);
    formData.append("preferredCountry", PreferedCountry);
    formData.append("eventId", eventId);
    attendeeCreateApi(formData).then((res) => {
        if (res.data.success == true) {
          toast.success(res.data.message);

          setFullName("");
          setEmail("");
          setPhoneNumber("");
          // setExpectation("");
          setPreferedCountry("");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        toast.error(e.message);
        console.log(e);
      });
  };

  return (
    <div className="px-4 mt-20 lg:px-20 py-8">
      <div className="mx-auto max-w-4xl">
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="md:w-1/2 pr-8">
            <img src={eventImage} alt="Event" className="w-full h-auto" />
          </div>
          <div className="md:w-1/2 mt-4 md:mt-0 pl-20">
            <h2 className="text-2xl font-bold mb-4">Register Event</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium text-gray-900"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullName"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="mt-1 p-2 w-full border rounded-md"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 p-2 w-full border rounded-md"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium text-gray-900"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="mt-1 p-2 w-full border rounded-md"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-900">
                  Prefered Country
                </label>

                <select
                  id="countries"
                  onChange={(e) => setPreferedCountry(e.target.value)}
                  className="mt-1 block w-full border border-solid border-gray-300 text-gray-900 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2.5"
                >
                  <option selected>Choose a country</option>
                  <option value="US">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="Australia">Australia</option>
                  <option value="Newzealand">Newzealand</option>
                  <option value="UK">UK</option>
                  <option value="Ireland">Ireland</option>
                  <option value="India">India</option>
                </select>
              </div>

              {/* <div className="mb-4">
                <label
                  htmlFor="numberOfTickets"
                  className="block text-sm font-medium text-gray-900"
                >
                  Expectation
                </label>
                <textarea
                  type="text"
                  id="numberOfTickets"
                  rows={5}
                  value={Expectation}
                  onChange={(e) => setExpectation(e.target.value)}
                  className="mt-1 p-2 w-full border rounded-md"
                  required
                />
              </div> */}
              {/* Add more form fields as needed */}
              <div className="mt-4">
                <button
                  type="submit"
                  onClick={handleFormSubmit}
                  className="px-4 py-2 bg-yellow-300 text-black-500 rounded-md"
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventBookingPage;
