import React, { useEffect, useState } from "react";
import { getSingleUniversityByCountryApi } from "../../apis/api";
import StickyForm from "../../components/StickyForm";

const India = () => {
  const images = [
    "/../assets/images/country/england.jpg",
    "/../assets/images/country/india.jpg",
    "/../assets/images/country/canada.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const [university, setUniversity] = useState([]);
  useEffect(() => {
    getSingleUniversityByCountryApi("India").then((res) => {
      console.log(res.data.universities);
      setUniversity(res.data.universities);
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">
      <div className="w-full flex flex-cols">
        <div className="w-full">
          <img
            src="/../assets/images/studyabroad.jpg"
            alt="Your alt text"
            className="w-full h-auto max-h-98 object-cover"
          />
          <div className="md:absolute flex flex-col top-[190px] md:w-[900px] w-full md:pl-36 pl-10 space-y-2">
            <h1 className="md:text-dark text-2xl md:text-4xl font-medium">
              Your dream of studying in
              <br /> India is closer than you think!
            </h1>
            <p className="md:text-dark w-[90%]">
              "At Hub International Education, we are dedicated to transforming
              educational aspirations into realities by providing personalized
              guidance and expert support throughout the academic journey."
            </p>
            <button className="bg-yellow-400 w-[210px] text-dark px-5  py-2 mt-2 rounded-md hover:bg-white hover:text-dark transition duration-300">
              Get Started For Free
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
      <div className="container mx-auto p-8 md:mt-8 mt-32 text-justify md:w-[75%] w-[100%]">
          <div className="flex flex-col md:flex-row gap-10 ">
            {/* first division */}
            <div className="flex-1 max-w-4xl">
              <h1 className="text-3xl font-bold mt-4 mb-2">
                Study in India from Nepal
              </h1>
              <p className="text-gray-700 mb-4">
                India and Nepal share a unique and close relationship
                characterized by historical, cultural, and economic ties. The
                two countries have deep-rooted connections that span centuries,
                with open borders allowing for easy movement of people and
                goods.
                <br />
                <br />
                Studying in India presents a compelling choice for international
                students as India offers high-quality education at affordable
                prices, making it financially accessible for many students. Most
                courses in India are taught in English, eliminating language
                barriers and facilitating communication with locals and
                businesses. Indian universities provide a diverse range of
                courses across various fields like engineering, medicine,
                business, and social sciences, ensuring students have ample
                options to align with their interests and career goals.
                Similarly, the diverse culture in India appeals to Nepalese
                students, creating a welcoming environment that fosters cultural
                exchange and personal growth.
              </p>
            </div>
          </div>

          {/* Key Facts Section */}
          <div className="max-w-4xl">
            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Study Levels and Academic Requirements:
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-4">
                <div className="font-semibold">Degree</div>
                <div>Diploma</div>
                <div>Bachelor/Graduate Degree</div>
                <div>Post Graduate Certificate</div>
                <div>Masters</div>
                <div>PHD</div>
              </div>
              <div className="p-4">
                <div className="font-semibold items-center">
                  Academic Requirement
                </div>
                {/* <div>GPA- 2.5 and above</div>
                            <div>GPA- 2.8 and above</div>
                            <div>50% and above</div> */}
                <div>45% and above</div>
              </div>
            </div>
          </div>

          {/* Additional info link */}
          <div className="mt-4 max-w-4xl justify-between">
            <h2 className="text-xl font-bold">Gap Acceptance</h2>
            <p className="text-gray-700 justify-between">
              Gap is accepted up to 5 years
              <br />
              <br />{" "}
            </p>
          </div>
          <div className="mt-4 max-w-4xl justify-between">
            <h2 className="text-xl font-bold">Intakes</h2>
            <p className="text-gray-700 justify-between">
              January and August
              <br />
              <br />{" "}
            </p>
          </div>

          <div className="max-w-4xl text-left">
            <div className="w-full overflow-hidden mb-12">
              <h1 className="text-red-700 md:text-4xl text-xl mt-4 md:mt-6 text-center font-semibold">
              Partner Institutions
                <div className="md:w-1/2 w-full mx-auto border-2 border-solid border-yellow-500"></div>
              </h1>

              <div className="flex flex-wrap  justify-center items-center mt-4">
                {university.map((uni, index) => (
                  <a href={uni.description} target="_blank">
                    <div
                      key={index}
                      className="flex flex-wrap items-center space-x-2 space-y-2 p-2 "
                    >
                      <img
                        src={uni.imageUrl}
                        alt={uni.universityName}
                        className="w-40 h-30 object-cover rounded "
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <div className="flex-1 max-w-4xl text-justify">
              <h1 className="text-xl font-bold mt-4 mb-2">
                Reasons to Study in India{" "}
              </h1>
              <p className="pl-10">
                <strong>• Quality Education:</strong> Indian institutions focus
                on logical and analytical thinking, providing top-notch
                education for career preparation.
              </p>
              <br />
              <p className="pl-10">
                <strong>• Affordability:</strong> India offers high-quality
                education at affordable prices, supported by scholarships and
                concessions.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Scholarships:</strong> India offers scholarships
                through organizations like the Indian Council for Cultural
                Relations to support low-income students pursuing higher
                education. Additionally, fee waivers are available at numerous
                educational institutions for international students{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Cultural Diversity:</strong> Students experience a
                vibrant multicultural society, enhancing personal growth and
                cultural exchange.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• English as a Common Language:</strong> English is
                widely spoken and used in educational programs, improving
                language skills.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Advanced Technology:</strong> India excels in research
                and innovation, offering opportunities to explore cutting-edge
                technology.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Explorative Learning:</strong> The education system
                emphasizes holistic learning through extracurricular activities.{" "}
              </p>

              <br />
              <p className="pl-10">
                <strong>• Student-Friendly Cities:</strong>Vibrant cities
                provide diverse traditions, cultures, and values for academic
                and personal development.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Ease of Access:</strong> The admission process is
                student-friendly, ensuring a hassle-free experience for
                international students.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• State-of-the-Art Campus Facilities:</strong>{" "}
                Universities offer modern facilities like smart classrooms and
                research centers for enhanced learning experiences.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Placement Opportunities:</strong> Indian universities
                and colleges often have dedicated placement cells that assist
                students in securing job placements upon graduation. These cells
                work closely with companies to organize recruitment drives and
                facilitate job interviews for students.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Industry Connections:</strong> Many educational
                institutions in India have strong ties with industry partners,
                leading to internship opportunities and campus recruitment
                drives by renowned companies. This exposure helps students gain
                practical experience and secure job offers even before
                completing their studies.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Diverse Job Market:</strong> India's growing economy
                offers a diverse job market with opportunities in sectors like
                IT, engineering, healthcare, finance, and management.
                International students can explore various career options based
                on their interests and qualifications.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Global Companies:</strong> Several multinational
                corporations have a presence in India and actively recruit
                talent from Indian educational institutions. This provides
                international students with the chance to work for prestigious
                global companies and gain valuable international work
                experience.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Skill Development:</strong> The placement process in
                India focuses not only on securing job offers but also on
                enhancing students' employability skills through training
                sessions, workshops, and career guidance programs. This holistic
                approach prepares students for the competitive job market.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• Entrepreneurial Opportunities:</strong> India's
                startup ecosystem is thriving, offering entrepreneurial
                opportunities for students interested in starting their
                ventures. Many educational institutions support student
                entrepreneurship through incubation centers and mentorship
                programs.{" "}
              </p>
            </div>

            <div className="flex-1 max-w-4xl text-justify pt-5 ">
              <h1 className="text-xl font-bold mt-4 mb-2">
                Application Procedure{" "}
              </h1>
              <p className="pl-10">
                <strong>Step 1: </strong>Select Course and University.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 2: </strong> Send all the documents ( 10th, 12th
                marksheet and Citizenship ).{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 3: </strong> Pay Registration fees and apply for an
                offer letter.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 4: </strong> After accepting all the terms and
                conditions of offer letter, pay admission fees and apply for
                admission letter.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 5: </strong> Prepare documents for reporting along
                with the materials mentioned in offer letter.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>Step 6: </strong> Report at University.{" "}
              </p>
              <br />
            </div>
          </div>
        </div>
        <>
          <StickyForm country="India"></StickyForm>
        </>
      </div>
    </div>
  );
};

export default India;
