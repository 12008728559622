import React, { useEffect, useState } from "react";
import { getSingleUniversityByCountryApi } from "../../apis/api";
import StickyForm from "../../components/StickyForm";

const Ireland = () => {
  const images = [
    "/../assets/images/country/england.jpg",
    "/../assets/images/country/india.jpg",
    "/../assets/images/country/canada.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const [university, setUniversity] = useState([]);
  useEffect(() => {
    getSingleUniversityByCountryApi("Ireland").then((res) => {
      console.log(res.data.universities);
      setUniversity(res.data.universities);
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center bg-white font-[Montserrat]">
      <div className="w-full flex flex-cols">
        <div className="w-full">
          <img
            src="/../assets/images/studyabroad.jpg"
            alt="Your alt text"
            className="w-full h-auto max-h-98 object-cover"
          />
          <div className="md:absolute flex flex-col top-[190px] md:w-[900px] w-full md:pl-36 pl-10 space-y-2">
            <h1 className="md:text-dark text-2xl md:text-4xl font-medium">
              Your dream of studying in
              <br /> Ireland is closer than you think!
            </h1>
            <p className="md:text-dark w-[90%]">
              "At Hub International Education, we are dedicated to transforming
              educational aspirations into realities by providing personalized
              guidance and expert support throughout the academic journey."
            </p>
            <button className="bg-yellow-400 w-[210px] text-dark px-5  py-2 mt-2 rounded-md hover:bg-white hover:text-dark transition duration-300">
              Get Started For Free
            </button>
          </div>
        </div>
      </div>

      {/* <div className="w-full md:relative inherit top-0">


                <div className="w-full object-cover">
                    <img src="/../assets/images/purple.png" alt="Your alt text" className="w-full h-auto max-h-98 object-cover" />
                </div>

                <div className="absolute flex flex-col items-start md:inset-0 justify-center w-full md:w-1/2 mx-auto">


                    <h1 className="text-3xl md:text-4xl font-medium font-semibold ">
                        Your dream of studying in<br /> Australia is closer than you think!
                    </h1>
                    <p className="w-full text-2xl">Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro voluptatum ullam blanditiis voluptas id. Ipsam minus ipsum hic! Natus dolor fuga quae. Porro facere, dicta dignissimos ipsam quos suscipit at.</p>
                    <button className="bg-yellow-400 text-black mt-8 px-5 font-semibold py-4 mt-2 rounded-full hover:bg-white hover:text-dark transition duration-300">
                        Get Started For Free
                    </button>
                </div>
            </div> */}
      <div className="flex flex-col md:flex-row">
        <div className="container mx-auto p-8 md:mt-8 mt-32 text-justify md:w-[75%] w-[100%]">
          <div className="flex flex-col md:flex-row gap-10 ">
            {/* first division */}
            <div className="flex-1 max-w-4xl">
              <h1 className="text-3xl font-bold mt-4 mb-2">
                Study in Ireland from Nepal
              </h1>
              <p className="text-gray-700 mb-4">
                Ireland has one of the best education systems in the world, its
                people are friendly and open-minded, and its big cities are
                smaller and easier to get around. |Let’s dig deeper into why you
                should consider studying in Ireland. Located on the edge of
                Europe, Ireland is an island nation with a whole lot of
                character. Colorfully known as the Emerald Isle, Ireland is
                known for its history, culture and vibrant nightlife, especially
                in Dublin and Cork. Ireland’s rugged coastline, rolling hills
                and valleys and reasonable cost of living make it a popular
                destination for tourists and international students alike.
                Ireland is a beautiful travel and study abroad destination
                offering exciting privileges
                <br /> <br />
              </p>

              <h1 className="text-xl font-bold mt-4 mb-2">
                Why Study in Ireland??
              </h1>
              <p className="pl-10">
                Studying in Ireland offers a range of advantages for
                international students, making it an attractive destination for
                education.1 Here are some compelling reasons why you might
                choose to study in Ireland:
                <br /> <br />
              </p>
              <p className="pl-10">
                <strong>• </strong>Outstanding education system courtesy of
                acclaimed universities in Ireland for international students.
                More importantly, learning institutions in the country offer
                many educational programs that help your professional ambitions.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong>Studying in Dublin offers easy access to
                significant areas such as groceries, recreation venues, and
                more. In addition, learners enrolled in universities in Ireland
                for international students can save money as they walk daily.{" "}
              </p>
              <br />
              <p className="pl-10">
                <strong>• </strong>Live in an innovative city where everything
                is within reach, particularly Dublin. Enjoy easy access to
                significant areas such as groceries, recreation venues, and more
                while enrolled in the region. In addition, learners enrolled in
                universities in Ireland for international students can save
                money as they walk daily.{" "}
              </p>
            </div>
          </div>
          <br />

          <div className="max-w-4xl text-left">
            <div className="w-full overflow-hidden mb-12">
              <h1 className="text-red-700 md:text-4xl text-2xl mt-4 md:mt-6 text-center font-semibold">
                Partner Institutions
                <div className="md:w-1/2 w-full mx-auto border-2 border-solid border-yellow-500"></div>
              </h1>

              <div className="flex flex-wrap  justify-center items-center mt-4">
                {university.map((uni, index) => (
                  <a href={uni.description} target="_blank">
                    <div
                      key={index}
                      className="flex flex-wrap items-center space-x-2 space-y-2 p-2 "
                    >
                      <img
                        src={uni.imageUrl}
                        alt={uni.universityName}
                        className="w-40 h-30 object-cover rounded "
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                Study Levels with Entry Requirements{" "}
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-2 space-y-4 font-normal">
                <div>
                  <br />{" "}
                </div>
                <div className="font-semibold">Duration</div>
                <div className="font-semibold">GPA</div>
                <div className="font-semibold">IETLS</div>
                <br />
                <br />
                <div className="font-semibold">PTE</div>
                <br />
                <br />
                <div className="font-semibold">Duolingo</div>
                <br />
                <br />
                <br />

                <div className="font-semibold">GAP</div>
                <div className="font-semibold">Tuition Fees</div>
                <br />
                <br />
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Bachelors</div>
                <div>3-4 Years</div>
                <div>2.4 or above</div>
                <div>
                  Private Institution -- 6/5.5 <br /> Technological Uni.. --
                  6/5.5
                  <br />
                  Universities.. -- 6/5.5
                </div>
                <div>
                  {" "}
                  Private Institution -- 53/51 <br /> Technological Uni.. --
                  60/51
                  <br />
                  Universities.. -- 63/51 || 61/48{" "}
                </div>
                <div>
                  Private Institution -- 105/85|| 100/85 <br /> Technological
                  Uni.. -- 120/105||100/85
                  <br />
                  Universities.. -- 130/115 || 120/105
                </div>
                <div>4.5 years</div>

                <div>
                  Private Institution --€6k - €10k <br /> Technological Uni.. --
                  €8k - €10k
                  <br />
                  Universities.. -- €12k - €20k
                </div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Masters</div>
                <div>1 Year</div>
                <div>50% or above</div>
                <div>
                  Private Institution -- 6/5.5 || 6.5/5.5 <br /> Technological
                  Uni.. -- 6.5/6 || 6.5/5.5
                  <br />
                  Universities.. -- 6.5/6 || 6.5/5.5
                </div>
                <div>
                  {" "}
                  Private Institution -- 56/51 <br /> Technological Uni.. --
                  60/51
                  <br />
                  Universities.. -- 63/51 || 61/48{" "}
                </div>
                <div>
                  Private Institution -- 105/85|| 100/85 <br /> Technological
                  Uni.. -- 120/105||100/85
                  <br />
                  Universities.. -- 130/115 || 120/105
                </div>
                <div>Genuine Gap Accepted</div>

                <div>
                  Private Institution --€10k - €14k <br /> Technological Uni..
                  -- €10k - €14k
                  <br />
                  Universities.. -- €17k - €30k
                </div>
              </div>
              <div className="font-semibold">
                Living Cost Per Year - €10,000{" "}
              </div>
            </div>

            <br />

            <br />

            <div className="bg-red-500 text-white text-center p-3 rounded-t-md">
              <h2 className="text-xl font-bold">
                National Framework of Qualification in Ireland{" "}
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 bg-white rounded-b-md divide-y md:divide-y-0 md:divide-x divide-gray-300">
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Level</div>

                <div>Level 7</div>
                <div>Level 8</div>
                <div>Level 9</div>
                <div>Level 10</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Degree Awarded</div>
                <div>Ordinary Bachelor’s Degree</div>
                <div>Honors Bachelor’s Degree</div>
                <div>
                  Postgraduate Diploma <br />
                  Master's Degree
                </div>
                <div>Doctoral Degree</div>
              </div>
              <div className="p-2 space-y-4 font-normal">
                <div className="text-xl font-semibold">Duration</div>
                <div>3 Years</div>
                <div>3-4 Years</div>
                <div>
                  1 Year <br />1 Year
                </div>
                <div>About 4 Years </div>
              </div>
            </div>
            <br />

            <br />
            {/* <p>Application Stages</p> */}
            <h2 className="text-xl font-bold ">Gap Acceptance</h2>
            <p className="">
              Relevant gap is accepted by showing justifiable and genuine work
              experience to fill the gap{" "}
            </p>
            <br />
            <h2 className="text-xl font-bold ">Intakes</h2>
            <p className="">
              Intakes may vary as per the universities/Colleges but January,
              April, and September are some of the popular intakes in the
              Ireland among which September is considered as the main intake
              with the availability of the most course options.{" "}
            </p>
            <br />

            <h2 className="text-xl font-bold ">
              Guidance from Application to Visa:
            </h2>
            <p className="">
              Our Hub professionals will give you a proper guidance regarding
              the renowned universities/Colleges and course options as per the
              qualifications and location preferences of an applicant. After
              confirming the course and the university by the applicant we
              require the following documents to proceed with an application.{" "}
            </p>
            <br />

            <h2 className="text-xl font-bold ">
              Documents Checklist for an Offer Letter:
            </h2>
            <p className="pl-10">
              <strong>• </strong>Academic Documents (Individual Mark sheets,
              Transcript, Completion Certificate, Provisional, Certificates of
              all levels){" "}
            </p>
            <p className="pl-10">
              <strong>• </strong>Passport{" "}
            </p>
            <p className="pl-10">
              <strong>• </strong>English Proficiency Certificate
              (IELTS/PTE/Duolingo/TOEFL){" "}
            </p>
            <p className="pl-10">
              <strong>• </strong>GAP/Experience Letter (If Applicable){" "}
            </p>
            <p className="pl-10">
              <strong>• </strong> Statement of Purpose (Sop){" "}
            </p>
            <p className="pl-10">
              <strong>• </strong>Details of CV{" "}
            </p>
            <p className="pl-10">
              <strong>• </strong>Two Recommendation Letters (Work/Education){" "}
            </p>
            <p className="pl-10">
              <strong>• </strong>Medium of Instruction (MOI) from Education (If
              Applicable){" "}
            </p>
            <br />
            <p className="text-xl">
              On successfully receiving an unconditional offer letter we need to
              step forward for a Tuition fee payment.{" "}
            </p>
            <br />

            <h2 className="text-xl font-bold ">Document Checklist for Visa</h2>
            <h2 className="text-xl font-bold pl-10 ">A. OFFER LETTER</h2>
            <h2 className="text-xl font-bold pl-10 ">
              B. FINANCIAL DOCUMENTATION PREPARATION
            </h2>
            <h2 className="text-xl font-bold pl-10 ">C. VISA APPLICATION</h2>
            <br />

            <h2 className="text-xl font-bold ">
              Financial Documents Requirement for Ireland
            </h2>
            <p className="pl-10">
              <strong>1 </strong> Income Source verification from VDC/
              Municipality with supporting documents. <br />
              a. Salary income- i. Appointment letter ii. Salary letter
              <br />
              b. Business income- i.Business Registration ii. Pan Registration
              iii.Audit Report
              <br />
              c. House rent/ Land lease Income- House rent/Land lease Agreement.
              <br />
              d. Pension Income- Pension Patta translation/ Bank statement.
              <br />
              e. Share Income- Share Certificate with Audit report showing
              profit.
              <br />
              f. Other income evidence. Such as for Vehicle income – Blue book
              translation and income letter from Samiti or Agreement paper
              required.
            </p>
            <br />

            <p className="pl-10">
              <strong>2 </strong> Relationship Verification showing all sponsors
              from V.D.C. / Municipality with citizenship translation.{" "}
            </p>
            <br />

            <p className="pl-10">
              <strong>3 </strong> Bank Balance statement (Certificate) of at
              least 6 months old or Education loan from Commercial Bank{" "}
            </p>
            <br />

            <p className="pl-10">
              <strong>4 </strong> Property Valuation Report with landownership
              translation. (From Bank or Municipality){" "}
            </p>
            <br />

            <p className="pl-10">
              <strong>5 </strong> Tax clearance certificate from VDC/
              Municipality.{" "}
            </p>
            <br />

            <p className="pl-10">
              <strong>6 </strong> Loan Disbursement letter and Pay slip.{" "}
            </p>
            <br />

            <p className="pl-10">
              <strong>7 </strong> Work Experience or Internship Certificate (if
              applicable).{" "}
            </p>
            <br />

            <p className="pl-10">
              <strong>8</strong> Affidavits of Support (Sponsorship letter).{" "}
            </p>
            <br />

            <p className="pl-10">
              <strong>9</strong> Statement of Purpose (SOP).i. introduction, ii,
              academic background, iii why Ireland, iv.why …/College/University.
              V. financial sponsors. Vi. Carrer plan Vii conclusion.{" "}
            </p>
            <br />

            <p className="pl-10">
              <strong>10</strong> Bio-data/ birth certificate/ police report.{" "}
            </p>
            <br />

            <p className="pl-10">
              <strong>11</strong> CA Report.{" "}
            </p>

            <br />
            <h2 className="text-xl font-bold ">
              Some Facts about IRELAND for the International Students:
            </h2>
            <h2 className="text-lg font-bold ">Living Expenses:</h2>
            <p>
              The living expenses set by the Irish Immigration for international
              students are; 1000 Euro per Year.
            </p>
            <br />
            <h2 className="text-lg font-bold ">Work Hour Limit:</h2>
            <p>
              Students on full-time degree-level courses holding a Student visa
              are permitted to work in the Ireland. The requirements and rules
              are as follows: Up to 20 hours per week during University/College
              term time and will be permitted to work 40 hours per week only
              during the months of June, July, August and September and from
              15th December to 15th January inclusive.
            </p>
            <br />
            <h2 className="text-lg font-bold ">Visa Type:</h2>
            <p>
              As an international student you need to apply for a Student Visa
              category.
            </p>
            <br />
            <h2 className="text-lg font-bold ">Post-Study Work:</h2>
            <p>
              After completing your course (bachelor’s degree, postgraduate
              degree or other eligible course) in the Ireland you are eligible
              for a Post Study Work Visa i.e., a Graduate visa which permits you
              to stay in the Ireland for at least 1- 2 years respectively.
            </p>
            <br />
            <h2 className="text-lg font-bold ">Entertainment & Leisure</h2>
            <p>
              The renowned writer WB Yeats once said about Ireland: "There are
              no strangers here, only friends you haven't yet met." If it's fast
              and furious that you want, live bands, traditional music and
              various festivals are literally at every crossroads. The west is
              fast becoming the new destination for extreme water sports,
              including surfing and kitesurfing, while, all around Ireland,
              there are facilities for almost every kind of other sport - even
              skiing!
            </p>
            <br />
            <h2 className="text-lg font-bold ">Travelling around Ireland</h2>
            <p>
              Travelling around Ireland is no longer the bumpy adventure it used
              to be, thanks to an impressive motorway system and improved
              nationwide public transport. Everyone loves a bargain and with
              proof of student identity, discounts are available on Dublin Bus,
              Irish Rail , DART, LUAS and Bus Éireann services. A TFI Leap Card
              is a prepaid travel card that is the easiest way to pay your fare
              on public transport around Ireland. It is valid on busses, trains,
              DARTs and Luas trams in and around Dublin, Cork, Galway, Limerick,
              Waterford, Sligo, Athlone, Kilkenny and Wexford city and town
              services throughout Ireland.
            </p>
            <br />
            <h2 className="text-lg font-bold ">
              Leading global Companies in Ireland
            </h2>
            <p>
              Ireland is the European hub to over 1,000 leading multinational
              companies! Companies who require a skilled, educated and highly
              capable workforce to drive their success chose to locate in
              Ireland.
              <br /> Over 1,000 FDI giants in ICT, Social Media, Pharmaceuticals
              and Finance have made Ireland the hub of their European
              operations, with names such as Google, HP, Apple, IBM, Facebook,
              Linkedin, Twitter, Pfizer, GSK and Genzyme.
            </p>
            <br />
            <br />

            <h2 className="text-xl font-bold ">
              Hub International Education can guide you through the admission
              process to experience the best education in the world.
            </h2>
          </div>
        </div>
        <StickyForm country="Ireland"></StickyForm>
      </div>
    </div>
  );
};

export default Ireland;
